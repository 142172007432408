import { Routes, Route } from "react-router-dom";
import Header from "./Component/Pages/Header/Header";
import Footer from "./Component/Pages/Footer/Footer";
import "./App.scss";
import { COMPONENTDATA } from "./Utils/Constant";
import Home from "./Component/Pages/Home/Home";
import { Container } from "@mui/material";
import Error from "./Component/Pages/404/404";
import ContactUs from "./Component/Pages/ContactUs/ContactUs";
import PrivacyPolicy from "./Component/Pages/PrivacyPolicy/PrivacyPolicy";
import TermsOFService from "./Component/Pages/TermOFService/TermsOFService";
import Disclaimer from "./Component/Pages/Disclaimer/Disclaimer";
import { Helmet } from "react-helmet";

function App() {
  window.onload = function() {
    const imgElement = document.querySelector('#goog-gt-vt img');
    if (imgElement) {
        imgElement.alt = 'g-translate';
    }
};

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: COMPONENTDATA?.map((x, i) => ({
              "@type": "ListItem",
              position: i + 1,
              name: x?.title,
              item: `https://calculatortimes.com/${x.slug}`,
            })),
          })}
        </script>
      </Helmet>
      <div className="App">
        <div>
          <Header />

          <Container className="container-class">
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/*" element={<Error />} />
              {COMPONENTDATA.map((item, index) => (
                <Route
                  path={`/${item?.slug}`}
                  element={item?.component}
                  key={index}
                />
              ))}
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOFService />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
            </Routes>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
