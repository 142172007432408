import { Box, Grid, Tab, Tabs, tabsClasses, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { COMPONENTDATA, CONSTANT, SEO } from "../../../Utils/Constant";
import CardComponent from "../../Common/Card/Card";
import "./Home.scss";
import ScientificCalculator from "../ScientificCalc/ScientificCalc";
import calculatorLogo from "../../../Assets/Icon/Calculators.png";
import { getUniqueCategories } from "../../../Utils/CommonFunction";
import Loader from "../../Common/Loader/Loader";
import { DataContext } from "../../../Context/DataContext";

function Home() {
  const { isCalcShow, setIsCalcShow, selectedCategory, setSelectedCategory } =
    useContext(DataContext);
  const [categoryList] = useState(
    COMPONENTDATA && COMPONENTDATA?.length > 0
      ? getUniqueCategories(COMPONENTDATA)
      : []
  );
  useEffect(() => {
    setTimeout(() => {
      setIsCalcShow(true);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeCategoryTabbing = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  return (
    <>
      <Helmet>
        <title>{SEO.HOME_PAGE_TITLE}</title>
        <meta name="description" content={SEO.HOME_PAGE_DESCRIPTION} />
        <meta name="keywords" content={SEO.HOME_PAGE_KEYWORDS} />

        <meta property="og:title" content={SEO.HOME_PAGE_TITLE} />
        <meta property="og:description" content={SEO.HOME_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.CALCULATOR_TIMES_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.CALCULATOR_TIMES} />
        <meta name="robots" content="index" />
<meta name="googlebot" content="index" />
<meta name="googlebot-news" content="index" />
        <meta name="twitter:title" content={SEO.HOME_PAGE_TITLE} />
        <meta name="twitter:description" content={SEO.HOME_PAGE_DESCRIPTION} /><link rel="canonical" href={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Grid sx={{ mb: 2 }}>
        {isCalcShow ? (
          <ScientificCalculator />
        ) : (
          <Grid className="loader-div-sci-calc">
            <Loader />
          </Grid>
        )}
      </Grid>

      <img src={calculatorLogo} alt="Calculators" className="heading-calcs" />
      <Grid item xs={12}>
        <Tabs
          value={selectedCategory || 0}
          onChange={handleChangeCategoryTabbing}
          variant="scrollable"
          scrollButtons
          className="homepage-tabbing-container"
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
            width: { xs: "90%", sm: "80%" },
            m: "4px auto 20px auto",
          }}
        >
          {categoryList.length > 0 &&
            categoryList.map((label, index) => (
              <Tab label={label} id={index} key={index} />
            ))}
        </Tabs>
      </Grid>
      <Box
        className="home-card"
        sx={{
          justifyContent: {
            xs: "center",
            sm: "flex-start",
          },
        }}
      >
        <h1 style={{display:"none"}}>Calculator Times</h1>
        {COMPONENTDATA.length > 0 ? (
          COMPONENTDATA?.filter(
            (x) =>
              x?.category?.toLowerCase() ===
              categoryList[selectedCategory]?.toLowerCase()
          ).map((item, index) => (
            <Grid
              key={index}
              sx={{
                width: {
                  sm: "calc(50% - 10px)",
                  md: "calc(33.33% - 14px)",
                  lg: "calc(25% - 15px)",
                },
                minWidth: { sm: "auto", xs: "100%" },
              }}
            >
              <CardComponent
                title={item?.title}
                description={item?.description}
                icon={item?.icon}
                path={`/${item?.slug}`}
                image={item?.image}
                alt={item?.alt}
              />
            </Grid>
          ))
        ) : (
          <Typography variant="h1" className="search-not-found">
            Oops! No calculators found.
          </Typography>
        )}
      </Box>
    </>
  );
}

export default Home;
