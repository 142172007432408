import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import InputComponent from "../../Common/InputComponent/InputComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BarChartComponent from "../../Common/BarChart/BarCahrt";

function MutualFundCalc() {
  const [totalInvestmentAmount, setTotalInvestmentAmount] = useState(10000);
  const [sipInterval, setSipInterval] = useState(10);
  const [expectInterest, setExpectInterest] = useState(12);
  const [totalValue, setTotalValue] = useState(0);
  const [estimateReturns, setEstimateReturns] = useState(0);
  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [yearlySummary, setYearlySummary] = useState([
    {
      year: 0,
      principal: +totalInvestmentAmount,
      interest: 0,
    },
  ]);

  useEffect(() => {
    var yearlyReturnRate = +expectInterest / 100;

    var futureValue =
      +totalInvestmentAmount * Math.pow(1 + +yearlyReturnRate, +sipInterval);

    var estimatedReturns = futureValue - +totalInvestmentAmount;
    setTotalValue(futureValue.toFixed(2));
    setEstimateReturns(estimatedReturns.toFixed(2));

    const records = [];
    let currentBalance = +totalInvestmentAmount;

    for (let year = 1; year <= sipInterval; year++) {
      const interest = +currentBalance * +yearlyReturnRate;
      const endingBalance = +currentBalance + +interest;

      records.push({
        year: year,
        Deposit: year === 1 ? +totalInvestmentAmount : 0,
        Interest: +interest.toFixed(2),
        EndingBalance: +endingBalance.toFixed(2),
      });

      currentBalance = +endingBalance;
    }

    const temp = [];
    let balance = 0;
    let interest = 0;

    for (let year = 0; year <= sipInterval; year++) {
      balance += year !== 0 && +totalInvestmentAmount;
      interest = +balance * +yearlyReturnRate;
      const endingBalance = +balance + +interest;

      temp.push({
        year: year,
        Deposit: +totalInvestmentAmount,
        Interest: +interest.toFixed(2),
      });

      balance = +endingBalance;
    }
    setYearlySummary(temp);

    setMonthlyRecords(records);
  }, [totalInvestmentAmount, sipInterval, expectInterest]);
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid>
          <Grid container>
            <Grid item xs={12} md={7} className="calculation-part">
              <Grid>
                <InputComponent
                  isInput={true}
                  flag="rupee"
                  label="Total Investment"
                  min={1}
                  value={totalInvestmentAmount}
                  setState={setTotalInvestmentAmount}
                  max={10000000000}
                />
                <InputComponent
                  isInput={true}
                  flag="percentage"
                  label="Expected Return Rate (p.a)"
                  setState={setExpectInterest}
                  value={expectInterest}
                  min={0}
                  max={999}
                />
                <InputComponent
                  isInput={true}
                  flag="year"
                  label="Time Period"
                  setState={setSipInterval}
                  value={sipInterval}
                  min={0}
                  max={199}
                />
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>
              <Grid className="result-common-div">
                <InputComponent
                  label="Invested Amount"
                  isInput={false}
                  value={totalInvestmentAmount}
                />

                <InputComponent
                  label="Estimate Return"
                  isInput={false}
                  value={estimateReturns}
                />
                <InputComponent
                  label="Total Value"
                  isInput={false}
                  value={totalValue}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: "flex" }}>
              <PieChartComponent
                data={[
                  {
                    id: 0,
                    value: +totalInvestmentAmount,
                    label: "Invested Amount",
                  },
                  { id: 1, value: +estimateReturns, label: "Est. Returns" },
                ]}
              />
            </Grid>
          </Grid>
          <Grid className="data-table-container">
            <Grid className="table-toggle-div">
              <Typography variant="h5">
                Your Amortization Details{" "}
                <span>
                  (<u>Yearly</u>)
                </span>
              </Typography>
              <Tooltip
                title={
                  tableView ? "click to close table" : "click to open table"
                }
                placement="top"
                arrow
              >
                <span>
                  <img
                    src={plusIcon}
                    alt="plusIcon"
                    onClick={() => setTableView(!tableView)}
                    style={{
                      transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                    }}
                    className="add-icon-table"
                  />
                </span>
              </Tooltip>
            </Grid>
            {tableView && (
              <Grid>
                <Grid>
                  <TableComponent
                    rows={monthlyRecords}
                    headerData={[
                      "Year",
                      "Deposit",
                      "Interest",
                      "Ending Balance",
                    ]}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <BarChartComponent
              data={[
                {
                  data: yearlySummary?.map((x) => x?.Deposit),
                  stack: "A",
                  label: "Principal Amount",
                },
                {
                  data: yearlySummary?.map((x) => x?.Interest),
                  stack: "A",
                  label: "Interest Value",
                },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default MutualFundCalc;
