import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function PpfCalc() {
  const [loanAmountInput, setLoanAmountInput] = useState(10000);
  const [interestValue, setInterestValue] = useState(7.1);
  const [yearOfLoan, setYearOfLoan] = useState(15);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [investedAmount, setInvestedAmount] = useState(0);

  useEffect(() => {
    calculatePPF(loanAmountInput, yearOfLoan, interestValue);
  }, [loanAmountInput, interestValue, yearOfLoan]);

  const calculatePPF = (yearlyInvest, timePeriod, rateOfInterest) => {
    let investedAmount = yearlyInvest * timePeriod;

    // Calculate total interest
    let totalInterest = 0;
    for (let i = 1; i <= timePeriod; i++) {
      totalInterest += yearlyInvest * Math.pow(1 + rateOfInterest / 100, i);
    }
    totalInterest -= investedAmount;

    // Calculate maturity value
    let maturityValue = investedAmount + totalInterest;
    setInvestedAmount(investedAmount.toFixed(2));
    setTotalAmount(totalInterest.toFixed(2));
    setTotalInterest(maturityValue.toFixed(2));
  };
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Yearly Investment"
                value={loanAmountInput}
                min={1}
                setState={setLoanAmountInput}
                max={10000000000}
              />

              <InputComponent
                isInput={true}
                flag="year"
                label="Time Period (in years)"
                setState={setYearOfLoan}
                value={yearOfLoan}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Rate of Interest (p.a.)"
                setState={setInterestValue}
                value={interestValue}
                dis={true}
                min={0}
                max={999}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Invested Amount"
                isInput={false}
                value={investedAmount}
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                value={totalInterest}
              />
              <InputComponent
                label="Maturity Value"
                isInput={false}
                value={totalAmount}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +investedAmount, label: "Total Invested" },
                { id: 1, value: +totalInterest, label: "Total Interest" },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default PpfCalc;
