import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import TableComponent from "../../Common/TableComponent/TableComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function CDCalc() {
  const [depositAmountInput, setDepositAmountInput] = useState(15000);
  const [interestValue, setInterestValue] = useState(6);
  const [yearOfDeposit, setYearOfDeposit] = useState(5);
  const [marginalTax, setMarginalTax] = useState(0);
  const [mainTableData, setMainTableData] = useState([]);
  const [totalTax, setTotalTax] = useState(0);
  const [interestAfterTax, setInterestAfterTax] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [frequency, setFrequency] = useState("Yearly");

  useEffect(() => {
    calculateCompoundInterest(
      depositAmountInput,
      interestValue,
      yearOfDeposit,
      frequency,
      marginalTax
    );
  }, [
    depositAmountInput,
    interestValue,
    yearOfDeposit,
    frequency,
    marginalTax,
  ]);

  function calculateCompoundInterest(
    principal,
    annualRate,
    years,
    compoundingFrequency,
    marginalTaxRate
  ) {
    const rate = annualRate / 100;

    let n;
    switch (compoundingFrequency) {
      case "Yearly":
        n = 1;
        break;
      case "Half Yearly":
        n = 2;
        break;
      case "Quarterly":
        n = 4;
        break;
      case "Monthly":
        n = 12;
        break;
      default:
        throw new Error("Invalid compounding frequency");
    }

    const months = +years * n;
    const monthlyRate = +rate / n;

    let totalInterest = 0;
    let totalTax = 0;
    let mainTableData = [];

    let currentPrincipal = +principal;
    for (let i = 0; i < months; i++) {
      const interest = +(+currentPrincipal * +monthlyRate).toFixed(2);

      const taxOnInterest = +(+interest * +(+marginalTaxRate / 100)).toFixed(2);

      const netInterest = +(+interest - +taxOnInterest).toFixed(2);
      const endingBalance = +(+currentPrincipal + +netInterest).toFixed(2);

      totalInterest += interest;
      totalTax += taxOnInterest;

      mainTableData.push({
        month: i + 1,
        Deposit: i === 0 ? +principal : 0,
        Interest: interest.toFixed(2),
        Tax: taxOnInterest.toFixed(2),
        "Ending balance": endingBalance.toFixed(2),
      });

      currentPrincipal = endingBalance;
    }

    setMainTableData(mainTableData);
    setTotalTax(+totalTax.toFixed(2));
    setInterestAfterTax((+totalInterest - +totalTax).toFixed(2));
    setTotalInterest(+totalInterest.toFixed(2));
    setTotalAmount((+principal + +totalInterest - +totalTax).toFixed(2));
  }

  const returnTablePeriodName = (value) => {
    switch (value) {
      case "Yearly":
        return "Year's";
      case "Half Yearly":
        return "Half Year";
      case "Quarterly":
        return "Quarter";
      case "Monthly":
        return "Month";
      default:
        return "Year's";
    }
  };
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: "10px 24px" }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                min={1}
                max={10000000000}
                label="Initial deposit"
                value={depositAmountInput}
                setState={setDepositAmountInput}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Rate of Interest (p.a.)"
                min={0}
                max={999}
                setState={setInterestValue}
                value={interestValue}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Deposit length"
                setState={setYearOfDeposit}
                value={yearOfDeposit}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Marginal tax rate"
                setState={setMarginalTax}
                value={marginalTax}
                max={999}
                min={0}
              />
            </Grid>

            <Grid sx={{ textAlign: "right", pr: 1 }}>
              <TabbingComponent
                data={["Yearly", "Half Yearly", "Quarterly", "Monthly"]}
                value={frequency}
                setValue={setFrequency}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                label="Compound Added Frequency"
                isInput={false}
                date={true}
                value={frequency}
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                flag="dollar"
                value={totalInterest}
              />
              <InputComponent
                label="Total tax"
                value={totalTax}
                flag="dollar"
                isInput={false}
              />
              <InputComponent
                label="Interest after tax"
                value={interestAfterTax}
                isInput={false}
                flag="dollar"
              />
              <InputComponent
                label="End balance"
                isInput={false}
                value={totalAmount}
                flag="dollar"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                {
                  id: 0,
                  value: +depositAmountInput,
                  label: "Initial deposit",
                },
                {
                  id: 1,
                  value: +interestAfterTax,
                  label: "Interest after tax",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid item>
              <Grid className="data-table-container">
                <Grid className="table-toggle-div">
                  <Typography variant="h6">Accumulation Schedule</Typography>
                </Grid>
                <Grid>
                  <Grid>
                    <TableComponent
                      rows={mainTableData}
                      headerData={[
                        returnTablePeriodName(frequency),
                        "Deposit",
                        "Interest",
                        "Tax",
                        "Ending balance",
                      ]}
                      isMinWidth={300}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default CDCalc;
