import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "./AgeCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  returnHelmetData
} from "../../../Utils/CommonFunction";
import DatePickerCommon from "../../Common/Datepicker/DatePickerCommon";
import dayjs from "dayjs";
import ageCalcImg from "../../../Assets/ComponentImage/ageCalcAsset.jpg";
import { dateDifferenceForAge } from "../../../Utils/dateHelper";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function AgeCalc() {
  const [dob, setDob] = useState();
  const [fromAgeCount, setfromAgeCount] = useState(dayjs(new Date()));
  const [resultData, setResultData] = useState([]);
  const [isFutureDate, setIsFutureDate] = useState(false);

  useEffect(() => {
    if (dob && fromAgeCount && dob <= fromAgeCount) {
      const ageInMonths = fromAgeCount.diff(dob, "month");
      const ageInWeeks = fromAgeCount.diff(dob, "week");
      const ageInDays = fromAgeCount.diff(dob, "day");
      const ageInYears = fromAgeCount.diff(dob, "year");
      const ageInHours = fromAgeCount.diff(dob, "hour");
      const ageInMinutes = fromAgeCount.diff(dob, "minute");
      const ageInSeconds = fromAgeCount.diff(dob, "second");
      const { str1, str2, str3 } = dateDifferenceForAge(dob, fromAgeCount);

      let result = {
        str1: str1,
        str2: str2,
        str3: str3,
        ageInMonths: `${ageInMonths} Months`,
        ageInWeeks: `${ageInWeeks} Weeks`,
        ageInDays: `${ageInDays} Days`,
        ageInYears: `${ageInYears} Years`,
        ageInHours: `${ageInHours} Hours`,
        ageInMinutes: `${ageInMinutes} Minutes`,
        ageInSeconds: `${ageInSeconds} Seconds`,
      };
      setResultData(result);
    } else {
      setResultData({
        str1: "0 years 0 months 0 days",
        str2: "0 months 0 days",
        str3: "0 weeks 0 days",
        ageInMonths: "0 Months",
        ageInWeeks: "0 Weeks",
        ageInDays: "0 Days",
        ageInYears: "0 Years",
        ageInHours: "0 Hours",
        ageInMinutes: "0 Minutes",
        ageInSeconds: "0 Seconds",
      });
    }
  }, [dob, fromAgeCount]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid className="date-picker-age-calc">
              <DatePickerCommon
                label="Date Of Birth"
                value={dob}
                setValue={setDob}
                fromAgeCount={fromAgeCount}
              />
              <DatePickerCommon
                label="Age at the Date of"
                value={fromAgeCount}
                setValue={setfromAgeCount}
                setIsFutureDate={setIsFutureDate}
              />
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            {isFutureDate ? (
              <Typography className="age-calc-err">
                Please select or verify the value for "Age at the Date of." It
                cannot be a date in the future.
              </Typography>
            ) : (
              <>
                <InputComponent
                  label="Age"
                  isInput={false}
                  value={resultData?.str1 || ""}
                  date={true}
                />
                <InputComponent
                  label=""
                  isInput={false}
                  value={resultData?.str2 || ""}
                  date={true}
                />
                <InputComponent
                  label=""
                  isInput={false}
                  value={resultData?.str3 || ""}
                  date={true}
                />
                <Divider sx={{ my: 2 }} />

                <Grid className="age-calc-input-div">
                  <InputComponent
                    label="Age In Years"
                    isInput={false}
                    value={resultData?.ageInYears || "0 Years"}
                    date={true}
                  />
                  <InputComponent
                    label="Age In Months"
                    isInput={false}
                    value={resultData?.ageInMonths || "0 Months"}
                    date={true}
                  />
                  <InputComponent
                    label="Age In Weeks"
                    isInput={false}
                    value={resultData?.ageInWeeks || "0 Weeks"}
                    date={true}
                  />
                  <InputComponent
                    label="Age In Days"
                    isInput={false}
                    value={resultData?.ageInDays || "0 Days"}
                    date={true}
                  />
                  <InputComponent
                    label="Age In Hours"
                    isInput={false}
                    value={resultData?.ageInHours || "0 Hours"}
                    date={true}
                  />
                  <InputComponent
                    label="Age In Minutes"
                    isInput={false}
                    value={resultData?.ageInMinutes || "0 Minutes"}
                    date={true}
                  />
                  <InputComponent
                    label="Age In Seconds"
                    isInput={false}
                    value={resultData?.ageInSeconds || "0 Seconds"}
                    date={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={0} md={1} />

          <Grid item xs={0} md={4} sx={{ py: 2 }}>
            <img
              src={ageCalcImg}
              alt="age-calculator"
              className="age-calc-assets"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default AgeCalc;
