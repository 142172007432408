import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BarChartComponent from "../../Common/BarChart/BarCahrt";

function CompoundInterestCalc() {
  const [loanAmountInput, setLoanAmountInput] = useState(100000);
  const [interestValue, setInterestValue] = useState(6);
  const [yearOfLoan, setYearOfLoan] = useState(5);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [frequency, setFrequency] = useState("Yearly");
  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [yearlySummary, setYearlySummary] = useState([
    {
      year: 0,
      Deposit: loanAmountInput,
      interest: 0,
    },
  ]);

  useEffect(() => {
    function calculateCompoundInterest(
      principal,
      annualRate,
      years,
      compoundingFrequency
    ) {
      const rate = annualRate / 100;

      let n;
      switch (compoundingFrequency) {
        case "Yearly":
          n = 1;
          break;
        case "Half Yearly":
          n = 2;
          break;
        case "Quarterly":
          n = 4;
          break;
        default:
          throw new Error("Invalid compounding frequency");
      }

      const amount = principal * Math.pow(1 + rate / n, n * years);

      const totalInterest = amount - principal;

      setTotalAmount(amount.toFixed(2));
      setTotalInterest(totalInterest.toFixed(2));

      const monthlyRate = rate / n;
      const months = years * n;
      let currentBalance = principal;

      const records = [];
      let temp = [
        {
          Deposit: +currentBalance,
          interest: 0,
        },
      ];
      let accumulatedInterest = 0;

      for (let month = 1; month <= months; month++) {
        const interest = +currentBalance * +monthlyRate;
        const endingBalance = +currentBalance + +interest;

        records.push({
          month: month,
          Deposit: month === 1 ? +principal : "0",
          Interest: +interest.toFixed(2),
          EndingBalance: +endingBalance.toFixed(2),
        });

        currentBalance = endingBalance;

        accumulatedInterest += interest;
        temp.push({
          Deposit: +principal,
          interest: +accumulatedInterest.toFixed(2),
        });
      }

      setMonthlyRecords(records);

      setYearlySummary([...temp]);
    }
    calculateCompoundInterest(
      loanAmountInput,
      interestValue,
      yearOfLoan,
      frequency
    );
  }, [loanAmountInput, interestValue, yearOfLoan, frequency]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Principal Amount"
                value={loanAmountInput}
                min={1}
                max={10000000000}
                setState={setLoanAmountInput}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Rate of Interest (p.a.)"
                min={0}
                max={999}
                setState={setInterestValue}
                value={interestValue}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Time Period"
                setState={setYearOfLoan}
                value={yearOfLoan}
                min={0}
                max={199}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid sx={{ margin: "20px 35px" }}>
              <TabbingComponent
                data={["Yearly", "Half Yearly", "Quarterly"]}
                value={frequency}
                setValue={setFrequency}
              />
            </Grid>
            <Grid>
              <InputComponent
                label="Compound Added Frequency"
                isInput={false}
                date={true}
                value={frequency}
              />
              <InputComponent
                label="Principal Amount"
                isInput={false}
                value={loanAmountInput}
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                value={totalInterest}
              />
              <InputComponent
                label="Total Amount"
                isInput={false}
                value={totalAmount}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +loanAmountInput, label: "Principal Amount" },
                { id: 1, value: +totalInterest, label: "Interest Amount" },
              ]}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Yearly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyRecords}
                  headerData={["Year", "Deposit", "Interest", "Ending Balance"]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={5} className="piechart-div">
          <BarChartComponent
            data={[
              {
                data: yearlySummary?.map((x) => x?.Deposit),
                stack: "A",
                label: "Principal Amount",
              },
              {
                data: yearlySummary?.map((x) => x?.interest),
                stack: "A",
                label: "Interest Value",
              },
            ]}
          />
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default CompoundInterestCalc;
