import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  returnHelmetData
} from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import InputComponent from "../../Common/InputComponent/InputComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";
import moment from "moment";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";

function SukanyaSamridhiYojanaCalc() {
  const [yearlyInvestment, setYearlyInvestment] = useState(10000);
  const [girlAge, setGirlAge] = useState(5);
  const [startPeriod, setStartPeriod] = useState(moment().format("yyyy"));
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [maturityYear, setMaturityYear] = useState(0);
  const [maturityValue, setMaturityValue] = useState(0);
  const [tableView, setTableView] = useState(false);
  const [yearlyRecords, setYearlyRecords] = useState([]);

  const SSY_RATE = 0.082;

  function calculateSukanyaSamriddhiYojana() {
    let yearEndBalance = 0;
    let totalInterestEarned = 0;
    let yearlyContribution = 0;

    const records = [];
    for (let year = 1; year <= 21; year++) {
      if (year <= 15) {
        yearlyContribution = +yearlyInvestment;
      } else if (year > 15) {
        yearlyContribution = 0;
      }

      yearEndBalance += +yearlyContribution;
      let interestEarned = +(+yearEndBalance * SSY_RATE).toFixed(2);
      yearEndBalance += +interestEarned;
      totalInterestEarned += +interestEarned;

      records.push({
        year: year,
        yearlyContribution: +yearlyContribution,
        interestEarned: +interestEarned,
        yearEndBalance: +yearEndBalance.toFixed(2),
      });
    }
    setYearlyRecords(records);
    setTotalInvestment((+yearEndBalance - +totalInterestEarned).toFixed(2));
    setTotalInterest(+totalInterestEarned.toFixed(2));
    setMaturityYear(+startPeriod + 21);
    setMaturityValue(+yearEndBalance.toFixed(2));
  }

  useEffect(() => {
    calculateSukanyaSamriddhiYojana();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearlyInvestment, startPeriod]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid>
          <Grid container>
            <Grid item xs={12} md={7} className="calculation-part">
              <Grid>
                <InputComponent
                  isInput={true}
                  flag="rupee"
                  label="Yearly investment"
                  value={yearlyInvestment}
                  min={1}
                  setState={setYearlyInvestment}
                  max={10000000000}
                />
                <InputComponent
                  isInput={true}
                  flag="year"
                  label="Girl's age"
                  setState={setGirlAge}
                  value={girlAge}
                  min={0}
                  max={199}
                />
                <InputComponent
                  isInput={true}
                  label="Start Period"
                  setState={setStartPeriod}
                  value={startPeriod}
                  flag="year"
                  min={2018}
                />
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>
              <Grid className="result-common-div">
                <InputComponent
                  label="Total Investment"
                  isInput={false}
                  value={totalInvestment}
                />
                <InputComponent
                  label="Total Interest"
                  isInput={false}
                  value={totalInterest}
                />
                <InputComponent
                  label="Maturity Year"
                  isInput={false}
                  date={true}
                  value={maturityYear}
                />
                <InputComponent
                  label="Maturity Value"
                  isInput={false}
                  value={maturityValue}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: "flex" }}>
              <PieChartComponent
                data={[
                  { id: 0, value: +totalInterest, label: "Total Interest" },
                  { id: 1, value: +maturityValue, label: "Maturity Value" },
                ]}
              />
            </Grid>
          </Grid>
          <Grid className="data-table-container">
            <Grid className="table-toggle-div">
              <Typography variant="h5">
                Your Amortization Details{" "}
                <span>
                  (<u>Yearly</u>)
                </span>
              </Typography>
              <Tooltip
                title={
                  tableView ? "click to close table" : "click to open table"
                }
                placement="top"
                arrow
              >
                <span>
                  <img
                    src={plusIcon}
                    alt="plusIcon"
                    onClick={() => setTableView(!tableView)}
                    style={{
                      transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                    }}
                    className="add-icon-table"
                  />
                </span>
              </Tooltip>
            </Grid>
            {tableView && (
              <Grid>
                <Grid>
                  <TableComponent
                    rows={yearlyRecords}
                    headerData={[
                      "year",
                      "Amount Deposited (₹)",
                      "Interest Earned (₹)",
                      "Year End Balanced (₹)",
                    ]}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default SukanyaSamridhiYojanaCalc;
