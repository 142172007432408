import { Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PieChartComponent from "../../Common/PieChart/PieChart";
import InputComponent from "../../Common/InputComponent/InputComponent";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BarChartComponent from "../../Common/BarChart/BarCahrt";

function SipCalculation() {
  const [monthlySipAmount, setMonthlySipAmount] = useState(25000);
  const [sipInterval, setSipInterval] = useState(10);
  const [expectInterest, setExpectInterest] = useState(12);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [estimateReturns, setEstimateReturns] = useState(0);
  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [yearlySummary, setYearlySummary] = useState([
    {
      year: 0,
      principal: 0,
      interest: 0,
    },
  ]);

  useEffect(() => {
    var monthlyReturnRate = expectInterest / 12 / 100;
    var totalMonths = sipInterval * 12;

    var futureValue =
      monthlySipAmount *
      ((Math.pow(1 + monthlyReturnRate, totalMonths) - 1) / monthlyReturnRate) *
      (1 + monthlyReturnRate);
    const investedAmountValue = monthlySipAmount * totalMonths;
    const estimateReturnsValue = futureValue - investedAmountValue;

    setTotalValue(futureValue.toFixed(0));
    setInvestedAmount(investedAmountValue.toFixed(0));
    setEstimateReturns(estimateReturnsValue.toFixed(0));

    const records = [];
    const temp = [
      {
        principal: +monthlySipAmount * 12,
        interest: 0,
      },
    ];
    let currentBalance = 0;
    let principalChart = 0;
    let interestChart = 0;
    for (let month = 1; month <= totalMonths; month++) {
      currentBalance += +monthlySipAmount;
      const interest = +currentBalance * +monthlyReturnRate;
      currentBalance += +interest;

      records.push({
        month: month,
        Deposit: +monthlySipAmount,
        Interest: +interest.toFixed(0),
        EndingBalance: +currentBalance.toFixed(0),
      });

      principalChart += +monthlySipAmount;
      interestChart += +interest;
      if (month % 12 === 0) {
        temp?.push({
          principal: principalChart.toFixed(0),
          interest: interestChart.toFixed(0),
        });
      }
    }

    setYearlySummary([...temp]);

    setMonthlyRecords(records);
  }, [monthlySipAmount, sipInterval, expectInterest]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={7} className="calculation-part">
          <Grid>
            <InputComponent
              isInput={true}
              flag="rupee"
              label="Monthly SIP Amount"
              value={monthlySipAmount}
              min={1}
              setState={setMonthlySipAmount}
              max={10000000000}
            />
            <InputComponent
              isInput={true}
              flag="percentage"
              label="Expected Return Rate (p.a)"
              setState={setExpectInterest}
              value={expectInterest}
              min={0}
              max={999}
            />
            <InputComponent
              isInput={true}
              flag="year"
              label="SIP Period"
              setState={setSipInterval}
              value={sipInterval}
              min={0}
              max={199}
            />
          </Grid>
          <Grid className="result-label">
            <Typography>Result</Typography>
          </Grid>
          <Grid className="result-common-div">
            <InputComponent
              label="Invested Amount"
              isInput={false}
              value={investedAmount}
            />

            <InputComponent
              label="Estimate Return"
              isInput={false}
              value={estimateReturns}
            />
            <InputComponent
              label="Total Value"
              isInput={false}
              value={totalValue}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} sx={{ display: "flex" }}>
          <PieChartComponent
            data={[
              { id: 0, value: +investedAmount, label: "Invested Amount" },
              { id: 1, value: +estimateReturns, label: "Est. Returns" },
            ]}
          />
        </Grid>
      </Grid>
      <Grid className="data-table-container">
        <Grid className="table-toggle-div">
          <Typography variant="h5">
            Your Amortization Details{" "}
            <span>
              (<u>Monthly</u>)
            </span>
          </Typography>
          <Tooltip
            title={tableView ? "click to close table" : "click to open table"}
            placement="top"
            arrow
          >
            <span>
              <img
                src={plusIcon}
                alt="plusIcon"
                onClick={() => setTableView(!tableView)}
                style={{
                  transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                }}
                className="add-icon-table"
              />
            </span>
          </Tooltip>
        </Grid>
        {tableView && (
          <Grid>
            <Grid>
              <TableComponent
                rows={monthlyRecords}
                headerData={["Month", "Deposit", "Interest", "Ending Balance"]}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={5} className="piechart-div">
        <BarChartComponent
          data={[
            {
              data: yearlySummary?.map((x) => x?.principal),
              stack: "A",
              label: "Principal Amount",
            },
            {
              data: yearlySummary?.map((x) => x?.interest),
              stack: "A",
              label: "Interest Value",
            },
          ]}
        />
      </Grid>
    </>
  );
}

export default SipCalculation;
