import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxModified from "../CalorieCalc/SelectBoxModified";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";

import { MACRO_FORMULAS, GENDERS } from "../../../Utils/calorieHelper";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import UnitConversion from "../../Common/UnitConversion/UnitConversion";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";

const MACRO_ACTIVITY = [
  {
    name: "Basal Metabolic Rate (BMR)",
    value: "bmr",
    multiplier: 1,
  },
  {
    name: "Sedentary: little or no exercise",
    value: "sedentary",
    multiplier: 1.2,
  },
  {
    name: "Light: exercise 1-3 times/week",
    value: "light",
    multiplier: 1.375,
  },
  {
    name: "Moderate: exercise 4-5 times/week",
    value: "moderate",
    multiplier: 1.55,
  },
  {
    name: "Active: daily exercise or intense exercise 3-4 times/week",
    value: "active",
    multiplier: 1.725,
  },
  {
    name: "Very Active: intense exercise 6-7 times/week",
    value: "veryactive",
    multiplier: 1.9,
  },
  {
    name: "Extra Active: very intense exercise daily, or physical job",
    value: "extraactive",
    multiplier: 2.0,
  },
];

const MACRO_GOALS = [
  {
    name: "Maintain weight",
    value: "maintain",
  },
  {
    name: "Mild weight loss (0.25 kg/week)",
    value: "mildWL",
  },
  {
    name: "Weight loss (0.5 kg/week)",
    value: "WL",
  },
  {
    name: "Extreme weight loss (1 kg/week)",
    value: "extremeWL",
  },
  {
    name: "Mild weight gain (0.25 kg/week)",
    value: "mildWG",
  },
  {
    name: "Weight gain (0.5 kg/week)",
    value: "WG",
  },
  {
    name: "Extreme weight gain (1 kg/week)",
    value: "extremeWG",
  },
];

const ACTIVITY_NAMES = MACRO_ACTIVITY.map((item) => ({
  name: item.name,
  value: item.value,
}));

const GOAL_NAMES = MACRO_GOALS.map((item) => ({
  name: item.name,
  value: item.value,
}));

export default function MacroCalc() {
  const [weight, setWeight] = useState({ unit: "kg", value: 70 });
  const [height, setHeight] = useState({ unit: "cm", value: 170 });
  const [age, setAge] = useState(22);
  const [gender, setGender] = useState({ name: "Male", value: "male" });
  const [unitConvert, setUnitConvert] = useState(false);
  const [fat, setFat] = useState(0.2);
  const [BMRFormula, setBMRFormula] = useState({
    name: "Mifflin-St Jeor",
    value: "MJ",
  });
  const [activity, setActivity] = useState({
    name: "Moderate: exercise 4-5 times/week",
    value: "moderate",
  });
  const [goal, setGoal] = useState({
    name: "Maintain weight",
    value: "maintain",
  });
  const [showSettings, setShowSettings] = useState(false);
  const [plan, setPlan] = useState("Balanced");
  const [protein, setProtein] = useState();
  const [carbs, setCarbs] = useState();
  const [fatInGrams, setFatInGrams] = useState();
  const [sugar, setSugar] = useState();
  const [saturatedFat, setSaturatedFat] = useState();
  const [foodEnergy, setFoodEnergy] = useState();


  useEffect(() => {
    calculateMacros();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weight, height, gender, age, fat, activity, goal, plan]);

  const calculateBMR = () => {
    if (BMRFormula.value === "MJ") {
      return (
        10 * weight.value +
        6.25 * height.value -
        5 * age +
        (gender.value === "male" ? 5 : -161)
      );
    } else {
      return 370 + 21.6 * (1 - fat) * weight.value;
    }
  };

  const calculateMacros = () => {
    const bmr = calculateBMR();
    const activityMultiplier = MACRO_ACTIVITY.find(
      (act) => act.value === activity.value
    ).multiplier;
    const tdee = bmr * activityMultiplier;

    let adjustedTDEE = tdee;
    // switch (goal.value) {
    //   case "mildWL":
    //     adjustedTDEE -= 250;
    //     break;
    //   case "WL":
    //     adjustedTDEE -= 500;
    //     break;
    //   case "extremeWL":
    //     adjustedTDEE -= 1000;
    //     break;
    //   case "mildWG":
    //     adjustedTDEE += 250;
    //     break;
    //   case "WG":
    //     adjustedTDEE += 500;
    //     break;
    //   case "extremeWG":
    //     adjustedTDEE += 1000;
    //     break;
    //   default:
    //     adjustedTDEE = tdee;
    // }

    switch (goal.value) {
      case "mildWL":
        adjustedTDEE -= adjustedTDEE * 0.1;
        break;
      case "WL":
        adjustedTDEE -= adjustedTDEE * 0.2;
        break;
      case "extremeWL":
        adjustedTDEE -= adjustedTDEE * 0.3;
        break;
      case "mildWG":
        adjustedTDEE += adjustedTDEE * 0.1;
        break;
      case "WG":
        adjustedTDEE += adjustedTDEE * 0.2;
        break;
      case "extremeWG":
        adjustedTDEE += adjustedTDEE * 0.3;
        break;
      default:
        adjustedTDEE = tdee;
    }

    switch (plan) {
      case "Balanced":
        setProtein(Math.round((0.3 * adjustedTDEE) / 4)); // 30% from protein
        setCarbs(Math.round((0.4 * adjustedTDEE) / 4)); // 40% from carbs
        setFatInGrams(Math.round((0.3 * adjustedTDEE) / 9)); // 30% from fat
        break;
      case "Low Fat":
        setProtein(Math.round((0.25 * adjustedTDEE) / 4)); // 25% from protein
        setCarbs(Math.round((0.55 * adjustedTDEE) / 4)); // 55% from carbs
        setFatInGrams(Math.round((0.2 * adjustedTDEE) / 9)); // 20% from fat
        break;
      case "Low Carb":
        setProtein(Math.round((0.4 * adjustedTDEE) / 4)); // 40% from protein
        setCarbs(Math.round((0.2 * adjustedTDEE) / 4)); // 20% from carbs
        setFatInGrams(Math.round((0.4 * adjustedTDEE) / 9)); // 40% from fat
        break;
      case "High Protein":
        setProtein(Math.round((0.35 * adjustedTDEE) / 4)); // 35% from protein
        setCarbs(Math.round((0.25 * adjustedTDEE) / 4)); // 25% from carbs
        setFatInGrams(Math.round((0.4 * adjustedTDEE) / 9)); // 40% from fat
        break;
      default:
        setProtein(0);
        setCarbs(0);
        setFatInGrams(0);
    }

    setSugar(Math.round((0.15 * adjustedTDEE) / 4));
    setSaturatedFat(Math.round((0.1 * adjustedTDEE) / 9));
    setFoodEnergy(Math.round(adjustedTDEE));
  };

  const weightUnitSetter = (value) =>
    setWeight((prev) => ({ ...prev, unit: value }));
  const weightValueSetter = (event) => {
    const value = parseFloat(event.target.value);
    setWeight((prev) => ({ ...prev, value: Math.min(value, 500) }));
  };

  const heightUnitSetter = (value) => {
    if (value === "ft") {
      setHeight({ unit: value, value: { ft: 5, in: 8 } });
    } else if (value === "cm") {
      setHeight({ unit: value, value: 170 });
    } else {
      setHeight((prev) => ({ ...prev, unit: value }));
    }
  };

  const heightCMValueSetter = (value) =>
    setHeight((prev) => ({ ...prev, value: Math.min(value, 300) }));

  const heightFTValueSetter = (value) =>
    setHeight((prev) => ({
      ...prev,
      value: { ...prev.value, ft: Math.min(value, 8) },
    }));

  const heightINValueSetter = (value) =>
    setHeight((prev) => ({
      ...prev,
      value: { ...prev.value, in: Math.min(value, 11) },
    }));

  const fatSetter = (value) => setFat(() => Math.min(value / 100, 1));

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                label="Age"
                isInput={true}
                min={1}
                max={199}
                value={age}
                setState={setAge}
              />
              <RadioButtonComponent
                label={"Gender"}
                data={GENDERS}
                defaultValue={"male"}
                valueSetter={setGender}
                row={false}
              />
              {height.unit === "cm" && (
                <InputComponent
                  label="Height (cm)"
                  isInput={true}
                  min={1}
                  max={200}
                  value={height.value}
                  setState={heightCMValueSetter}
                />
              )}

              {height.unit === "ft" && (
                <>
                  <InputComponent
                    label="Height (ft)"
                    isInput={true}
                    min={1}
                    max={10}
                    value={height.value.ft}
                    setState={heightFTValueSetter}
                  />

                  <InputComponent
                    label="Height (in)"
                    isInput={true}
                    max={20}
                    value={height.value.in}
                    setState={heightINValueSetter}
                  />
                </>
              )}
              <SelectBoxComponent
                label="Height (cm)"
                value={height.unit}
                setValue={heightUnitSetter}
                data={["cm", "ft"]}
                placeholder="Select height unit"
              />
              <br />
              <Grid>
                <Grid className="concrete-calc-input pace">
                  <Typography className="input-label">Weight</Typography>
                  <Grid sx={{ display: "flex" }}>
                    <input
                      type="number"
                      value={weight.value}
                      onChange={weightValueSetter}
                      className="input-field"
                      min={1}
                      max={999}
                    />

                    <Grid className="select-box-wrap">
                      <SelectBoxComponent
                        value={weight.unit}
                        setValue={weightUnitSetter}
                        data={["kg", "lb"]}
                        placeholder="Select weight unit"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Typography
              variant="h6"
              sx={{ mx: 1, fontWeight: 500, fontSize: "18px", mt: 2 }}
            >
              Activity
            </Typography>

            <SelectBoxModified
              value={activity.value}
              valueSetter={setActivity}
              data={ACTIVITY_NAMES}
              placeholder="Select your activity"
            />

            <Typography
              variant="h6"
              sx={{ mx: 1, fontWeight: 500, fontSize: "18px", mt: 2 }}
            >
              Your Goal
            </Typography>

            <SelectBoxModified
              value={goal.value}
              valueSetter={setGoal}
              data={GOAL_NAMES}
              placeholder="Select your activity"
            />

            <Grid
              id="settings"
              sx={{ ml: 1.5 }}
              className="data-table-container"
            >
              <Grid item>
                <Grid className="table-toggle-div">
                  <Typography
                    variant="h6"
                    sx={{ color: "black", fontWeight: 600 }}
                  >
                    Settings
                  </Typography>
                  <Tooltip
                    title={
                      showSettings
                        ? "click to close setting"
                        : "click to open setting"
                    }
                    placement="top"
                    arrow
                  >
                    <span>
                      <img
                        src={plusIcon}
                        alt="plusIcon"
                        onClick={() => setShowSettings((prev) => !prev)}
                        style={{
                          transform: showSettings
                            ? "rotate(45deg)"
                            : "rotate(0deg)",
                        }}
                        className="add-icon-table"
                      />
                    </span>
                  </Tooltip>
                </Grid>
                {showSettings && (
                  <>
                    <Grid item>
                      <RadioButtonComponent
                        label={"BMR estimation formula"}
                        data={MACRO_FORMULAS}
                        defaultValue={"MJ"}
                        valueSetter={setBMRFormula}
                        row={false}
                      />

                      {BMRFormula.value === "KM" && (
                        <InputComponent
                          label="Body Fat (%)"
                          isInput={true}
                          value={fat * 100}
                          setState={fatSetter}
                        />
                      )}
                    </Grid>
                  </>
                )}
                {!unitConvert ? (
                  <Grid sx={{ textAlign: "center" }}>
                    <Button
                      onClick={(e) => setUnitConvert(!unitConvert)}
                      variant="contained"
                    >
                      Unit Conversion
                    </Button>
                  </Grid>
                ) : (
                  <UnitConversion setUnitConvert={setUnitConvert} />
                )}
              </Grid>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid sx={{ margin: "20px 35px" }}>
              <TabbingComponent
                data={["Balanced", "Low Fat", "Low Carb", "High Protein"]}
                value={plan}
                setValue={setPlan}
              />
            </Grid>
            <InputComponent
              label="Protein"
              isInput={false}
              value={`${protein || 0} grams/day`}
              date={true}
            />
            <InputComponent
              label="Carbs"
              isInput={false}
              value={`${carbs || 0} grams/day`}
              date={true}
            />
            <InputComponent
              label="Fat"
              isInput={false}
              value={`${fatInGrams || 0} grams/day`}
              date={true}
            />
            <InputComponent
              label="Sugar"
              isInput={false}
              value={`${sugar || 0} grams/day`}
              date={true}
            />
            <InputComponent
              label="Saturated Fat"
              isInput={false}
              value={`${saturatedFat || 0} grams/day`}
              date={true}
            />
            <InputComponent
              label="Food Energy"
              isInput={false}
              value={`${foodEnergy || 0} Calories/day`}
              date={true}
            />
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              flag="invest"
              data={[
                { id: 0, value: +protein, label: "Protein(grams/day)" },
                { id: 1, value: +carbs, label: "Carbs(grams/day)" },
                { id: 2, value: +fatInGrams, label: "Fat(grams/day)" },
                { id: 3, value: +sugar, label: "Sugar(grams/day)" },
                {
                  id: 4,
                  value: +saturatedFat,
                  label: "Saturated Fat(grams/day)",
                },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
