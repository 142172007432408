import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import DateView from "../../Common/CalenderView/CalenderView";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import InputSlider from "../../Common/Slider/Slider";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import InputComponent from "../../Common/InputComponent/InputComponent";
import {
  generateUsingLastPeriod,
  generateConceptionDate,
  generateIVF,
  generateUltrasound,
} from "../../../Utils/pregnancyHelper";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import Table from "../PregnancyCalc/Table";
import dayjs from "dayjs";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

export default function DueDateCalc() {
  const [parameter, setParameter] = useState("Last Period");
  const [inputDate, setInputDate] = useState(dayjs());
  const [avgCycLen, setAvgCycLen] = useState(28);
  const [embryoAge, setEmbryoAge] = useState({ label: "5 Days", value: 5 });
  const [tableData, setTableData] = useState([]);

  const [LOPWeek, setLOPWeek] = useState(22);
  const [LOPDay, setLOPDay] = useState(6);

  const [resultParagraph, setResultParagraph] = useState("");
  useEffect(() => {
    if (parameter === "Last Period") {
      let data = generateUsingLastPeriod(inputDate, avgCycLen);
      setResultParagraph(data.paragraphStrings);
      setTableData(data.tableData);
    } else if (parameter === "Conception Date") {
      let data = generateConceptionDate(inputDate);
      setResultParagraph(data.paragraphStrings);
      setTableData(data.tableData);
    } else if (parameter === "IVF Transfer Date") {
      let data = generateIVF(inputDate, embryoAge.value);
      setResultParagraph(data.paragraphStrings);
      setTableData(data.tableData);
    } else if (parameter === "Ultrasound") {
      let data = generateUltrasound(inputDate, LOPWeek, LOPDay);
      setResultParagraph(data.paragraphStrings);
      setTableData(data.tableData);
    }
  }, [parameter, inputDate, avgCycLen, embryoAge, LOPWeek, LOPDay]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Grid item className="calculation-part ">
              <Grid item>
                <Typography className="common-sub-heading-desc">
                  The Pregnancy Calculator can estimate a pregnancy schedule
                  based on the provided due date, last period date, ultrasound
                  date, conception date, or IVF transfer date.
                </Typography>
              </Grid>

              <Grid item sx={{ mx: 1, mb: -1 }}>
                <SelectBoxComponent
                  value={parameter}
                  setValue={setParameter}
                  data={[
                    "Last Period",
                    "Ultrasound",
                    "Conception Date",
                    "IVF Transfer Date",
                  ]}
                  placeholder="Select a parameter"
                  label="Calculate Based On:"
                />
              </Grid>

              {parameter === "Last Period" && (
                <>
                  <Grid item sx={{ mt: 3 }}>
                    <Grid item>
                      <Typography
                        variant="h6"
                        sx={{ mx: 1, fontWeight: 600, fontSize: "18px" }}
                      >
                        First Day of Your Last Period:
                      </Typography>
                      <DateView date={inputDate} valueSetter={setInputDate} />
                    </Grid>

                    <Grid item>
                      <InputSlider
                        label="Average Length of Your Cycles: "
                        value={avgCycLen}
                        valueSetter={setAvgCycLen}
                        min={22}
                        max={44}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid className="data-table-container">
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 5,
                          ml: 1,
                          fontSize: "1.08rem",
                          fontWeight: 600,
                        }}
                      >
                        {resultParagraph?.dueDate} <br></br>
                        {resultParagraph?.conceivedDate} <br></br>
                      </Typography>

                      {tableData.length >= 0 && inputDate < new Date() && (
                        <Grid sx={{ mt: 2 }}>
                          <Table
                            rows={tableData}
                            headerData={[
                              "Week",
                              "Date",
                              "Trimester",
                              "Important Milestones",
                            ]}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              {parameter === "Conception Date" && (
                <>
                  <Grid item sx={{ mt: 3 }}>
                    <Typography
                      variant="h6"
                      sx={{ mx: 1, fontWeight: 600, fontSize: "18px" }}
                    >
                      Conception Date:
                    </Typography>
                    <DateView date={inputDate} valueSetter={setInputDate} />
                  </Grid>
                  <Grid item>
                    <Grid className="data-table-container">
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 5,
                          ml: 1,
                          fontSize: "1.08rem",
                          fontWeight: 600,
                        }}
                      >
                        {resultParagraph?.dueDateStr} <br></br>
                        {resultParagraph?.trimesterStr} <br></br>
                        {resultParagraph?.progressStr} <br></br>
                      </Typography>

                      {tableData.length >= 0 && (
                        <Grid sx={{ mt: 2 }}>
                          <Table
                            rows={tableData}
                            headerData={[
                              "Week",
                              "Date",
                              "Trimester",
                              "Important Milestones",
                            ]}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              {parameter === "IVF Transfer Date" && (
                <>
                  <Grid item sx={{ mt: 3 }}>
                    <Typography
                      variant="h6"
                      sx={{ mx: 1, fontWeight: 600, fontSize: "18px" }}
                    >
                      Transfer Date:
                    </Typography>
                    <DateView date={inputDate} valueSetter={setInputDate} />
                    <RadioButtonComponent
                      label={"Embryo Age:"}
                      data={[
                        { label: "3 Days", value: 3 },
                        { label: "5 Days", value: 5 },
                        { label: "6 Days", value: 6 },
                      ]}
                      defaultValue={5}
                      valueSetter={setEmbryoAge}
                      row={false}
                    />
                  </Grid>
                  <Grid item>
                    <Grid className="data-table-container">
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 5,
                          ml: 1,
                          fontSize: "1.08rem",
                          fontWeight: 600,
                        }}
                      >
                        {resultParagraph?.dueDateStr} <br></br>
                        {resultParagraph?.trimesterStr} <br></br>
                        {resultParagraph?.progressStr} <br></br>
                      </Typography>

                      {tableData.length >= 0 && (
                        <Grid sx={{ mt: 2 }}>
                          <Table
                            rows={tableData}
                            headerData={[
                              "Week",
                              "Date",
                              "Trimester",
                              "Important Milestones",
                            ]}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              {parameter === "Ultrasound" && (
                <>
                  <Grid item sx={{ mt: 3 }}>
                    <Typography
                      variant="h6"
                      sx={{ mx: 1, fontWeight: 600, fontSize: "18px" }}
                    >
                      Ultrasound Date:
                    </Typography>
                    <DateView date={inputDate} valueSetter={setInputDate} />
                    <Typography
                      variant="h6"
                      sx={{ mx: 1, fontWeight: 600, fontSize: "18px" }}
                    >
                      Length of Pregnancy at the Time:
                    </Typography>
                    <InputComponent
                      label="Weeks"
                      isInput={true}
                      value={LOPWeek}
                      setState={(value) => setLOPWeek(Math.min(42, value))}
                    />

                    <InputComponent
                      label="Days"
                      isInput={true}
                      value={LOPDay}
                      setState={(value) => setLOPDay(Math.min(295, value))}
                    />
                  </Grid>
                  <Grid item>
                    <Grid className="data-table-container">
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 5,
                          ml: 1,
                          fontSize: "1.08rem",
                          fontWeight: 600,
                        }}
                      >
                        {resultParagraph?.dueDateStr} <br></br>
                        {resultParagraph?.trimesterStr} <br></br>
                        {resultParagraph?.progressStr} <br></br>
                        {resultParagraph?.conceivedStr} <br></br>
                      </Typography>

                      {tableData.length >= 0 && (
                        <Grid sx={{ mt: 2 }}>
                          <Table
                            rows={tableData}
                            headerData={[
                              "Week",
                              "Date",
                              "Trimester",
                              "Important Milestones",
                            ]}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              <Typography
                sx={{ mt: 5, ml: 1, fontSize: "1.08rem", opacity: 0.6 }}
              >
                Note: The results of this calculator are estimations based on
                averages for single pregnancy. The results for twin pregnancy or
                multiple pregnancy are different.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
