/* eslint-disable no-eval */
import React, { useState } from "react";
import "./ScientificCalc.scss";
import { Button, Grid } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";

const ScientificCalculator = () => {
  const [display, setDisplay] = useState("0");

  const handleNumberClick = (value) => {
    if (display === "0" || display === "Error") {
      setDisplay(value);
    } else {
      setDisplay(display + value);
    }
  };

  const handleOperatorClick = (value) => {
    setDisplay(display + value);
  };

  const handleClear = () => {
    setDisplay("0");
  };

  const handleBackspace = () => {
    setDisplay(
      display.length > 1 ? display?.slice(0, -1) : display?.length === 1 ? 0 : 0
    );
  };

  const handleCalculate = () => {
    try {
      setDisplay(eval(display).toString());
    } catch (error) {
      setDisplay("Error");
    }
  };
  const factorial = () => {
    if (display === 0 || display === 1) {
      setDisplay("1");
    } else {
      var result = 1;
      for (var i = 2; i <= display; i++) {
        result *= i;
      }
      setDisplay(`${result}`);
    }
  };
  const handleScientificFunction = (func) => {
    try {
      if (func === "pow(10,") {
        const result = eval(`Math.${func}${display})`);
        setDisplay(result.toFixed(0).toString());
      } else if (func === "x^3") {
        const result = eval(`Math.pow(${display}, 3)`);
        setDisplay(result.toFixed(0).toString());
      } else if (func === "x^2") {
        const result = eval(`Math.pow(${display}, 2)`);
        setDisplay(result.toFixed(0).toString());
      } else if (func === "cubeRoot") {
        const result = eval(`Math.pow(${display}, 1/3)`);
        setDisplay(result.toFixed(2).toString());
      } else {
        if (
          func === "tan" ||
          func === "sin" ||
          func === "cos" ||
          func === "sinh" ||
          func === "cosh" ||
          func === "tanh"
        ) {
          let tempDisplay = display * (Math.PI / 180);

          const result = eval(`Math.${func}(${tempDisplay})`);
          setDisplay(result.toFixed(2).toString());
        } else if (
          func === "asin" ||
          func === "acos" ||
          func === "atan" ||
          func === "asinh" ||
          func === "acosh" ||
          func === "atanh"
        ) {
          const result = eval(`Math.${func}(${display})`);
          let tempResult = result * (180 / Math.PI);
          setDisplay(`${tempResult.toFixed(0).toString()}°`);
        } else {
          const result = eval(`Math.${func}(${display})`);
          setDisplay(result.toFixed(2).toString());
        }
      }
    } catch (error) {
      setDisplay("Error");
    }
  };
  return (
    <Grid className="scientific-calculator calculation-part notranslate">
      <Grid
        className="display"
        sx={{
          color:
            display === "Error" || display === "NaN" || display === "Infinity"
              ? "red"
              : "white",
        }}
      >
        {display}
      </Grid>
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={5} md={5} lg={6} className="buttons-div">
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("sin")}
          >
            sin
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("cos")}
          >
            cos
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("tan")}
          >
            tan
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("asin")}
          >
            sin<sup>-1</sup>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("acos")}
          >
            cos<sup>-1</sup>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("atan")}
          >
            tan<sup>-1</sup>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("sinh")}
          >
            sinh
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("cosh")}
          >
            cosh
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("tanh")}
          >
            tanh
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("asinh")}
          >
            sinh<sup>-1</sup>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("acosh")}
          >
            cosh<sup>-1</sup>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("atanh")}
          >
            tanh<sup>-1</sup>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleNumberClick(Math.PI)}
          >
            π
          </Button>

          <Button
            variant="contained"
            onClick={() => handleScientificFunction("x^3")}
          >
            x<sup>3</sup>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("x^2")}
          >
            x<sup>2</sup>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("cubeRoot")}
          >
            3√x
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("sqrt")}
          >
            2√x
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("exp")}
          >
            e<sup>x</sup>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("pow(10,")}
          >
            10<sup>x</sup>
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("log10")}
          >
            log
          </Button>
          <Button
            variant="contained"
            onClick={() => handleScientificFunction("log")}
          >
            ln
          </Button>

          <Button variant="contained" onClick={() => factorial()}>
            n!
          </Button>

          <Button
            variant="contained"
            onClick={() => handleScientificFunction("abs")}
          >
            |x|
          </Button>
        </Grid>
        <Grid
          item
          xs={8.9}
          sm={5}
          md={5}
          lg={4.5}
          className="buttons-div"
          sx={{ maxWidth: { xs: 256, sm: "auto" } }}
        >
          <Button
            variant="contained"
            className="button-number"
            onClick={() => handleNumberClick("7")}
          >
            7
          </Button>
          <Button
            variant="contained"
            className="button-number"
            onClick={() => handleNumberClick("8")}
          >
            8
          </Button>
          <Button
            variant="contained"
            className="button-number"
            onClick={() => handleNumberClick("9")}
          >
            9
          </Button>

          <Button
            variant="contained"
            className="button-number"
            onClick={() => handleNumberClick("4")}
          >
            4
          </Button>
          <Button
            variant="contained"
            className="button-number"
            onClick={() => handleNumberClick("5")}
          >
            5
          </Button>
          <Button
            variant="contained"
            className="button-number"
            onClick={() => handleNumberClick("6")}
          >
            6
          </Button>

          <Button
            variant="contained"
            className="button-number"
            onClick={() => handleNumberClick("1")}
          >
            1
          </Button>
          <Button
            variant="contained"
            className="button-number"
            onClick={() => handleNumberClick("2")}
          >
            2
          </Button>
          <Button
            variant="contained"
            className="button-number"
            onClick={() => handleNumberClick("3")}
          >
            3
          </Button>

          <Button
            variant="contained"
            className="button-number"
            onClick={() => handleNumberClick("0")}
          >
            0
          </Button>
          <Button variant="contained" onClick={() => handleNumberClick(".")}>
            .
          </Button>
          <Button
            variant="contained"
            className="button-number"
            color="success"
            onClick={handleCalculate}
          >
            =
          </Button>
        </Grid>
        <Grid item xs={3.1} sm={2} md={2} lg={1.5} className="buttons-div">
          <Button
            variant="contained"
            className="button-number btn-backspace"
            onClick={handleBackspace}
          >
            <BackspaceIcon />
          </Button>
          <Button
            variant="contained"
            className="button-number"
            onClick={handleClear}
          >
            C
          </Button>
          <Button variant="contained" onClick={() => handleOperatorClick("+")}>
            +
          </Button>
          <Button variant="contained" onClick={() => handleOperatorClick("-")}>
            -
          </Button>
          <Button variant="contained" onClick={() => handleOperatorClick("*")}>
            *
          </Button>
          <Button variant="contained" onClick={() => handleOperatorClick("/")}>
            /
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScientificCalculator;
