import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import "./Table.scss";
import { Typography } from "@mui/material";

const GRADES = [
  "A+",
  "A",
  "A-",
  "B+",
  "B",
  "B-",
  "C+",
  "C",
  "C-",
  "D+",
  "D",
  "D-",
  "F",
  "P",
  "NP",
];
const GRADE_POINTS = {
  "A+": 4.3,
  A: 4,
  "A-": 3.7,
  "B+": 3.3,
  B: 3,
  "B-": 2.7,
  "C+": 2.3,
  C: 2,
  "C-": 1.7,
  "D+": 1.3,
  D: 1,
  "D-": 0.7,
  F: 0,
  P: -1,
  NP: -1,
};

export default function DenseTable({
  numOfRows,
  headerData,
  courses,
  credits,
  grades,
}) {
  const rowHeaderCellStyling = {
    align: "left",
    backgroundColor: "#295fa6",
    fontWeight: "bold",
    color: "white",
    paddingRight: "5px",
  };

  return (
    <TableContainer className="tableBorder" sx={{}} component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ border: "2px solid #295fa6" }}>
            {headerData?.map((item, index) => (
              <TableCell
                align="left"
                sx={{ ...rowHeaderCellStyling }}
                key={index}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(numOfRows)].map((value, i) => (
            <TableRow >
              <TableCell>
                <TextField
                  sx={{ width: "100%" }}
                  id={`co${i}`}
                  name={`co${i}`}
                  label="Subject"
                  size="small"
                  variant="outlined"
                  value={courses.data[`co${i}`]}
                  onChange={(event) => {
                    courses.setter((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  sx={{ width: "100%" }}
                  id={`cr${i}`}
                  name={`cr${i}`}
                  label="Credits"
                  size="small"
                  variant="outlined"
                  value={credits.data[`cr${i}`]}
                  inputProps={{ type: "number" }}
                  onChange={(event) => {
                    credits.setter((prev) => ({
                      ...prev,
                      [event.target.name]: Math.max(
                        parseInt(event.target.value),
                        0
                      ),
                    }));
                  }}
                />
              </TableCell>
              <TableCell sx={{ paddingY: 0 }}>
                <SelectBoxComponent
                  value={grades.data[`gr${i}`]}
                  setValue={(value) => {
                    grades.setter((prev) => ({ ...prev, [`gr${i}`]: value }));
                  }}
                  data={GRADES}
                  placeholder={"Select the course grade"}
                />
              </TableCell>
              <TableCell>
                <Typography>
                  {GRADE_POINTS[grades.data[`gr${i}`]] === -1
                    ? "Not counted"
                    : credits.data[`cr${i}`] +
                      " x " +
                      GRADE_POINTS[grades.data[`gr${i}`]] +
                      " = " +
                      (
                        credits.data[`cr${i}`] *
                        GRADE_POINTS[grades.data[`gr${i}`]]
                      ).toFixed(1)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
