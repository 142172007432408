import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import dayjs from "dayjs";
import CheckboxGroup from "../../Common/Checkbox/Checkbox";
import DatePickerCommon from "../../Common/Datepicker/DatePickerCommon";
import PieChartComponent from "../../Common/PieChart/PieChart";

function FHALoanCalc() {
  const [homePrice, setHomePrice] = useState(500000);
  const [downPayment, setDownPayment] = useState(3.5);
  const [loanTerm, setLoanTerm] = useState(30);
  const [interestRate, setInterestRate] = useState(6.4);
  const [upfrontFhaMip, setUpfrontFhaMip] = useState(1.75);
  const [annualFhaMip, setAnnualFhaMip] = useState(0.55);
  const [annualFhaMipDuration, setAnnualFhaMipDuration] = useState("78% LTV");
  const [OptionalCheckBox, setOptionalCheckBox] = useState(true);
  const [propertyTaxes, setPropertyTaxes] = useState(1);
  const [homeInsurance, setHomeInsurance] = useState(2500);
  const [hoaFee, setHoaFee] = useState(0);
  const [otherCosts, setOtherCosts] = useState(5000);
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [isFewerOptions, setIsFewerOptions] = useState(false);
  const [propertyTaxesIncrease, setPropertyTaxesIncrease] = useState(0);
  const [homeInsuranceIncrease, setHomeInsuranceIncrease] = useState(0);
  const [hoaFeeIncrease, setHoaFeeIncrease] = useState(0);
  const [otherCostsIncrease, setOtherCostsIncrease] = useState(0);
  const [extraMonthlyPay, setExtraMonthlyPay] = useState(0);
  const [extraYearlyPay, setExtraYearlyPay] = useState(0);
  const [extraOnetimePay, setExtraOnetimePay] = useState(0);
  const [biweeklyCheckBox, setBiweeklyCheckBox] = useState(false);
  const [housePriceValue, setHousePriceValue] = useState(0);
  const [loanAmountWithUpfrontMip, setLoanAmountWithUpfrontMip] = useState(0);
  const [downPaymentValue, setDownPaymentValue] = useState(0);
  const [upfrontMip, setUpfrontMip] = useState(0);
  const [totalOfMortgagePayments, setTotalOfMortgagePayments] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [mortgagePayoffDate, setMortgagePayoffDate] = useState(0);
  const [annualMipPayoffDate, setAnnualMipPayoffDate] = useState(0);
  const [biWeeklyPayment, setBiWeeklyPayment] = useState(0);
  const [totalInterestBiWeekly, setTotalInterestBiWeekly] = useState(0);
  const [payoff, setPayoff] = useState(0);
  const [resultDataMonthly, setResultDataMonthly] = useState({
    mortgagePayment: 0,
    propertyTax: 0,
    homeInsurance: 0,
    annualMIP: 0,
    otherCosts: 0,
    totalOutOfPocket: 0,
  });
  const [resultDataTotal, setResultDataTotal] = useState({
    mortgagePayment: 0,
    propertyTax: 0,
    homeInsurance: 0,
    annualMIP: 0,
    otherCosts: 0,
    totalOutOfPocket: 0,
  });

  useEffect(() => {}, []);
  const handleCheckboxChange = (newSelectedValues, flag) => {
    console.log("newSelectedValues", newSelectedValues);
    if (flag === "first") {
      if (newSelectedValues[0] === "Include Optionals Below") {
        setOptionalCheckBox(true);
      } else {
        setOptionalCheckBox(false);
      }
    } else {
      if (newSelectedValues[0] === "Show Biweekly Payback Results") {
        setBiweeklyCheckBox(true);
      } else {
        setBiweeklyCheckBox(false);
      }
    }
  };
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Home Price"
                value={homePrice}
                min={0}
                setState={setHomePrice}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Down Payment"
                value={downPayment}
                min={0}
                setState={setDownPayment}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Term"
                value={loanTerm}
                min={0}
                setState={setLoanTerm}
                max={50}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest Rate"
                value={interestRate}
                min={0}
                setState={setInterestRate}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Upfront FHA MIP"
                value={upfrontFhaMip}
                min={0}
                setState={setUpfrontFhaMip}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Annual FHA MIP"
                value={annualFhaMip}
                min={0}
                setState={setAnnualFhaMip}
                max={999}
              />
              <SelectBoxComponent
                value={annualFhaMipDuration}
                setValue={setAnnualFhaMipDuration}
                data={[
                  "Loan Term",
                  "11 Years",
                  "5 Years",
                  "78% LTV",
                  "No Annual MIP",
                ]}
                label="Annual FHA MIP Duration"
              />
              <CheckboxGroup
                data={[
                  {
                    label: "Include Optionals Below",
                    value: "Include Optionals Below",
                  },
                ]}
                valueSetter={(e) => handleCheckboxChange(e, "first")}
                defaultValues={["Include Optionals Below"]}
                row={true}
              />
              {OptionalCheckBox && (
                <>
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Property Taxes"
                    value={propertyTaxes}
                    min={0}
                    setState={setPropertyTaxes}
                    max={999}
                  />
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Home Insurance"
                    value={homeInsurance}
                    min={0}
                    setState={setHomeInsurance}
                    max={10000000000}
                  />
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="HOA Fee"
                    value={hoaFee}
                    min={0}
                    setState={setHoaFee}
                    max={10000000000}
                  />
                  <InputComponent
                    isInput={true}
                    flag="dollar"
                    label="Other Costs"
                    value={otherCosts}
                    min={0}
                    setState={setOtherCosts}
                    max={10000000000}
                  />
                  <DatePickerCommon
                    label="Start Date"
                    value={startDate}
                    setValue={setStartDate}
                    enableFutureDate={true}
                  />
                  {isFewerOptions ? (
                    <Typography
                      className="common-sub-heading-calc center-align"
                      onClick={() => setIsFewerOptions(!isFewerOptions)}
                    >
                      - Fewer Options
                    </Typography>
                  ) : (
                    <Typography
                      className="common-sub-heading-calc center-align"
                      onClick={() => setIsFewerOptions(!isFewerOptions)}
                    >
                      + More Options
                    </Typography>
                  )}
                  {isFewerOptions && (
                    <>
                      <Typography className="common-sub-heading-calc">
                        Annual Tax & Cost Increase
                      </Typography>
                      <InputComponent
                        isInput={true}
                        flag="percentage"
                        label="Property Taxes Increase"
                        value={propertyTaxesIncrease}
                        min={0}
                        setState={setPropertyTaxesIncrease}
                        max={999}
                      />{" "}
                      <InputComponent
                        isInput={true}
                        flag="percentage"
                        label="Home Insurance Increase"
                        value={homeInsuranceIncrease}
                        min={0}
                        setState={setHomeInsuranceIncrease}
                        max={999}
                      />{" "}
                      <InputComponent
                        isInput={true}
                        flag="percentage"
                        label="HOA Fee Increase"
                        value={hoaFeeIncrease}
                        min={0}
                        setState={setHoaFeeIncrease}
                        max={999}
                      />{" "}
                      <InputComponent
                        isInput={true}
                        flag="percentage"
                        label="Other Costs Increase"
                        value={otherCostsIncrease}
                        min={0}
                        setState={setOtherCostsIncrease}
                        max={999}
                      />
                      <Typography className="common-sub-heading-calc">
                        Extra Payments
                      </Typography>
                      <InputComponent
                        isInput={true}
                        flag="dollar"
                        label="Extra Monthly Pay"
                        value={extraMonthlyPay}
                        min={0}
                        setState={setExtraMonthlyPay}
                        max={10000000000}
                      />{" "}
                      <InputComponent
                        isInput={true}
                        flag="dollar"
                        label="Extra Yearly Pay"
                        value={extraYearlyPay}
                        min={0}
                        setState={setExtraYearlyPay}
                        max={10000000000}
                      />{" "}
                      <InputComponent
                        isInput={true}
                        flag="dollar"
                        label="Extra One-time Pay"
                        value={extraOnetimePay}
                        min={0}
                        setState={setExtraOnetimePay}
                        max={10000000000}
                      />
                      <CheckboxGroup
                        data={[
                          {
                            label: "Show Biweekly Payback Results",
                            value: "Show Biweekly Payback Results",
                          },
                        ]}
                        valueSetter={handleCheckboxChange}
                        defaultValues={false}
                        row={true}
                      />
                    </>
                  )}
                </>
              )}
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              {biweeklyCheckBox && (
                <>
                  <Typography className="common-sub-heading-calc">
                    If Payback Bi-weekly
                  </Typography>
                  <InputComponent
                    label="Bi-weekly Payment"
                    isInput={false}
                    flag="dollar"
                    value={biWeeklyPayment}
                  />
                  <InputComponent
                    label="Total Interest"
                    isInput={false}
                    flag="dollar"
                    value={totalInterestBiWeekly}
                  />
                  <InputComponent
                    label="Payoff"
                    isInput={false}
                    flag="dollar"
                    value={payoff}
                  />
                </>
              )}
              <Typography className="common-sub-heading-calc">
                House Price
              </Typography>
              <InputComponent
                label="House Price"
                isInput={false}
                flag="dollar"
                value={housePriceValue}
              />
              <InputComponent
                label="Loan Amount with Upfront MIP"
                isInput={false}
                flag="dollar"
                value={loanAmountWithUpfrontMip}
              />
              <InputComponent
                label="Down Payment"
                isInput={false}
                flag="dollar"
                value={downPaymentValue}
              />
              <InputComponent
                label="Upfront MIP"
                isInput={false}
                flag="dollar"
                value={upfrontMip}
              />
              <InputComponent
                label={`Total of ${loanTerm * 12} Mortgage Payments`}
                isInput={false}
                flag="dollar"
                value={totalOfMortgagePayments}
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                flag="dollar"
                value={totalInterest}
              />
              <InputComponent
                label="Mortgage Payoff Date"
                isInput={false}
                date={true}
                value={mortgagePayoffDate}
              />
              <InputComponent
                label="Annual MIP Payoff Date (147 Total Payments)"
                isInput={false}
                date={true}
                value={annualMipPayoffDate}
              />
              {OptionalCheckBox && (
                <>
                  <Typography className="common-sub-heading-calc">
                    Monthly
                  </Typography>
                  <InputComponent
                    label="Mortgage Payment"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.mortgagePayment}
                  />
                  <InputComponent
                    label="Property Tax"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.propertyTax}
                  />
                  <InputComponent
                    label="Home Insurance"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.homeInsurance}
                  />
                  <InputComponent
                    label="Annual MIP"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.annualMIP}
                  />
                  <InputComponent
                    label="Other Costs"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.otherCosts}
                  />
                  <InputComponent
                    label="Total Out-of-Pocket"
                    isInput={false}
                    flag="dollar"
                    value={resultDataMonthly?.totalOutOfPocket}
                  />
                  <Typography className="common-sub-heading-calc">
                    Total
                  </Typography>
                  <InputComponent
                    label="Mortgage Payment"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.mortgagePayment}
                  />
                  <InputComponent
                    label="Property Tax"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.propertyTax}
                  />
                  <InputComponent
                    label="Home Insurance"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.homeInsurance}
                  />
                  <InputComponent
                    label="Annual MIP"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.annualMIP}
                  />
                  <InputComponent
                    label="Other Costs"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.otherCosts}
                  />
                  <InputComponent
                    label="Total Out-of-Pocket"
                    isInput={false}
                    flag="dollar"
                    value={resultDataTotal?.totalOutOfPocket}
                  />
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                {
                  id: 0,
                  value: +resultDataTotal?.mortgagePayment,
                  label: "Principal",
                },
                {
                  id: 1,
                  value: +resultDataTotal?.otherCosts,
                  label: "Interest",
                },
                {
                  id: 2,
                  value: +resultDataTotal?.mortgagePayment,
                  label: "Property Taxes",
                },
                {
                  id: 3,
                  value: +resultDataTotal?.otherCosts,
                  label: "Annual MIP",
                },
                {
                  id: 4,
                  value: +resultDataTotal?.mortgagePayment,
                  label: "Other Cost",
                },
                {
                  id: 5,
                  value: +resultDataTotal?.otherCosts,
                  label: "Home Insurance",
                },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default FHALoanCalc;
