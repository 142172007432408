import React, { useEffect } from "react";
import "./RangeChart.scss";

const RangeChart = ({ value1, value2, totalRange }) => {
  // Calculate the percentage positions
  const pos1 = (value1 / totalRange) * 100;
  const pos2 = (value2 / totalRange) * 100;
  useEffect(() => {
    // Calculate the percentage positions
    const pos1 = (value1 / totalRange) * 100;
    const pos2 = (value2 / totalRange) * 100;

    // Set marker positions
    document.getElementById("marker1").style.left = `calc(${pos1}% - 1px)`;
    document.getElementById("marker2").style.left = `calc(${pos2}% - 1px)`;

    // Set label positions
    document.getElementById("label1").style.left = `calc(${pos1}% - 20px)`;
    document.getElementById("label1").textContent = `$${value1}`;

    document.getElementById("label2").style.left = `calc(${pos2}% - 20px)`;
    document.getElementById("label2").textContent = `$${value2}`;
  }, [value1, value2, totalRange]);
  return (
    <div className="chart-container">
      <div id="safe" className="segment">
        <span>Safe</span>
      </div>
      <div id="acceptable" className="segment">
        <span>acceptable</span>
      </div>
      <div id="aggressive" className="segment">
        <span>aggressive</span>
      </div>
      <div
        id="marker1"
        className="marker"
        style={{ left: `calc(${pos1}% - 1px)` }}
      ></div>
      <div
        id="marker2"
        className="marker"
        style={{ left: `calc(${pos2}% - 1px)` }}
      ></div>
      <div
        id="label1"
        className="label"
        style={{ left: `calc(${pos1}% - 20px)` }}
      >
        ${value1}
      </div>
      <div
        id="label2"
        className="label"
        style={{ left: `calc(${pos2}% - 20px)` }}
      >
        ${value2}
      </div>
    </div>
  );
};

export default RangeChart;
