import { useEffect } from "react";

export default function GaugeChartComponent({ value }) {
  useEffect(() => {
    function updateValue(newValue) {
      const arrow = document.getElementById("arrow");

      let angle = 0;
      if (newValue <= 13) {
        angle = 0;
      } else if (newValue > 13 && newValue < 16) {
        angle += ((newValue - 13.6) / 24) * 180;
      } else if (newValue >= 16 && newValue <= 18) {
        angle += ((newValue - 13.7) / 24) * 180;
      } else if (newValue > 18 && newValue < 20) {
        angle += ((newValue - 13.9) / 24) * 180;
      } else if (newValue >= 20 && newValue <= 28) {
        angle += ((newValue - 15.5) / 24) * 180;
      } else if (newValue > 28 && newValue <= 33) {
        angle += ((newValue - 16.3) / 24) * 180;
      } else if (newValue > 33 && newValue <= 37) {
        angle += ((newValue - 17.4) / 24) * 180;
      } else if (newValue > 37 && newValue <= 40) {
        angle += ((newValue - 18.5) / 24) * 180;
      } else if (newValue > 40 && newValue <= 42) {
        angle += ((newValue - 18) / 24) * 180;
      }

      arrow.setAttribute(
        "to",
        `${angle < 0 ? 0 : angle > 180 ? 180 : angle} 140 140`
      );
      arrow.beginElement();
    }
    updateValue(value);
  }, [value]);

  return (
    <div>
      <div style={{ paddingTop: "10px", textAlign: "center" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          width="300px"
          height="163px"
          viewBox="0 0 300 163"
        >
          <g
            transform="translate(18,18)"
            style={{
              fontFamily: "arial, helvetica, sans-serif",
              fontSize: "12px",
            }}
          >
            <defs>
              <marker
                id="arrowhead"
                markerWidth="10"
                markerHeight="7"
                refX="0"
                refY="3.5"
                orient="auto"
              >
                <polygon points="0 0, 10 3.5, 0 7"></polygon>
              </marker>
              <path
                id="curvetxt1"
                d="M-4 140 A140 140, 0, 0, 1, 284 140"
                style={{ fill: "none" }}
              ></path>
              <path
                id="curvetxt2"
                d="M33 43.6 A140 140, 0, 0, 1, 280 140"
                style={{ fill: "#none" }}
              ></path>
              <path
                id="curvetxt3"
                d="M95 3 A140 140, 0, 0, 1, 284 140"
                style={{ fill: "#none" }}
              ></path>
              <path
                id="curvetxt4"
                d="M235.4 33 A140 140, 0, 0, 1, 284 140"
                style={{ fill: "#none" }}
              ></path>
            </defs>
            <path
              d="M0 140 A140 140, 0, 0, 1, 6.9 96.7 L140 140 Z"
              fill="#bc2020"
            ></path>
            <path
              d="M6.9 96.7 A140 140, 0, 0, 1, 12.1 83.1 L140 140 Z"
              fill="#d38888"
            ></path>
            <path
              d="M12.1 83.1 A140 140, 0, 0, 1, 22.6 63.8 L140 140 Z"
              fill="#ffe400"
            ></path>
            <path
              d="M22.6 63.8 A140 140, 0, 0, 1, 96.7 6.9 L140 140 Z"
              fill="#008137"
            ></path>
            <path
              d="M96.7 6.9 A140 140, 0, 0, 1, 169.1 3.1 L140 140 Z"
              fill="#ffe400"
            ></path>
            <path
              d="M169.1 3.1 A140 140, 0, 0, 1, 233.7 36 L140 140 Z"
              fill="#d38888"
            ></path>
            <path
              d="M233.7 36 A140 140, 0, 0, 1, 273.1 96.7 L140 140 Z"
              fill="#bc2020"
            ></path>
            <path
              d="M273.1 96.7 A140 140, 0, 0, 1, 280 140 L140 140 Z"
              fill="#8a0101"
            ></path>
            <path d="M45 140 A90 90, 0, 0, 1, 230 140 Z" fill="#fff"></path>
            <circle cx="140" cy="140" r="5" fill="#666"></circle>
            <g
              style={{
                paintOrder: "stroke",
                stroke: "#fff",
                strokeWidth: "2px",
              }}
            >
              <text x="25" y="111" transform="rotate(-72, 25, 111)">
                16
              </text>
              <text x="30" y="96" transform="rotate(-66, 30, 96)">
                17
              </text>
              <text x="35" y="83" transform="rotate(-57, 35, 83)">
                18.5
              </text>
              <text x="97" y="29" transform="rotate(-18, 97, 29)">
                25
              </text>
              <text x="157" y="20" transform="rotate(12, 157, 20)">
                30
              </text>
              <text x="214" y="45" transform="rotate(42, 214, 45)">
                35
              </text>
              <text x="252" y="95" transform="rotate(72, 252, 95)">
                40
              </text>
            </g>
            <g style={{ fontSize: "14px" }}>
              <text>
                <textPath href="#curvetxt1">Underweight</textPath>
              </text>
              <text>
                <textPath href="#curvetxt2">Normal</textPath>
              </text>
              <text>
                <textPath href="#curvetxt3">Overweight</textPath>
              </text>
              <text>
                <textPath href="#curvetxt4">Obesity</textPath>
              </text>
            </g>
            <line
              x1="140"
              y1="140"
              x2="65"
              y2="140"
              stroke="#666"
              stroke-width="2"
              marker-end="url(#arrowhead)"
            >
              <animateTransform
                id="arrow"
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                from="0 140 140"
                to="0 140 140"
                dur="1s"
                fill="freeze"
                repeatCount="1"
              ></animateTransform>
            </line>
            <text
              x="67"
              y="120"
              style={{ fontSize: "30px", fontWeight: "bold", color: "#000" }}
            >
              BMI = {value > 42 ? 0 : value}
            </text>
          </g>
        </svg>
      </div>
    </div>
  );
}
