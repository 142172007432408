import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function LoanCalc() {
  const [loanAmountInput, setLoanAmountInput] = useState(100000);
  const [interestValue, setInterestValue] = useState(6);
  const [yearOfLoan, setYearOfLoan] = useState(5);
  const [monthlyEmi, setMonthlyEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const totalLoanMonths = yearOfLoan * 12;
    const interestPerMonth = interestValue / 100 / 12;
    const monthlyPayment =
      (loanAmountInput *
        interestPerMonth *
        (1 + interestPerMonth) ** totalLoanMonths) /
      ((1 + interestPerMonth) ** totalLoanMonths - 1);

    const totalInterestGenerated =
      monthlyPayment * totalLoanMonths - loanAmountInput;
    setMonthlyEmi(monthlyPayment.toFixed(0));
    setTotalAmount((+totalInterestGenerated + +loanAmountInput).toFixed(0));
    setTotalInterest(totalInterestGenerated.toFixed(0));
  }, [loanAmountInput, interestValue, yearOfLoan]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid className="result-common-div">
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Principal Amount"
                value={loanAmountInput}
                setState={setLoanAmountInput}
                min={1}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Rate of Interest (p.a.)"
                setState={setInterestValue}
                value={interestValue}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Time Period"
                setState={setYearOfLoan}
                value={yearOfLoan}
                min={0}
                max={199}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Monthly EMI"
                isInput={false}
                value={monthlyEmi}
              />
              <InputComponent
                label="Principal Amount"
                isInput={false}
                value={loanAmountInput}
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                value={totalInterest}
              />
              <InputComponent
                label="Total Amount"
                isInput={false}
                value={totalAmount}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +loanAmountInput, label: "Principal Amount" },
                { id: 1, value: +totalInterest, label: "Interest Amount" },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default LoanCalc;
