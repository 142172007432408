import { BarChart } from "@mui/x-charts";
import * as React from "react";

const BarChartComponent = (props) => {
  const { data } = props;

  return (
    <BarChart
      series={data}
      sx={{
        width: "100%",
        height: { xs: "50% !important", sm: "100% !important" },
      }}
      width={600}
      height={350}
    />
  );
};
export default BarChartComponent;
