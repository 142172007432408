import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import PieChartComponent from "../../Common/PieChart/PieChart";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";

function APRCalc() {
  const [loanAmount, setLoanAmount] = useState(100000);
  const [loanTerm, setLoanTerm] = useState(10);
  const [interestRate, setInterestRate] = useState(6);
  const [compound, setCompound] = useState("Monthly");
  const [payBack, setPayBack] = useState("Every Month");
  const [loanedFees, setLoanedFees] = useState(0);
  const [upfrontFees, setUpfrontFees] = useState(2500);
  const [houseValue, setHouseValue] = useState(350000);
  const [downPayment, setDownPayment] = useState(20);
  const [loanTerm2, setLoanTerm2] = useState(30);
  const [interestRate2, setInterestRate2] = useState(6.2);
  const [loanFees, setLoanFees] = useState(3500);
  const [points, setPoints] = useState(0.5);
  const [pmiInsurance, setPmiInsurance] = useState(0);
  const [result1, setResult1] = useState({
    realAPR: 0,
    amountFinanced: 0,
    upfrontOutOfPocketFees: 0,
    paymentEveryMonth: 0,
    totalOfPayments: 0,
    totalInterest: 0,
    allPaymentsAndFees: 0,
  });
  const [result2, setResult2] = useState({
    realAPR: 0,
    loanAmount: 0,
    downPayment: 0,
    monthlyPay: 0,
    totalOfPayments: 0,
    totalInterest: 0,
    allPaymentsAndFees: 0,
  });

  useEffect(() => {
    function calculateRealAPR(
      loanAmount,
      monthlyPayment,
      loanTermYears,
      paybackFreq,
      upfrontFees
    ) {
      const tolerance = 1e-6;
      const maxIterations = 100;
      let apr = 0.05;

      const totalPayments = loanTermYears * paybackFreq;

      for (let i = 0; i < maxIterations; i++) {
        let fValue = -loanAmount + upfrontFees; // Note: upfrontFees should be subtracted here if they reduce the net amount received
        let fDerivative = 0;
        const ratePerPeriod = apr / paybackFreq;

        for (let t = 1; t <= totalPayments; t++) {
          const discountFactor = 1 / Math.pow(1 + ratePerPeriod, t);
          fValue += monthlyPayment * discountFactor;
          fDerivative -=
            (t * monthlyPayment * discountFactor) /
            ((1 + ratePerPeriod) * paybackFreq);
        }

        const newApr = apr - fValue / fDerivative;

        if (Math.abs(newApr - apr) < tolerance) {
          return newApr * 100;
        }

        apr = newApr;
      }

      return apr * 100;
    }

    let compoundPeriods = {
      Annually: 1,
      "Semi-annually": 2,
      Quarterly: 4,
      Monthly: 12,
      "Semi-monthly": 24,
      Biweekly: 26,
      Weekly: 52,
      Daily: 365,
      Continuously: Infinity, // Continuous compounding
    };

    let paybackPeriods = {
      "Every Day": 365,
      "Every Week": 52,
      "2 Weeks": 26,
      "Half Month": 24,
      "Every Month": 12,
      "Every Quarter": 4,
      "6 Months": 2,
      "Every Year": 1,
    };

    const n = compoundPeriods[compound]; // Compounding frequency
    const m = paybackPeriods[payBack]; // Payback frequency

    // Calculate the amount financed (loan amount minus loaned fees)
    const amountFinanced = +loanAmount - +loanedFees;

    // Adjust interest rate for the compounding period
    const adjustedRate = Math.pow(1 + +interestRate / 100 / n, n / m) - 1;

    // Number of payments over the loan term
    const numberOfPayments = loanTerm * m;

    // Calculate the monthly payment using the amortization formula
    const paymentEveryMonth =
      (amountFinanced * adjustedRate) /
      (1 - Math.pow(1 + adjustedRate, -numberOfPayments));

    // Calculate total payments
    const totalOfPayments = paymentEveryMonth * numberOfPayments;

    // Total interest paid
    const totalInterest = totalOfPayments - amountFinanced;

    // Upfront out-of-pocket fees
    const upfrontOutOfPocketFees = +upfrontFees;

    // Calculate the Real APR using the iterative method
    const realAPR = calculateRealAPR(
      +loanAmount,
      +paymentEveryMonth,
      +loanTerm,
      m,
      +upfrontFees
    );

    // Update state with results
    setResult1({
      realAPR: realAPR.toFixed(3), // Display Real APR with higher precision
      amountFinanced: amountFinanced.toFixed(2),
      upfrontOutOfPocketFees: upfrontOutOfPocketFees.toFixed(2),
      paymentEveryMonth: paymentEveryMonth.toFixed(2),
      totalOfPayments: totalOfPayments.toFixed(2),
      totalInterest: totalInterest.toFixed(2),
      allPaymentsAndFees: (totalOfPayments + upfrontOutOfPocketFees).toFixed(2),
    });
  }, [
    compound,
    interestRate,
    loanAmount,
    loanTerm,
    loanedFees,
    payBack,
    upfrontFees,
  ]);

  useEffect(() => {
    const calculateRealAPRMortgage = () => {
      const dp = +houseValue * (+downPayment/100)
      const loanAmount = +houseValue - dp; // Calculate loan amount

      const monthlyRate = interestRate2 / 100 / 12; // Convert annual interest rate to monthly rate
      const numberOfPayments = loanTerm2 * 12; // Total number of monthly payments

      // Calculate monthly payment using the loan amortization formula
      const monthlyPay =
        (loanAmount * monthlyRate) /
        (1 - Math.pow(1 + monthlyRate, -numberOfPayments));

      // Calculate total of all payments (monthly payment * number of payments)
      const totalOfPayments = monthlyPay * numberOfPayments;

      // Calculate total interest paid
      const totalInterest = totalOfPayments - loanAmount;

      // Calculate total points (1 point = 1% of loan amount)
      const pointsCost = (points / 100) * loanAmount;

      // Add loan fees, points, and PMI insurance to calculate total upfront costs
      const totalUpfrontCosts = loanFees + pointsCost + pmiInsurance;

      // Adjust loan amount for real APR calculation (loanAmount - totalUpfrontCosts)
      const adjustedLoanAmount = loanAmount - totalUpfrontCosts;

      // Newton-Raphson method to approximate the APR
      const tolerance = 1e-6; // Accuracy tolerance
      const maxIterations = 100; // Maximum iterations for convergence
      let apr = interestRate2 / 100; // Initial guess for APR (starting with interest rate)

      // Function to calculate fValue (Net Present Value of cash flows)
      const calculateFValue = (apr) => {
        let fValue = adjustedLoanAmount;
        for (let t = 1; t <= numberOfPayments; t++) {
          const discountFactor = 1 / Math.pow(1 + apr / 12, t);
          fValue -= monthlyPay * discountFactor;
        }
        return fValue;
      };

      // Function to calculate the derivative of fValue with respect to APR
      const calculateDerivative = (apr) => {
        let derivative = 0;
        for (let t = 1; t <= numberOfPayments; t++) {
          const discountFactor = 1 / Math.pow(1 + apr / 12, t);
          derivative += (t * monthlyPay * discountFactor) / (1 + apr / 12);
        }
        return derivative;
      };

      // Newton-Raphson method to find the real APR
      for (let i = 0; i < maxIterations; i++) {
        const fValue = calculateFValue(apr);
        const derivative = calculateDerivative(apr);
        const newApr = apr - fValue / derivative;

        // If the change in APR is smaller than the tolerance, we're done
        if (Math.abs(newApr - apr) < tolerance) {
          apr = newApr;
          break;
        }
        apr = newApr;
      }

      // Calculate all payments and fees (total of payments + total upfront costs)
      const allPaymentsAndFees = +totalOfPayments + +loanFees;

      // Update the result state with the calculated values
      setResult2({
        realAPR: (apr * 100).toFixed(3), // Convert to percentage
        loanAmount,
        downPayment: dp,
        monthlyPay,
        totalOfPayments,
        totalInterest,
        allPaymentsAndFees,
      });
    };

    // Trigger the calculation
    calculateRealAPRMortgage();
  }, [
    houseValue,
    downPayment,
    loanTerm2,
    interestRate2,
    loanFees,
    points,
    pmiInsurance,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              1. General APR Calculator
            </Typography>
            <InputComponent
              isInput={true}
              flag="dollar"
              label="Loan Amount"
              value={loanAmount}
              min={0}
              setState={setLoanAmount}
              max={10000000000}
            />
            <InputComponent
              isInput={true}
              flag="year"
              label="Loan Term"
              value={loanTerm}
              min={0}
              setState={setLoanTerm}
              max={50}
            />
            <InputComponent
              isInput={true}
              flag="percentage"
              label="Interest Rate"
              value={interestRate}
              min={0}
              setState={setInterestRate}
              max={999}
            />
            <SelectBoxComponent
              value={compound}
              setValue={setCompound}
              data={[
                "Annually",
                "Semi-annually",
                "Quarterly",
                "Monthly",
                "Semi-monthly",
                "Biweekly",
                "Weekly",
                "Daily",
                "Continuously",
              ]}
              label="Compound"
            />{" "}
            <SelectBoxComponent
              value={payBack}
              setValue={setPayBack}
              data={[
                "Every Day",
                "Every Week",
                "2 Weeks",
                "Half Month",
                "Every Month",
                "Every Quarter",
                "6 Months",
                "Every Year",
              ]}
              label="Pay Back"
            />
            <InputComponent
              isInput={true}
              flag="dollar"
              label="Loaned Fees"
              value={loanedFees}
              min={0}
              setState={setLoanedFees}
              max={10000000000}
            />
            <InputComponent
              isInput={true}
              flag="dollar"
              label="Upfront Fees"
              value={upfrontFees}
              min={0}
              setState={setUpfrontFees}
              max={10000000000}
            />
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Real APR"
                isInput={false}
                value={result1?.realAPR}
                flag="percentage"
              />
              <InputComponent
                label="Amount Financed"
                isInput={false}
                value={result1?.amountFinanced}
                flag="dollar"
              />
              <InputComponent
                label="Upfront Out-of-Pocket Fees"
                isInput={false}
                value={result1?.upfrontOutOfPocketFees}
                flag="dollar"
              />
              <InputComponent
                label="Payment Every Month"
                isInput={false}
                value={result1?.paymentEveryMonth}
                flag="dollar"
              />
              <InputComponent
                label={`Total of ${loanTerm * 12} Payments:`}
                isInput={false}
                value={result1?.totalOfPayments}
                flag="dollar"
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                value={result1?.totalInterest}
                flag="dollar"
              />
              <InputComponent
                label="All Payments and Fees"
                isInput={false}
                value={result1?.allPaymentsAndFees}
                flag="dollar"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +result1?.amountFinanced, label: "Principal" },
                { id: 1, value: +result1?.totalInterest, label: "Interest" },
                {
                  id: 2,
                  value: +result1?.upfrontOutOfPocketFees,
                  label: "Fees",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={7} className="calculation-part" my={2}>
            <Typography className="common-sub-heading-calc">
              2. Mortgage APR Calculator
            </Typography>
            <InputComponent
              isInput={true}
              flag="dollar"
              label="House Value"
              value={houseValue}
              min={0}
              setState={setHouseValue}
              max={10000000000}
            />
            <InputComponent
              isInput={true}
              flag="percentage"
              label="Down Payment"
              value={downPayment}
              min={0}
              setState={setDownPayment}
              max={999}
            />
            <InputComponent
              isInput={true}
              flag="year"
              label="Loan Term"
              value={loanTerm2}
              min={0}
              setState={setLoanTerm2}
              max={50}
            />
            <InputComponent
              isInput={true}
              flag="percentage"
              label="Interest Rate"
              value={interestRate2}
              min={0}
              setState={setInterestRate2}
              max={999}
            />
            <InputComponent
              isInput={true}
              flag="dollar"
              label="Loan Fees"
              value={loanFees}
              min={0}
              setState={setLoanFees}
              max={10000000000}
            />
            <InputComponent
              isInput={true}
              flag="dollar"
              label="Points"
              value={points}
              min={0}
              setState={setPoints}
              max={10000000000}
            />
            <InputComponent
              isInput={true}
              flag="dollar"
              label="PMI Insurance"
              value={pmiInsurance}
              min={0}
              setState={setPmiInsurance}
              max={10000000000}
            />
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Real APR"
                isInput={false}
                value={result2?.realAPR}
                flag="percentage"
              />
              <InputComponent
                label="Loan Amount"
                isInput={false}
                value={result2?.loanAmount}
                flag="dollar"
              />
              <InputComponent
                label="Down Payment"
                isInput={false}
                value={result2?.downPayment}
                flag="dollar"
              />
              <InputComponent
                label="Monthly Pay"
                isInput={false}
                value={result2?.monthlyPay}
                flag="dollar"
              />
              <InputComponent
                label={`Total of ${loanTerm2 * 12} Payments:`}
                isInput={false}
                value={result2?.totalOfPayments}
                flag="dollar"
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                value={result2?.totalInterest}
                flag="dollar"
              />
              <InputComponent
                label="All Payments and Fees"
                isInput={false}
                value={result2?.allPaymentsAndFees}
                flag="dollar"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +result2?.loanAmount, label: "Principal" },
                { id: 1, value: +result2?.totalInterest, label: "Interest" },
                { id: 2, value: +loanFees, label: "Fees" },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default APRCalc;
