import React from "react";
import "./404.scss";
import { Grid, Typography } from "@mui/material";
import notFound from "../../../Assets/Icon/404.png";
import { Home } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";
function Error() {
  const navigate = useNavigate();
  const goToHomeHandler = () => {
    navigate("/");
  };
  return (
    <>
      <Helmet>
        <title>{SEO.ERR_PAGE_TITLE}</title>
        <meta name="description" content={SEO.ERR_PAGE_DESCRIPTION} />
        <meta name="keywords" content={SEO.ERR_PAGE_KEYWORDS} />

        <meta property="og:title" content={SEO.ERR_PAGE_TITLE} />
        <meta property="og:description" content={SEO.ERR_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.CALCULATOR_TIMES_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.CALCULATOR_TIMES} />
        <meta name="robots" content="noindex" />
<meta name="googlebot" content="noindex" />
<meta name="googlebot-news" content="noindex" />
        <meta name="twitter:title" content={SEO.ERR_PAGE_TITLE} />
        <meta name="twitter:description" content={SEO.ERR_PAGE_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid className="err-page-container">
        <img src={notFound} alt="page-not-found" />
        <Typography variant="h4">Oops, page not found!</Typography>
        <button onClick={() => goToHomeHandler()}>
          Go to Home <Home />
        </button>
      </Grid>
    </>
  );
}

export default Error;
