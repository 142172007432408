import { Box, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxModified from "../CalorieCalc/SelectBoxModified";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import {
  calculateBMR,
  generateActivityNeeds,
  generateDiet1,
  generateDiet2,
  BMR_FORMULAS,
  ENERGY_UNITS,
  GENDERS,
  ACTIVITY,
  // UNIT_NAMES,
  unitConverter,
} from "../../../Utils/calorieHelper";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  returnHelmetData
} from "../../../Utils/CommonFunction";
import UnitConversion from "../../Common/UnitConversion/UnitConversion";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

const ACTIVITY_NAMES = ACTIVITY.map((item) => ({
  name: item.name,
  value: item.value,
}));

export default function WeightLossCalc() {
  const [weight, setWeight] = useState({ unit: "kg", value: 70 });
  const [height, setHeight] = useState({ unit: "cm", value: 170 });
  const [age, setAge] = useState(22);
  const [gender, setGender] = useState({ name: "Male", value: "male" });
  const [unitConvert, setUnitConvert] = useState(false);

  const [fat, setFat] = useState(0.2);
  const [BMRFormula, setBMRFormula] = useState({
    name: "Mifflin-St Jeor",
    value: "MJ",
  });
  const [BMR, setBMR] = useState(1658);
  const [energyUnit, setEnergyUnit] = useState({
    name: "Calories",
    value: "cal",
  });
  const [activity, setActivity] = useState({
    name: "Moderate: exercise 4-5 times/week",
    value: "moderate",
  });

  const [showSettings, setShowSettings] = useState(false);

  const [mainTableView, setMainTableView] = useState(false);
  const [mainTableData, setMainTableData] = useState([]);
  const [diet1TableData, setDiet1TableData] = useState([]);
  const [diet2TableData, setDiet2TableData] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [unit1, setUnit1] = useState({
    value: 1,
    unit: "Calorie [Nutritional,  kcal]",
  });
  const [unit2, setUnit2] = useState({ value: 4.1868, unit: "joules [J]" });

  

  useEffect(() => {
    let weightInKG =
      weight.unit === "kg" ? weight.value : weight.value / 2.20462;
    let heigthInCM =
      height.unit === "cm"
        ? height.value
        : height.value.ft * 30.48 + height.value.in * 2.54;

    setBMR(() =>
      calculateBMR(
        weightInKG,
        heigthInCM,
        age,
        fat,
        BMRFormula.value,
        gender.value,
        energyUnit.value
      )
    );
    setMainTableData(() => generateActivityNeeds(BMR, activity.value));

    if (mainTableData[0]) {
      setDiet1TableData(() => generateDiet1(mainTableData[0]?.energy));
      setDiet2TableData(() => generateDiet2(mainTableData[0]?.energy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weight, height, gender, age, fat, BMRFormula, energyUnit, BMR, activity]);

  useEffect(() => {
    setUnit2((prev) => ({ ...prev, value: unitConverter(unit1, unit2) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit1, unit2.unit]);

  const weightUnitSetter = (value) =>
    setWeight((prev) => ({ ...prev, unit: value }));
  const weightValueSetter = (event) => {
    const value = parseFloat(event.target.value);
    setWeight((prev) => ({ ...prev, value: Math.min(value, 500) }));
  };

  const heightUnitSetter = (value) => {
    if (value === "ft") {
      setHeight({ unit: value, value: { ft: 5, in: 8 } });
    } else if (value === "cm") {
      setHeight({ unit: value, value: 170 });
    }
  };

  const heightCMValueSetter = (value) =>
    setHeight((prev) => ({ ...prev, value: Math.min(value, 260) }));
  const heightFTValueSetter = (value) =>
    setHeight((prev) => ({
      ...prev,
      value: { ...prev.value, ft: Math.min(value, 8) },
    }));
  const heightINValueSetter = (value) =>
    setHeight((prev) => ({
      ...prev,
      value: { ...prev.value, in: Math.min(value, 12) },
    }));

  const fatSetter = (value) => setFat(() => Math.min(value / 100, 1));

  // const unit1ValueSetter = (value) =>
  //   setUnit1((prev) => ({ ...prev, value: value }));
  // const unit1Setter = (unit) => setUnit1((prev) => ({ ...prev, unit: unit }));
  // const unit2Setter = (unit) => setUnit2((prev) => ({ ...prev, unit: unit }));

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid item className="calculation-part">
              <Typography className="common-sub-heading-desc">
                Use the weight loss calculator to estimate the number of daily
                calories your body needs to maintain your current weight.
              </Typography>

              <Grid>
                <InputComponent
                  label="Age"
                  isInput={true}
                  min={1}
                  max={199}
                  value={age}
                  setState={setAge}
                />
                <RadioButtonComponent
                  label={"Gender"}
                  data={GENDERS}
                  defaultValue={"male"}
                  valueSetter={setGender}
                  row={false}
                />
                {height.unit === "cm" && (
                  <InputComponent
                    label="Height (cm)"
                    isInput={true}
                    min={1}
                    max={200}
                    value={height.value}
                    setState={heightCMValueSetter}
                  />
                )}

                {height.unit === "ft" && (
                  <>
                    <InputComponent
                      label="Height (ft)"
                      isInput={true}
                      min={1}
                      max={10}
                      value={height.value.ft}
                      setState={heightFTValueSetter}
                    />

                    <InputComponent
                      label="Height (in)"
                      isInput={true}
                      max={20}
                      value={height.value.in}
                      setState={heightINValueSetter}
                    />
                  </>
                )}
                <SelectBoxComponent
                  label="Height Unit"
                  value={height.unit}
                  setValue={heightUnitSetter}
                  data={["cm", "ft"]}
                  placeholder="Select height unit"
                />

                <br></br>

                <Grid>
                  <Grid className="concrete-calc-input pace">
                    <Typography className="input-label">Weight</Typography>
                    <Grid sx={{ display: "flex" }}>
                      <input
                        type="number"
                        value={weight.value}
                        onChange={weightValueSetter}
                        className="input-field"
                      />

                      <Grid className="select-box-wrap">
                        <SelectBoxComponent
                          value={weight.unit}
                          setValue={weightUnitSetter}
                          data={["kg", "lb"]}
                          placeholder="Select weight unit"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Typography
                variant="h6"
                sx={{ mx: 1, fontWeight: 500, fontSize: "18px" }}
              >
                Activity
              </Typography>

              <SelectBoxModified
                value={activity.value}
                valueSetter={setActivity}
                data={ACTIVITY_NAMES}
                placeholder="Select your activity"
              />

              <Grid
                id="settings"
                sx={{ ml: 1.5 }}
                className="data-table-container"
              >
                <Grid item>
                  <Grid className="table-toggle-div">
                    <Typography
                      variant="h6"
                      sx={{ color: "black", fontWeight: 600 }}
                    >
                      Settings
                    </Typography>
                    <Tooltip
                      title={
                        showSettings
                          ? "click to close setting"
                          : "click to open setting"
                      }
                      placement="top"
                      arrow
                    >
                      <span>
                        <img
                          src={plusIcon}
                          alt="plusIcon"
                          onClick={() => setShowSettings((prev) => !prev)}
                          style={{
                            transform: showSettings
                              ? "rotate(45deg)"
                              : "rotate(0deg)",
                          }}
                          className="add-icon-table"
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                  {showSettings && (
                    <>
                      <Grid item>
                        <RadioButtonComponent
                          label={"BMR estimation formula"}
                          data={BMR_FORMULAS}
                          defaultValue={"MJ"}
                          valueSetter={setBMRFormula}
                          row={false}
                        />

                        {BMRFormula.value === "KM" && (
                          <InputComponent
                            label="Body Fat (%)"
                            isInput={true}
                            value={fat * 100}
                            setState={fatSetter}
                          />
                        )}

                        <RadioButtonComponent
                          label={"Results unit:"}
                          data={ENERGY_UNITS}
                          defaultValue={"cal"}
                          valueSetter={setEnergyUnit}
                          row={false}
                        />
                      </Grid>
                    </>
                  )}
                  {!unitConvert ? (
                    <Grid sx={{ textAlign: "center" }}>
                      <Button
                        onClick={(e) => setUnitConvert(!unitConvert)}
                        variant="contained"
                      >
                        Unit Conversion
                      </Button>
                    </Grid>
                  ) : (
                    <UnitConversion setUnitConvert={setUnitConvert} />
                  )}
                </Grid>
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>

              <Grid className="result-common-div">
                <Typography fontSize={20} textAlign={"center"}>
                  To achieve your weight loss goals, aim to consume
                  <strong> {isNaN(BMR) ? 0 : BMR.toFixed(0)} </strong>
                  {energyUnit.value === "cal" ? "Calories/day" : "kJ/day"}.
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Grid className="data-table-container">
                <Grid className="table-toggle-div">
                  <Typography
                    variant="h6"
                    className="common-sub-heading-calc"
                    sx={{ textAlign: "left" }}
                  >
                    Daily calorie needs based on activity level
                  </Typography>
                  <Tooltip
                    title={
                      mainTableView
                        ? "click to close table"
                        : "click to open table"
                    }
                    placement="top"
                    arrow
                  >
                    <span>
                      <img
                        src={plusIcon}
                        alt="plusIcon"
                        onClick={() => setMainTableView((prev) => !prev)}
                        style={{
                          transform: mainTableView
                            ? "rotate(45deg)"
                            : "rotate(0deg)",
                        }}
                        className="add-icon-table"
                      />
                    </span>
                  </Tooltip>
                </Grid>
                {mainTableView && (
                  <Grid sx={{ py: 2 }}>
                    <Grid>
                      <TableComponent
                        rows={mainTableData}
                        headerData={["Weight Goal", `${energyUnit.name}/day`]}
                        isMinWidth={300}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid item className="calculation-part">
              <Grid item>
                <Typography className="common-sub-heading-calc">
                  Zigzag Calorie Cycling:
                </Typography>
                <Typography sx={{ mb: 4, opacity: 0.7, px: 1 }}>
                  Your body will probably adjust to the new, reduced energy
                  environment when you follow a low-calorie diet, which could
                  cause your progress to plateau. A "zigzag diet," or zigzag
                  calorie cycling, is a technique for consuming calories that
                  may be able to assist you break through this plateau and
                  resume your goal-achieving. Two seven-day Zigzag calorie
                  cycling programmes are provided below.
                </Typography>
              </Grid>

              <Grid item sx={{ mb: 4 }}>
                <Typography className="common-sub-heading-calc">
                  Zigzag diet schedule 1
                </Typography>
                <Grid className="data-table-container">
                  <Grid>
                    <Grid>
                      <TableComponent
                        rows={diet1TableData}
                        headerData={[
                          "Day",
                          "Mild weight loss",
                          "Weight loss",
                          "Extreme weight loss",
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ mb: 4 }}>
                <Typography className="common-sub-heading-calc">
                  Zigzag diet schedule 2
                </Typography>
                <Grid className="data-table-container">
                  <Grid>
                    <Grid>
                      <TableComponent
                        rows={diet2TableData}
                        headerData={[
                          "Day",
                          "Mild weight loss",
                          "Weight loss",
                          "Extreme weight loss",
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />
              <Grid item>
                <Typography className="common-sub-heading-calc">
                  What Is Next?
                </Typography>
                <Typography sx={{ mb: 4, px: 1, opacity: 0.7 }}>
                  Choose a method to track your calories and progress. You can
                  either do this on paper or electronically using a mobile app.
                  Track your progress over time and make adjustments as needed.
                  <br></br>
                  As you lose weight, your basal metabolic rate (BMR) will
                  change accordingly. As such, it will likely be necessary to
                  make adjustments to your calorie intake plan to continue
                  making progress.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
