import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  calculateHousePropertyIncome,
  calculateHRADeduction,
  calculateTotalDeduction,
  returnHelmetData,
} from "../../../Utils/CommonFunction";
import "./IncomeTaxCalc.scss";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function IncomeTaxCalc() {
  const [assessmentYear, setAssessmentYear] = useState("2024-25");
  const [ageCategory, setAgeCategory] = useState("below 60");
  const [grossSalaryIncome, setGrossSalaryIncome] = useState(0);
  const [annualIncomeFromOtherSource, setAnnualIncomeFromOtherSource] =
    useState(0);
  const [annualIncomeFromInterest, setAnnualIncomeFromInterest] = useState(0);
  const [annualRentalIncome, setAnnualRentalIncome] = useState(0);
  const [
    annualInterestPaidOnHomeLoanSelf,
    setAnnualInterestPaidOnHomeLoanSelf,
  ] = useState(0);
  const [
    annualInterestPaidOnHomeLoanLetOut,
    setAnnualInterestPaidOnHomeLoanLetOut,
  ] = useState(0);
  const [basicDeduction80C, setBasicDeduction80C] = useState(0);
  const [contributionToNPS80CCD, setContributionToNPS80CCD] = useState(0);
  const [medicalInsPremium80D, setMedicalInsPremium80D] = useState(0);
  const [donationToCharity80G, setDonationToCharity80G] = useState(0);
  const [interestOnEduCationLoan80E, setInterestOnEduCationLoan80E] =
    useState(0);
  const [
    interestOnDepositInSavingAccount,
    setInterestOnDepositInSavingAccount,
  ] = useState(0);
  const [isMetroCity, setIsMetroCity] = useState("Yes");
  const [basicSalaryReceivedPerAnnum, setBasicSalaryReceivedPerAnnum] =
    useState(0);
  const [dearnessReceivedPerAnnum, setDearnessReceivedPerAnnum] = useState(0);
  const [HraReceivedPerAnnum, setHraReceivedPerAnnum] = useState(0);
  const [totalRentPaidPerAnnum, setTotalRentPaidPerAnnum] = useState(0);
  const [oldRegime, setOldRegime] = useState(0);
  const [newRegime, setNewRegime] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  useEffect(() => {
    const HRADeducatableAmount = calculateHRADeduction(
      +basicSalaryReceivedPerAnnum,
      +dearnessReceivedPerAnnum,
      +HraReceivedPerAnnum,
      +totalRentPaidPerAnnum,
      isMetroCity
    );
    const StandardDeductionAmount = calculateTotalDeduction(
      basicDeduction80C,
      +contributionToNPS80CCD,
      +medicalInsPremium80D,
      +donationToCharity80G,
      +interestOnEduCationLoan80E,
      +interestOnDepositInSavingAccount
    );

    const totalAnnualIncomeWithoutDeduction =
      +grossSalaryIncome +
      +annualIncomeFromOtherSource +
      +annualIncomeFromInterest;

    const finalTaxPayableAmountForOldRegima =
      +totalAnnualIncomeWithoutDeduction -
      +StandardDeductionAmount -
      +HRADeducatableAmount +
      calculateHousePropertyIncome(
        +annualRentalIncome,
        +annualInterestPaidOnHomeLoanSelf,
        +annualInterestPaidOnHomeLoanLetOut
      ) -
      50000;
    const finalTaxPayableAmountForNewRegima =
      +totalAnnualIncomeWithoutDeduction - 50000;
    setTotalIncome(
      (
        +grossSalaryIncome +
        +annualIncomeFromOtherSource +
        +annualIncomeFromInterest +
        +annualRentalIncome +
        +annualInterestPaidOnHomeLoanLetOut +
        +annualInterestPaidOnHomeLoanSelf
      ).toFixed(2)
    );
    setTotalDeduction(
      (StandardDeductionAmount + HRADeducatableAmount + 50000).toFixed(2)
    );
    setOldRegime(
      calculateOldReginaTax(
        finalTaxPayableAmountForOldRegima,
        ageCategory
      ).toFixed(2)
    );
    setNewRegime(
      calculateNewReginaTax(
        finalTaxPayableAmountForNewRegima,
        ageCategory
      ).toFixed(2)
    );
  }, [
    dearnessReceivedPerAnnum,
    HraReceivedPerAnnum,
    totalRentPaidPerAnnum,
    isMetroCity,
    basicSalaryReceivedPerAnnum,
    interestOnDepositInSavingAccount,
    basicDeduction80C,
    contributionToNPS80CCD,
    medicalInsPremium80D,
    donationToCharity80G,
    interestOnEduCationLoan80E,
    annualInterestPaidOnHomeLoanLetOut,
    annualInterestPaidOnHomeLoanSelf,
    annualIncomeFromInterest,
    annualRentalIncome,
    assessmentYear,
    ageCategory,
    grossSalaryIncome,
    annualIncomeFromOtherSource,
  ]);
  const calculateOldReginaTax = (finalAmount, ageCategory) => {
    let finalTax = 0;

    if (ageCategory === "below 60") {
      if (finalAmount <= 250000) {
        finalTax = 0;
      } else if (finalAmount <= 300000) {
        finalTax = 0 + (finalAmount - 250000) * 0.05;
      } else if (finalAmount <= 500000) {
        finalTax = 2500 + (finalAmount - 300000) * 0.05;
      } else if (finalAmount <= 1000000) {
        finalTax = 12500 + (finalAmount - 500000) * 0.2;
      } else {
        finalTax = 112500 + (finalAmount - 1000000) * 0.3;
      }
      return finalTax > 0 ? finalTax + finalTax * 0.04 : 0;
    } else if (ageCategory === "60 or above 60") {
      if (finalAmount <= 250000) {
        finalTax = 0;
      } else if (finalAmount <= 500000) {
        finalTax = (finalAmount - 300000) * 0.05;
      } else if (finalAmount <= 1000000) {
        finalTax = 10000 + (finalAmount - 500000) * 0.2;
      } else {
        finalTax = 110000 + (finalAmount - 1000000) * 0.3;
      }
      return finalTax > 0 ? finalTax + finalTax * 0.04 : 0;
    } else if (ageCategory === "80 or above 80") {
      if (finalAmount <= 250000) {
        finalTax = 0;
      } else if (finalAmount <= 500000) {
        finalTax = 0;
      } else if (finalAmount <= 1000000) {
        finalTax = 0 + (finalAmount - 500000) * 0.2;
      } else {
        finalTax = 100000 + (finalAmount - 1000000) * 0.3;
      }
      return finalTax > 0 ? finalTax + finalTax * 0.04 : 0;
    }
  };

  const calculateNewReginaTax = (finalAmount) => {
    let finalTax = 0;
    if (finalAmount <= 300000) {
      finalTax = 0;
    } else if (finalAmount <= 600000) {
      finalTax = 0 + (finalAmount - 300000) * 0.05;
    } else if (finalAmount <= 900000) {
      finalTax = 15000 + (finalAmount - 600000) * 0.1;
    } else if (finalAmount <= 1200000) {
      finalTax = 45000 + (finalAmount - 900000) * 0.15;
    } else if (finalAmount <= 1500000) {
      finalTax = 90000 + (finalAmount - 1200000) * 0.2;
    } else {
      finalTax = 150000 + (finalAmount - 1500000) * 0.3;
    }
    return finalTax > 0 ? finalTax + finalTax * 0.04 : 0;
  };

  useEffect(() => {
    if (ageCategory === "below 60") {
      if (medicalInsPremium80D > 25000) {
        setMedicalInsPremium80D(25000);
      }
      if (interestOnDepositInSavingAccount > 10000) {
        setInterestOnDepositInSavingAccount(10000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ageCategory]);
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} className="calculation-part">
            <Grid>
              <SelectBoxComponent
                value={assessmentYear}
                setValue={setAssessmentYear}
                data={["2022-23", "2023-24", "2024-25"]}
                placeholder="Select Assessment-Year"
              />
              <SelectBoxComponent
                value={ageCategory}
                setValue={setAgeCategory}
                data={["below 60", "60 or above 60", "80 or above 80"]}
                placeholder="Age Category"
              />
              <Grid sx={{ p: 1 }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Income
                  </AccordionSummary>
                  <AccordionDetails>
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Gross salary income"
                      value={grossSalaryIncome}
                      setState={setGrossSalaryIncome}
                      max={10000000000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Annual income from other sources"
                      value={annualIncomeFromOtherSource}
                      setState={setAnnualIncomeFromOtherSource}
                      max={10000000000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Annual income from interest"
                      value={annualIncomeFromInterest}
                      setState={setAnnualIncomeFromInterest}
                      max={10000000000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Annual income from let-out house property (rental income)"
                      value={annualRentalIncome}
                      setState={setAnnualRentalIncome}
                      max={10000000000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Annual interest paid on home loan (self-occupied)"
                      value={annualInterestPaidOnHomeLoanSelf}
                      setState={setAnnualInterestPaidOnHomeLoanSelf}
                      max={10000000000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Annual interest paid on home loan (let-out)"
                      value={annualInterestPaidOnHomeLoanLetOut}
                      setState={setAnnualInterestPaidOnHomeLoanLetOut}
                      max={10000000000}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Deductions
                  </AccordionSummary>
                  <AccordionDetails>
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Basic deductions u/s 80C"
                      value={basicDeduction80C}
                      setState={setBasicDeduction80C}
                      max={150000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Contribution to NPS u/s 80CCD(1B)"
                      value={contributionToNPS80CCD}
                      setState={setContributionToNPS80CCD}
                      max={50000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Medical Insurance Premium u/s 80D"
                      value={medicalInsPremium80D}
                      setState={setMedicalInsPremium80D}
                      max={ageCategory === "below 60" ? 25000 : 50000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Donation to charity u/s 80G"
                      value={donationToCharity80G}
                      setState={setDonationToCharity80G}
                      max={10000000000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Interest on Educational Loan u/s 80E"
                      value={interestOnEduCationLoan80E}
                      setState={setInterestOnEduCationLoan80E}
                      max={10000000000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Interest on deposits in saving account u/s 80TTA/TTB"
                      value={interestOnDepositInSavingAccount}
                      setState={setInterestOnDepositInSavingAccount}
                      max={ageCategory === "below 60" ? 10000 : 50000}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    HRA Exemption
                  </AccordionSummary>
                  <AccordionDetails>
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Basic salary received per annum"
                      value={basicSalaryReceivedPerAnnum}
                      setState={setBasicSalaryReceivedPerAnnum}
                      max={10000000000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Dearness allowance (DA) received per annum"
                      value={dearnessReceivedPerAnnum}
                      setState={setDearnessReceivedPerAnnum}
                      max={10000000000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="HRA received per annum"
                      value={HraReceivedPerAnnum}
                      setState={setHraReceivedPerAnnum}
                      max={10000000000}
                    />
                    <InputComponent
                      isInput={true}
                      flag="rupee"
                      label="Total rent paid per annum"
                      value={totalRentPaidPerAnnum}
                      setState={setTotalRentPaidPerAnnum}
                      max={10000000000}
                    />
                    <SelectBoxComponent
                      value={isMetroCity}
                      label="Do you live in Delhi, Mumbai, Chennai or Kolkata?"
                      setValue={setIsMetroCity}
                      data={["Yes", "No"]}
                      placeholder="Do you live in a metro city?"
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Total Income"
                isInput={false}
                value={totalIncome}
              />
              <InputComponent
                label="Total Deduction (Standard + HRA)"
                isInput={false}
                value={totalDeduction}
              />
              <InputComponent
                label="Total tax (Old regime)"
                isInput={false}
                value={oldRegime}
              />
              <InputComponent
                label="Total tax (New regime)"
                isInput={false}
                value={newRegime}
              />
              <InputComponent
                label="Difference between old regime and new regime"
                isInput={false}
                value={+oldRegime - +newRegime}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className="piechart-div"
            sx={{ display: oldRegime > 0 || newRegime > 0 ? "flex" : "none" }}
          >
            <PieChartComponent
              data={[
                { id: 0, value: +oldRegime, label: "Total tax (Old regime)" },
                {
                  id: 1,
                  value: +newRegime,
                  label: "Total tax (New regime)",
                },
              ]}
              is
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default IncomeTaxCalc;
