import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputSlider from "../../Common/Slider/Slider";
import InputComponent from "../../Common/InputComponent/InputComponent";
import PieChart from "../../Common/PieChart/PieChart";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

export default function InflationCalc() {
  const [currentCost, setCurrentCost] = useState(100);
  const [inflationRate, setInflationRate] = useState(3.27);
  const [timePeriod, setTimePeriod] = useState(5);
  const [costIncrease, setCostIncrease] = useState(0);
  const [futureCost, setFutureCost] = useState(0);


  function calculateInflation(currentCost, inflationRate, timePeriod) {
    let futureCost = +(
      +currentCost * Math.pow(1 + +inflationRate / 100, +timePeriod)
    ).toFixed(0);
    setFutureCost(+futureCost.toFixed(2));

    let costIncrease = +futureCost - +currentCost;
    setCostIncrease(+costIncrease.toFixed(2));
  }

  useEffect(() => {
    calculateInflation(currentCost, inflationRate, timePeriod);
  }, [currentCost, inflationRate, timePeriod]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid
            sx={{ marginBottom: 2 }}
            item
            xs={12}
            md={7}
            className="calculation-part"
          >
            <Typography className="common-sub-heading-desc">
              Inflation is an indicator of the rate of price change when
              compared to a specific category of goods over a particular period.
              Inflation signifies the elevation in the prices of goods/services
              that denotes a fall in purchasing capacity.
            </Typography>
            <InputSlider
              label="Current Value ($)"
              value={currentCost}
              valueSetter={setCurrentCost}
              steps={3000}
              min={1}
              max={500000}
              sx={{ mb: 5 }}
            />

            <InputSlider
              label="Rate of Inflation (% per annum)"
              value={inflationRate}
              valueSetter={setInflationRate}
              min={1}
              max={20}
              sx={{ mb: 5 }}
            />

            <InputSlider
              label="Time Period (yr)"
              value={timePeriod}
              valueSetter={setTimePeriod}
              min={1}
              max={50}
              sx={{ mb: 5 }}
            />

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid item className="result-common-div">
              <InputComponent
                label="Current Value"
                isInput={false}
                flag="dollar"
                value={currentCost}
              />

              <InputComponent
                label="Value Increase"
                isInput={false}
                flag="dollar"
                value={costIncrease}
              />

              <InputComponent
                label="Future Value"
                isInput={false}
                flag="dollar"
                value={futureCost}
              />
            </Grid>
          </Grid>

          <Grid className="piechart-div" item xs={12} md={5}>
            <PieChart
              data={[
                {
                  id: 0,
                  value: +currentCost,
                  label: "Current Cost",
                },
                {
                  id: 1,
                  value: +costIncrease,
                  label: "Cost Increase",
                },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
