import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../Context/DataContext";
import "./Card.scss";

const CardComponent = (props) => {
  const navigate = useNavigate();
  const { setTabIndex } = React.useContext(DataContext);
  const { title, description, path, image, alt } = props;
  const openCalcHandler = () => {
    setTabIndex(title);
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <Card
      className="card-container"
      sx={{ minHeight: { xs: "60px", sm: "250px" } }}
    >
      <CardActionArea onClick={(e) => openCalcHandler()} className="card-btn">
        <CardContent
          className="card-content-div"
          sx={{ minHeight: { xs: "auto", sm: "238px" } }}
        >
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="card-title"
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            className="card-detail-text"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardMedia
        component="img"
        image={image}
        alt={alt}
        className="card-media-img"
        sx={{ height: { xs: "40px", sm: "85px" } }}
        onClick={(e) => openCalcHandler()}
      />
    </Card>
  );
};
export default CardComponent;
