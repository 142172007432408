import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";

const TakeSalaryHomeCalc = () => {
  const [payFrequency, setPayFrequency] = useState("Weekly");
  const [payType, setPayType] = useState("Salary");
  const [grossPayMethod, setGrossPayMethod] = useState("pay per year");
  const [payHours, setPayHours] = useState(0);
  const [payAmount, setPayAmount] = useState(1000);
  const [overtimeHours, setOvertimeHours] = useState(0);
  const [overtimeAmount, setOvertimeAmount] = useState(0);
  const [earnings, setEarnings] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);

  useEffect(() => {
    // Calculate Earnings
    let grossPay = 0;
    if (payType === "Salary") {
      if (grossPayMethod === "pay per year") {
        switch (payFrequency) {
          case "Weekly":
            grossPay = +payAmount / 52;
            break;
          case "Bi-weekly":
            grossPay = +payAmount / 26;
            break;
          case "Monthly":
            grossPay = +payAmount / 12;
            break;
          case "Semi-monthly":
            grossPay = +payAmount / 24;
            break;
          default:
            grossPay = +payAmount;
        }
      } else {
        grossPay = +payAmount;
      }
    } else {
      grossPay = +payAmount * +payHours;
    }

    const overtimePay = +overtimeHours * +overtimeAmount * 1.5;
    const earnings = +grossPay + +overtimePay;
    setEarnings(earnings);

    const calculateFederalIncomeTax = (earnings) => {
      let finalTax = 0;
      if (earnings <= 300000) {
        finalTax = 0;
      } else if (earnings <= 600000) {
        finalTax = 0 + (earnings - 300000) * 0.05;
      } else if (earnings <= 900000) {
        finalTax = 15000 + (earnings - 600000) * 0.1;
      } else if (earnings <= 1200000) {
        finalTax = 45000 + (earnings - 900000) * 0.15;
      } else if (earnings <= 1500000) {
        finalTax = 90000 + (earnings - 1200000) * 0.2;
      } else {
        finalTax = 150000 + (earnings - 1500000) * 0.3;
      }

      switch (payFrequency) {
        case "Weekly":
          finalTax /= 52;
          break;
        case "Bi-weekly":
          finalTax /= 26;
          break;
        case "Monthly":
          finalTax /= 12;
          break;
        case "Semi-monthly":
          finalTax /= 24;
          break;
        default:
          break;
      }
      return finalTax;
    };

    // Set Federal Income Tax
    const federalTax = calculateFederalIncomeTax(earnings);

    // Calculate Taxes

    const socialSecurityRate = 0.062;
    const medicareRate = 0.0145;

    const socialSecurity = Number(earnings) * Number(socialSecurityRate);
    const medicare = Number(earnings) * Number(medicareRate);

    const totalDeductions =
      Number(federalTax) + Number(socialSecurity) + Number(medicare);
    setTaxes(totalDeductions.toFixed(2));
    // Calculate Take Home
    setTotalEarning(earnings - totalDeductions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payFrequency,
    payType,
    grossPayMethod,
    payAmount,
    overtimeHours,
    overtimeAmount,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader name="PayCheck Calculator" />

        <Grid container sx={{ p: "12px 30px" }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <Typography
                variant="h6"
                sx={{ mx: 1, fontWeight: 500, fontSize: "18px" }}
              >
                How often are you paid?
              </Typography>
              <SelectBoxComponent
                value={payFrequency}
                setValue={setPayFrequency}
                data={["Weekly", "Bi-weekly", "Monthly", "Semi-monthly"]}
                placeholder="Select pay frequency"
              />
              <Typography
                variant="h6"
                sx={{ mx: 1, fontWeight: 500, fontSize: "18px", mt: 2 }}
              >
                How are you paid?
              </Typography>
              <SelectBoxComponent
                value={payType}
                setValue={setPayType}
                data={["Salary", "Hourly"]}
                placeholder="Select type"
              />
              {payType === "Hourly" ? (
                <InputComponent
                  label="Hours"
                  isInput={true}
                  value={payHours}
                  setState={setPayHours}
                  min={1}
                  max={9999}
                />
              ) : (
                <SelectBoxComponent
                  value={grossPayMethod}
                  setValue={setGrossPayMethod}
                  data={["pay per year", "pay per month"]}
                  placeholder="Select Gross Pay Method"
                />
              )}
              <InputComponent
                label="Amount"
                isInput={true}
                flag="rupee"
                value={payAmount}
                setState={setPayAmount}
                min={0}
                max={10000000000000000}
              />
              <Typography
                variant="h6"
                sx={{ mx: 1, fontWeight: 500, fontSize: "18px" }}
              >
                Any overtime?
              </Typography>
              <InputComponent
                label="Hours"
                isInput={true}
                value={overtimeHours}
                setState={setOvertimeHours}
              />
              <InputComponent
                label="Amount"
                isInput={true}
                flag="rupee"
                value={overtimeAmount}
                setState={setOvertimeAmount}
              />
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                label="Earnings"
                isInput={false}
                value={earnings}
              />
              <InputComponent label="Taxes" isInput={false} value={taxes} />
              <InputComponent
                label="Take Home"
                isInput={false}
                value={totalEarning}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +totalEarning, label: "Take Home" },
                { id: 1, value: +taxes, label: "Taxes" },
              ]}
            />
          </Grid>
        </Grid>

        <BlogComponent />
      </Box>
    </>
  );
};

export default TakeSalaryHomeCalc;
