import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import DatePickerCommon from "../../Common/Datepicker/DatePickerCommon";
import "../AgeCalc/AgeCalc.scss";
import dayjs from "dayjs";
import dayCalcImg from "../../../Assets/ComponentImage/dayCalcAsset.jpg";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function DaysCalc() {
  const [day, setDay] = useState();
  const [fromDayCount, setFromDayCount] = useState(dayjs(new Date()));
  const [resultData, setResultData] = useState([]);


  useEffect(() => {
    if (day && fromDayCount) {
      const DiffInDays = fromDayCount.diff(day, "day");
      let result = {
        Days:
          Math.sign(+DiffInDays) === -1
            ? `${Math.abs(DiffInDays)} Days into future`
            : `${DiffInDays} Days`,
      };
      setResultData(result);
    }
  }, [day, fromDayCount]);
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <DatePickerCommon
                label="Start Date"
                value={day}
                setValue={setDay}
                enableFutureDate={true}
              />
              <DatePickerCommon
                label="End Date"
                value={fromDayCount}
                setValue={setFromDayCount}
                enableFutureDate={true}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Days"
                isInput={false}
                value={resultData?.Days || "0 Days"}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={0} md={1} />

          <Grid item xs={0} md={4} sx={{ py: 2 }}>
            <img
              src={dayCalcImg}
              alt="days-calculator"
              className="age-calc-assets"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default DaysCalc;
