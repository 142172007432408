import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import SipCalculation from "./SipCalculation";
import LumpsumCalculation from "./LumpsumCalculation";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function LumpsumCalc() {
  const [type, setType] = useState("Lumpsum");

  return (
    <>
           {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid sx={{ margin: "10px 4px" }}>
          <TabbingComponent
            data={["SIP", "Lumpsum"]}
            value={type}
            setValue={setType}
          />
        </Grid>
        <Grid>
          {type === "SIP" && <SipCalculation />}
          {type === "Lumpsum" && <LumpsumCalculation />}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default LumpsumCalc;
