import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import DatePickerCommon from "../../Common/Datepicker/DatePickerCommon";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import dayjs from "dayjs";
import "./TimeCalc.scss";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

const TimeCalc = () => {
  const CALCULATE = [
    { label: "Add", value: "add" },
    { label: "Subtract", value: "subtract" },
  ];
  const [addSubtract, setAddSubtract] = useState({ name: "Add", value: "add" });
  const [time, setTime] = useState(0);
  const [day1, setDay1] = useState(1);
  const [day2, setDay2] = useState(1);
  const [hour1, setHour1] = useState(1);
  const [hour2, setHour2] = useState(1);
  const [minute1, setMinute1] = useState(1);
  const [minute2, setMinute2] = useState(1);
  const [second1, setSecond1] = useState(1);
  const [second2, setSecond2] = useState(1);
  const [totalDays, setTotalDays] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);

  const [date, setDate] = useState();
  const [amOrPm, setAmOrPm] = useState("PM");
  const [addSubtract1, setAddSubtract1] = useState({
    name: "Add",
    value: "add",
  });
  const [day4, setDay4] = useState(1);
  const [hour3, setHour3] = useState(1);
  const [hour4, setHour4] = useState(1);
  const [minute3, setMinute3] = useState(1);
  const [minute4, setMinute4] = useState(1);
  const [second3, setSecond3] = useState(1);
  const [second4, setSecond4] = useState(1);
  const [timeWithDate, setTimeWithDate] = useState(0);

  const [expression, setExpression] = useState(
    "1d 2h 3m 4s + 4h 5s - 2030s + 28h"
  );
  const [result, setResult] = useState("");
  const [totalDays1, setTotalDays1] = useState(0);
  const [totalHours1, setTotalHours1] = useState(0);
  const [totalMinutes1, setTotalMinutes1] = useState(0);
  const [totalSeconds1, setTotalSeconds1] = useState(0);

  useEffect(() => {
    let totalDays, totalHours, totalMinutes, totalSeconds;

    const time1InSeconds =
      +day1 * 86400 + +hour1 * 3600 + +minute1 * 60 + +second1;
    const time2InSeconds =
      +day2 * 86400 + +hour2 * 3600 + +minute2 * 60 + +second2;

    let resultInSeconds;
    if (addSubtract?.value === "add") {
      resultInSeconds = +time1InSeconds + +time2InSeconds;
    } else {
      resultInSeconds = +time1InSeconds - +time2InSeconds;
    }

    let days = Math.floor(+resultInSeconds / (24 * 60 * 60));
    let remainingSeconds = +resultInSeconds % (24 * 60 * 60);
    totalDays = +days + +remainingSeconds / 86400;
    let hours = Math.floor(+remainingSeconds / (60 * 60));
    remainingSeconds %= 60 * 60;
    totalHours = +days * 24 + +hours + +remainingSeconds / 3600;
    let minutes = Math.floor(+remainingSeconds / 60);
    totalMinutes = +days * 1440 + +hours * 60 + +minutes + +minutes / 60;
    let seconds = +remainingSeconds % 60;
    totalSeconds = +days * 86400 + +hours * 3600 + +minutes * 60 + seconds;

    let times = `${days} days\t${hours} hours\t${minutes} minutes\t${seconds} seconds`;
    setTime(times);
    setTotalDays(+totalDays.toFixed(2));
    setTotalHours(+totalHours.toFixed(2));
    setTotalMinutes(+totalMinutes.toFixed(2));
    setTotalSeconds(+totalSeconds.toFixed(2));
  }, [
    day1,
    hour1,
    minute1,
    second1,
    day2,
    hour2,
    minute2,
    second2,
    addSubtract,
  ]);

  useEffect(() => {
    const calculateResult = () => {
      const startTime = `${dayjs(date).format(
        "YYYY-MM-DD"
      )} ${hour3}:${minute3}:${second3} ${amOrPm}`;

      let resultDate = moment(startTime, "YYYY-MM-DD hh:mm:ss A");

      if (addSubtract1?.value === "add") {
        resultDate = resultDate.add(parseInt(day4 || 0), "days");
        resultDate = resultDate.add(parseInt(hour4 || 0), "hours");
        resultDate = resultDate.add(parseInt(minute4 || 0), "minutes");
        resultDate = resultDate.add(parseInt(second4 || 0), "seconds");
      } else {
        resultDate = resultDate.subtract(parseInt(day4 || 0), "days");
        resultDate = resultDate.subtract(parseInt(hour4 || 0), "hours");
        resultDate = resultDate.subtract(parseInt(minute4 || 0), "minutes");
        resultDate = resultDate.subtract(parseInt(second4 || 0), "seconds");
      }

      setTimeWithDate(resultDate.format("MMMM DD, YYYY, hh:mm:ss A (dddd)"));
    };

    calculateResult();
  }, [
    date,
    hour3,
    minute3,
    second3,
    amOrPm,
    day4,
    hour4,
    minute4,
    second4,
    addSubtract1,
  ]);

  useEffect(() => {
    const calculateResult = (inputString) => {
      const matches = inputString.split(/(?=[+-])/).map((term) => term.trim());
      let totalSeconds = 0;

      matches.forEach((match) => {
        const daysMatch = match.match(/([-+]?\d+)d/);
        const hoursMatch = match.match(/([-+]?\d+)h/);
        const minutesMatch = match.match(/([-+]?\d+)m/);
        const secondsMatch = match.match(/([-+]?\d+)s/);

        let operator;
        if (match.match(/\s*-/)) {
          operator = -1;
        } else {
          operator = 1;
        }

        const days = daysMatch ? Math.abs(parseInt(+daysMatch[1])) : 0;
        const hours = hoursMatch ? Math.abs(parseInt(+hoursMatch[1])) : 0;
        const minutes = minutesMatch ? Math.abs(parseInt(+minutesMatch[1])) : 0;
        const seconds = secondsMatch ? Math.abs(parseInt(+secondsMatch[1])) : 0;

        totalSeconds +=
          operator *
          (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds);
      });

      let days = Math.floor(totalSeconds / (24 * 60 * 60));
      let remainingSeconds = totalSeconds % (24 * 60 * 60);
      let totalDays1 = days + remainingSeconds / 86400;
      let hours = Math.floor(remainingSeconds / (60 * 60));
      remainingSeconds %= 60 * 60;
      let totalHours1 = days * 24 + hours + remainingSeconds / 3600;
      let minutes = Math.floor(remainingSeconds / 60);
      let totalMinutes1 = days * 1440 + hours * 60 + minutes + minutes / 60;
      let seconds = remainingSeconds % 60;
      let totalSeconds1 = days * 86400 + hours * 3600 + minutes * 60 + seconds;

      let result = "";
      if (+days !== 0) result += `${+days}d `;
      if (+hours !== 0) result += `${+hours}h `;
      if (+minutes !== 0) result += `${+minutes}m `;
      if (+seconds !== 0) result += `${+seconds}s`;

      setResult(result.trim());
      setTotalDays1(+totalDays1.toFixed(2));
      setTotalHours1(+totalHours1.toFixed(2));
      setTotalMinutes1(+totalMinutes1.toFixed(2));
      setTotalSeconds1(+totalSeconds1.toFixed(2));
    };

    calculateResult(expression);
  }, [expression]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container gap={2.5} sx={{ mt: 2 }}>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Grid>
              <Typography className="common-sub-heading-calc">
                1. Add or Subtract Time
              </Typography>

              <InputComponent
                isInput={true}
                label="Day"
                value={day1}
                max={366}
                setState={setDay1}
              />
              <InputComponent
                isInput={true}
                label="Hour"
                max={60}
                value={hour1}
                setState={setHour1}
              />
              <InputComponent
                isInput={true}
                label="Minute"
                max={60}
                value={minute1}
                setState={setMinute1}
              />
              <InputComponent
                isInput={true}
                label="Second"
                max={60}
                value={second1}
                setState={setSecond1}
              />
              <RadioButtonComponent
                data={CALCULATE}
                defaultValue={"add"}
                valueSetter={setAddSubtract}
                row={false}
              />
              <InputComponent
                isInput={true}
                label="Day"
                value={day2}
                max={366}
                setState={setDay2}
              />
              <InputComponent
                isInput={true}
                label="Hour"
                max={60}
                value={hour2}
                setState={setHour2}
              />
              <InputComponent
                isInput={true}
                max={60}
                label="Minute"
                value={minute2}
                setState={setMinute2}
              />
              <InputComponent
                isInput={true}
                max={60}
                label="Second"
                value={second2}
                setState={setSecond2}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                isInput={false}
                label="Total Time"
                value={time}
                date={true}
              />
              <Grid className="result-common-div">
                <InputComponent
                  label="Days"
                  isInput={false}
                  value={totalDays}
                  date={true}
                />
                <InputComponent
                  label="Hours"
                  isInput={false}
                  value={totalHours}
                  date={true}
                />
                <InputComponent
                  label="Minutes"
                  isInput={false}
                  value={totalMinutes}
                  date={true}
                />
                <InputComponent
                  label="Seconds"
                  isInput={false}
                  value={totalSeconds}
                  date={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Grid>
              <Typography className="common-sub-heading-calc">
                2. Add or Subtract Time from a Date
              </Typography>

              <DatePickerCommon
                label="Start Time"
                value={date}
                setValue={setDate}
              />
              <InputComponent
                isInput={true}
                label="Hour"
                value={hour3}
                max={60}
                setState={setHour3}
              />
              <InputComponent
                isInput={true}
                label="Minute"
                value={minute3}
                max={60}
                setState={setMinute3}
              />
              <InputComponent
                isInput={true}
                label="Second"
                max={60}
                value={second3}
                setState={setSecond3}
              />
              <SelectBoxComponent
                value={amOrPm}
                setValue={setAmOrPm}
                data={["AM", "PM"]}
                placeholder="Select AM or PM"
              />
              <RadioButtonComponent
                data={CALCULATE}
                defaultValue={"add"}
                valueSetter={setAddSubtract1}
                row={false}
              />
              <InputComponent
                isInput={true}
                label="Day"
                max={366}
                value={day4}
                setState={setDay4}
              />
              <InputComponent
                isInput={true}
                label="Hour"
                max={60}
                value={hour4}
                setState={setHour4}
              />
              <InputComponent
                isInput={true}
                label="Minute"
                max={60}
                value={minute4}
                setState={setMinute4}
              />
              <InputComponent
                isInput={true}
                label="Second"
                max={60}
                value={second4}
                setState={setSecond4}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                isInput={false}
                label="Time"
                value={timeWithDate}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={5.5}
            className="calculation-part"
            sx={{ mt: 2 }}
          >
            <Grid>
              <Typography className="common-sub-heading-calc">
                3. Time Calculator in Expression
              </Typography>

              <textarea
                rows="4"
                // cols="65"
                value={expression}
                onChange={(e) => setExpression(e.target.value)}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                isInput={false}
                label="Total Time"
                value={result}
                date={true}
              />
              <InputComponent
                label="Days"
                isInput={false}
                value={totalDays1}
                date={true}
              />
              <InputComponent
                label="Hours"
                isInput={false}
                value={totalHours1}
                date={true}
              />
              <InputComponent
                label="Minutes"
                isInput={false}
                value={totalMinutes1}
                date={true}
              />
              <InputComponent
                label="Seconds"
                isInput={false}
                value={totalSeconds1}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
};

export default TimeCalc;
