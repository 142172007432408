import React from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Grid, Typography } from "@mui/material";
import "./Header.scss";
import { COMPONENTDATA } from "../../../Utils/Constant";

function ComponentHeader(props) {
  const { name } = props;
  const activeSlug = window.location.pathname.replace(/\//g, '');

  return (
    <Grid className="component-heading">
      <Typography variant="h1" className="back-btn">
        <ArrowBackOutlinedIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            window.history.back();
            window.scrollTo(0, 0);
          }}
        />
        {COMPONENTDATA?.filter((x) => x.slug === activeSlug)[0]?.title || name}
      </Typography>
    </Grid>
  );
}

export default ComponentHeader;
