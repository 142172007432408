import { TableCell, tableCellClasses, TableRow } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import { COMPONENTDATA, CONSTANT, conversionFactors } from "./Constant";
import { Helmet } from "react-helmet";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const formatDate = (inputDate) => {
  var inputDateObj = new Date(inputDate);
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var day = inputDateObj.getDate();
  var monthIndex = inputDateObj.getMonth();
  var year = inputDateObj.getFullYear();
  var formattedDate = monthNames[monthIndex] + " " + day + ", " + year;
  return formattedDate;
};

export const getValueBySlugOrTitle = (key, value) => {
  const item = COMPONENTDATA.find((item) => item[key] === value);
  return item ? (key === "slug" ? item.title : item.slug) : null;
};

export const decodeHTMLString = (htmlString) => {
  var decodedString = decodeURIComponent(htmlString);
  var tempElement = document.createElement("div");
  tempElement.innerHTML = decodedString;
  return tempElement.innerText || tempElement.textContent;
};

export const formatAmountIndian = (number, flag) => {
  // const scaleSuffixes = [
  //   "",
  //   "",
  //   "M",
  //   "B",
  //   "T",
  //   "Q",
  //   "Qu",
  //   "S",
  //   "Se",
  //   "O",
  //   "N",
  //   "D",
  //   "UD",
  //   "DD",
  //   "TD",
  //   "QD",
  //   "QnD",
  //   "SD",
  // ];
  // if (isNaN(number) || number === Infinity) {
  //   number = 0;
  // }
  // const magnitude = Math.floor(Math.log10(Math.abs(number)) / 3);
  // if (magnitude > 1) {
  //   const scale = scaleSuffixes[magnitude];

  //   const scaledNumber = number / Math.pow(10, magnitude * 3);

  //   var x = scaledNumber.toFixed(2).toString();
  //   var lastThree = x.substring(x.length - 3);
  //   var otherNumbers = x.substring(0, x.length - 3);
  //   if (otherNumbers !== "") {
  //     lastThree = "" + lastThree;
  //   }
  //   var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

  //   return "₹" + res + scale;
  // } else {
  //   var parts = number.toString().split(".");
  //   var integerPart = parts[0];
  //   var decimalPart = parts.length > 1 ? "." + parts[1] : "";

  //   // Format integer part with commas
  //   // eslint-disable-next-line no-redeclare
  //   var lastThree = integerPart.substring(integerPart.length - 3);
  //   // eslint-disable-next-line no-redeclare
  //   var otherNumbers = integerPart.substring(0, integerPart.length - 3);
  //   if (otherNumbers !== "") {
  //     lastThree = "," + lastThree;
  //   }
  //   var formattedInteger =
  //     otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

  //   // Concatenate formatted integer part with decimal part and return
  //   if (flag === "dollar") {
  //     return "$" + formattedInteger + decimalPart;
  //   } else {
  //     return "₹" + formattedInteger + decimalPart;
  //   }
  // }
  if (isNaN(number) || number === Infinity) {
    number = 0;
  }
  if (flag === "dollar") {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "USD",
    }).format(number);
  } else if (flag === "euro") {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "EUR",
    }).format(number);
  } else if (flag === "pound") {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "GBP",
    }).format(number);
  } else {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(number);
  }
};

export const getMessageByPercentage = (percentage) => {
  const messages = {
    0: "Uh oh, this might not be a match made in heaven.",
    10: "Not a strong connection, but there's always a chance!",
    20: "A little spark, but room for growth.",
    30: "Things are getting interesting! Potential for a good connection.",
    40: "There's definitely something there! Keep exploring.",
    50: "A balanced foundation for a relationship.",
    60: "Strong compatibility, keep nurturing it!",
    70: "Hot damn, this is getting serious!",
    80: "Soulmate vibes! A powerful connection.",
    90: "True love written in the stars!",
    100: "You two are practically soulmates!",
  };

  percentage = Math.min(100, Math.max(0, percentage));

  let closestKey = Object.keys(messages).reduce((prev, curr) => {
    return Math.abs(curr - percentage) < Math.abs(prev - percentage)
      ? curr
      : prev;
  });

  return messages[closestKey];
};

export const loveColor = (percent) => {
  if (percent < 0 || percent > 100) {
    return "Invalid percentage. Please enter a value between 0 and 100.";
  }

  if (percent <= 20) {
    return "#BEBEBE";
  } else if (percent <= 40) {
    return "#FFD1DC";
  } else if (percent <= 60) {
    return "#FFDAB9";
  } else if (percent <= 80) {
    return "#FF0000";
  } else {
    return "#FF1493";
  }
};

export const calculateHRADeduction = (
  basicSalary,
  da,
  hraReceived,
  totalRentPaid,
  metro
) => {
  const metroFactor = metro === "Yes" ? 0.5 : 0.4;
  const maxHRA = +hraReceived;
  const actualRentPaid = totalRentPaid - (basicSalary + da) * 0.1;

  const option1 = maxHRA;
  const option2 = (basicSalary + da) * metroFactor;
  const option3 = actualRentPaid;

  return Math.min(option1, option2, option3);
};

export const calculateTotalDeduction = (
  basicDeduction80C,
  npsContribution,
  medicalInsurance80D,
  donation80G,
  educationLoanInterest80E,
  savingInterest80TTA
) => {
  const section80C = Math.min(basicDeduction80C + npsContribution, 150000);
  const section80D = Math.min(medicalInsurance80D, 25000);

  const total80D = Math.min(section80D, 75000);

  const section80G = donation80G;

  const section80E = educationLoanInterest80E;
  const section80TTA = Math.min(savingInterest80TTA, 10000);
  const totalDeduction =
    section80C + total80D + section80G + section80E + section80TTA;

  return totalDeduction;
};

export const calculateHousePropertyIncome = (
  rentalIncome,
  interestSelfOccupied,
  interestLetOut
) => {
  let grossAnnualValue = rentalIncome;

  let propertyTax = 0;
  let netAnnualValue = grossAnnualValue - propertyTax;

  let standardDeduction = 0.3 * netAnnualValue;

  let totalInterestDeduction = interestSelfOccupied + interestLetOut;

  let incomeFromHouseProperty =
    netAnnualValue - standardDeduction - totalInterestDeduction;

  let lossFromHouseProperty = Math.min(incomeFromHouseProperty, 200000);

  return lossFromHouseProperty;
};

export const gradeLetterColor = (letterGrade) => {
  if (letterGrade === "A+") {
    return "#00FF00";
  } else if (letterGrade === "A") {
    return "#33CC33";
  } else if (letterGrade === "A-") {
    return "#66CC66";
  } else if (letterGrade === "B+") {
    return "#FFFF00";
  } else if (letterGrade === "B") {
    return "#FFCC00";
  } else if (letterGrade === "B-") {
    return "#FF9933";
  } else if (letterGrade === "C+") {
    return "#FFA500";
  } else if (letterGrade === "C") {
    return "#FF6600";
  } else if (letterGrade === "C-") {
    return "#FF3333";
  } else if (letterGrade === "D+") {
    return "#FF0000";
  } else if (letterGrade === "D") {
    return "#CC0000";
  } else if (letterGrade === "D-") {
    return "#990000";
  } else {
    return "#FF0000";
  }
};

export const returnHelmetAsPerParameter = (blogData) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(
    blogData?.yoast ? updateYoastHeadUrls(blogData?.yoast) : "",
    "text/html"
  );
  const headElements = Array.from(doc.head.children);

  return (
    <Helmet>
      {headElements.length > 0 &&
        headElements?.map((element, index) => {
          // Clone the element to use as React component
          const attributes = Array.from(element.attributes).reduce(
            (acc, attr) => {
              acc[attr.name] = attr.value;
              return acc;
            },
            {}
          );

          // Check the tag name and create corresponding React component
          switch (element.tagName) {
            // case "TITLE":
            //   return <title key={index}>{element.textContent}</title>;
            case "META":
              return <meta key={index} {...attributes} />;
            case "SCRIPT":
              return (
                <script key={index} {...attributes} type="application/ld+json">
                  {element.textContent}
                </script>
              );
            default:
              return null;
          }
        })}
    </Helmet>
  );
};

export const getUniqueCategories = (array) => {
  const categorySet = new Set();
  for (const item of array) {
    categorySet.add(item.category.toLowerCase()); // Ensure case-insensitivity (optional)
  }
  return Array.from(categorySet).reverse();
};

export const convert = (value, fromUnit, toUnit) => {
  if (
    conversionFactors[fromUnit.trim()] === undefined ||
    conversionFactors[toUnit.trim()] === undefined
  ) {
    return false;
  }

  const valueInBaseUnit = value * conversionFactors[fromUnit];
  const convertedValue = valueInBaseUnit / conversionFactors[toUnit];

  return convertedValue.toFixed(2);
};

export const convertTemperature = (value, fromUnit, toUnit) => {
  if (fromUnit === toUnit) {
    return value;
  }

  let valueInCelsius;

  // Convert from the original unit to Celsius
  if (fromUnit === "Celsius") {
    valueInCelsius = value;
  } else if (fromUnit === "Kelvin") {
    valueInCelsius = value - 273.15;
  } else if (fromUnit === "Fahrenheit") {
    valueInCelsius = (value - 32) * (5 / 9);
  } else {
    return null;
  }

  // Convert from Celsius to the target unit
  if (toUnit === "Celsius") {
    return valueInCelsius;
  } else if (toUnit === "Kelvin") {
    return valueInCelsius + 273.15;
  } else if (toUnit === "Fahrenheit") {
    return valueInCelsius * (9 / 5) + 32;
  } else {
    return null;
  }
};

export const updateYoastHeadUrls = (yoastDataInitial) => {
  // Check if yoastDataInitial is a string
  if (typeof yoastDataInitial !== "string") {
    throw new TypeError("The yoast_head parameter must be a string.");
  }

  // Extract the JSON-LD script content
  let yoast_head = yoastDataInitial.replace(
    '\n<meta name="robots" content="noindex, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />',
    ""
  );
  let ldJsonStart = yoast_head.indexOf('<script type="application/ld+json"');
  let ldJsonEnd =
    ldJsonStart === -1 ? -1 : yoast_head.indexOf("</script>", ldJsonStart) + 9;

  let ldJsonContent =
    ldJsonStart === -1 ? "" : yoast_head.substring(ldJsonStart, ldJsonEnd);
  let jsonString = ldJsonContent.match(
    /<script[^>]*>([\s\S]*?)<\/script>/
  )?.[1];

  let jsonLdObject;
  try {
    jsonLdObject = JSON.parse(jsonString);
  } catch (error) {
    console.error("Failed to parse JSON-LD content:", error);
    jsonLdObject = null;
  }

  // Regular expression to match URLs with subdomains and optional date components
  const urlPattern =
    /https:\/\/api\.calculatortimes\.com\/(\d{4}\/\d{2}\/\d{2}\/)?/g;
  const arrayUrlPattern = /http:\/\/api\.calculatortimes\.com/g;

  // Function to recursively update URLs in the JSON-LD object
  function updateUrls(obj) {
    if (Array.isArray(obj)) {
      // If obj is an array, update each string element
      return obj.map((item) =>
        typeof item === "string"
          ? item.replace(arrayUrlPattern, "https://calculatortimes.com")
          : updateUrls(item)
      );
    } else if (typeof obj === "object" && obj !== null) {
      // If obj is an object, iterate over its properties
      for (let key in obj) {
        if (typeof obj[key] === "string") {
          obj[key] = obj[key].replace(
            urlPattern,
            "https://calculatortimes.com/"
          );
        } else if (typeof obj[key] === "object") {
          obj[key] = updateUrls(obj[key]);
        }
      }
    }
    return obj;
  }

  // Update URLs in the JSON-LD object if it exists
  if (jsonLdObject) {
    updateUrls(jsonLdObject);
  }

  // Convert the updated JSON-LD object back to a string
  let updatedJsonString = JSON.stringify(jsonLdObject || {});

  // Replace the original JSON-LD script in the yoast_head with the updated one
  let updatedLdJsonContent = ldJsonContent.replace(
    jsonString,
    updatedJsonString
  );
  let updatedYoastHead = yoast_head.replace(
    ldJsonContent,
    updatedLdJsonContent
  );
  // Extract and update the <meta> tag content
  updatedYoastHead = updatedYoastHead.replace(
    urlPattern,
    "https://calculatortimes.com/"
  );

  return updatedYoastHead;
};

export const returnHelmetData = () => {
  let dynamicSlug = window.location.pathname;

  if (dynamicSlug.startsWith("/")) {
    dynamicSlug = dynamicSlug.substring(1);
  }
  if (dynamicSlug.endsWith("/")) {
    dynamicSlug = dynamicSlug.substring(0, dynamicSlug.length - 1);
  }
  const calcData = COMPONENTDATA.filter((x) => x.slug === dynamicSlug)[0];

  return (
    <Helmet>
      <title>{calcData?.title} - CalculatorTimes</title>
      <meta name="description" content={calcData?.description} />

      <meta name="keywords" content={calcData?.keywords} />
      <meta
        property="og:title"
        content={`${calcData?.title} - CalculatorTimes`}
      />
      <link rel="canonical" href={window.location.href} />
      <meta name="robots" content="index" />
      <meta name="googlebot" content="index" />
      <meta name="googlebot-news" content="index" />
      <meta property="og:description" content={calcData?.description} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`https://calculatortimes.com/${calcData.slug}`}
      />
      <meta property="og:site_name" content={CONSTANT.CALCULATOR_TIMES} />
      <meta
        name="twitter:title"
        content={`${calcData?.title} - CalculatorTimes`}
      />
      <meta name="twitter:description" content={calcData?.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          name: calcData.title,
          description: calcData?.description,
          url: `https://calculatortimes.com/${calcData.slug}`,
          keywords: calcData.keywords,
          potentialAction: {
            "@type": "CalculateAction",
            target: `https://calculatortimes.com/${calcData.slug}`,
          },
        })}
      </script>
    </Helmet>
  );
};
