import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "../GPACalc/Table.scss";

const DISTANCE_UNITS = ["Kilometers", "Miles", "Meters", "Yards"];

export default function DenseTable({
  numOfRows,
  headerData,
  distances,
  distanceUnits,
  times,
}) {
  const rowHeaderCellStyling = {
    align: "left",
    backgroundColor: "#295fa6",
    fontWeight: "bold",
    color: "white",
    paddingRight: "5px",
  };

  return (
    <TableContainer className="tableBorder" sx={{}} component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ border: "2px solid #295fa6" }}>
            {headerData?.map((item, index) => (
              <TableCell
                align="left"
                sx={{ ...rowHeaderCellStyling }}
                key={index}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(numOfRows)].map((value, i) => (
            <TableRow key={i}>
              <TableCell>
                <TextField
                  sx={{ width: "100%" }}
                  id={`d${i}`}
                  name={`d${i}`}
                  label="Distance"
                  size="small"
                  variant="outlined"
                  value={distances.data[`d${i}`]}
                  onChange={(event) => {
                    distances.setter((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                />
              </TableCell>
              <TableCell>
                <Select
                  sx={{ width: "100%" }}
                  id={`du${i}`}
                  name={`du${i}`}
                  value={distanceUnits.data[`du${i}`]}
                  onChange={(event) => {
                    distanceUnits.setter((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                >
                  {DISTANCE_UNITS.map((unit, index) => (
                    <MenuItem key={index} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <TextField
                  sx={{ width: "100%" }}
                  id={`t${i}`}
                  name={`t${i}`}
                  label="Time"
                  size="small"
                  variant="outlined"
                  value={times.data[`t${i}`]}
                  onChange={(event) => {
                    times.setter((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
