import { Box, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import {
  calculateBMR,
  generateCalorieNeeds,
  BMR_FORMULAS,
  ENERGY_UNITS,
  GENDERS,
} from "../../../Utils/calorieHelper";
import TableComponent from "../../Common/TableComponent/TableComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import {
  returnHelmetData
} from "../../../Utils/CommonFunction";
import UnitConversion from "../../Common/UnitConversion/UnitConversion";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

export default function BMRCalc() {
  const [weight, setWeight] = useState({ unit: "kg", value: 70 });
  const [height, setHeight] = useState({ unit: "cm", value: 170 });
  const [age, setAge] = useState(22);
  const [gender, setGender] = useState({ name: "Male", value: "male" });
  const [fat, setFat] = useState(0.2);
  const [BMRFormula, setBMRFormula] = useState({
    name: "Mifflin-St Jeor",
    value: "MJ",
  });
  const [BMR, setBMR] = useState(1658);
  const [energyUnit, setEnergyUnit] = useState({
    name: "Calories",
    value: "cal",
  });

  const [tableView, setTableView] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [unitConvert, setUnitConvert] = useState(false);

  useEffect(() => {
    let weightInKG =
      weight.unit === "kg" ? weight.value : weight.value / 2.20462;
    let heigthInCM =
      height.unit === "cm"
        ? height.value
        : height.value.ft * 30.48 + height.value.in * 2.54;

    setBMR(() =>
      calculateBMR(
        weightInKG,
        heigthInCM,
        age,
        fat,
        BMRFormula.value,
        gender.value,
        energyUnit.value
      )
    );
    setTableData(() => generateCalorieNeeds(BMR));
  }, [weight, height, gender, age, fat, BMRFormula, energyUnit, BMR]);

  const weightUnitSetter = (value) =>
    setWeight((prev) => ({ ...prev, unit: value }));
  const weightValueSetter = (event) => {
    const value = parseFloat(event.target.value);
    setWeight((prev) => ({ ...prev, value: Math.min(value, 500) }));
  };

  const heightUnitSetter = (value) => {
    if (value === "ft") {
      setHeight({ unit: value, value: { ft: 5, in: 8 } });
    } else if (value === "cm") {
      setHeight({ unit: value, value: 170 });
    }
  };

  const heightCMValueSetter = (value) =>
    setHeight((prev) => ({ ...prev, value: Math.min(value, 260) }));
  const heightFTValueSetter = (value) =>
    setHeight((prev) => ({
      ...prev,
      value: { ...prev.value, ft: Math.min(value, 8) },
    }));
  const heightINValueSetter = (value) =>
    setHeight((prev) => ({
      ...prev,
      value: { ...prev.value, in: Math.min(value, 12) },
    }));

  const fatSetter = (value) => setFat(() => Math.min(value / 100, 1));

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={8} className="calculation-part ">
            <Typography className="common-sub-heading-desc">
              The Basal Metabolic Rate (BMR) Calculator estimates your basal
              metabolic rate - the amount of energy expended while at rest in a
              neutrally temperate environment, and in a post-absorptive state
              (meaning that the digestive system is inactive, which requires
              about 12 hours of fasting).
            </Typography>

            <Grid>
              <InputComponent
                label="Age"
                isInput={true}
                value={age}
                min={1}
                max={199}
                setState={setAge}
              />
              <RadioButtonComponent
                label={"Gender"}
                data={GENDERS}
                defaultValue={"male"}
                valueSetter={setGender}
                row={false}
              />
              {height.unit === "cm" && (
                <InputComponent
                  label="Height (cm)"
                  isInput={true}
                  value={height.value}
                  min={1}
                  max={200}
                  setState={heightCMValueSetter}
                />
              )}

              {height.unit === "ft" && (
                <>
                  <InputComponent
                    label="Height (ft)"
                    isInput={true}
                    min={1}
                    max={10}
                    value={height.value.ft}
                    setState={heightFTValueSetter}
                  />

                  <InputComponent
                    label="Height (in)"
                    isInput={true}
                    min={1}
                    max={20}
                    value={height.value.in}
                    setState={heightINValueSetter}
                  />
                </>
              )}
              <SelectBoxComponent
                label={"Height Unit"}
                value={height.unit}
                setValue={heightUnitSetter}
                data={["cm", "ft"]}
                placeholder="Select height unit"
              />

              <Grid>
                <Grid className="concrete-calc-input pace">
                  <Typography className="input-label">Weight</Typography>
                  <Grid sx={{ display: "flex" }}>
                    <input
                      type="number"
                      value={weight.value}
                      onChange={weightValueSetter}
                      className="input-field"
                    />

                    <Grid className="select-box-wrap">
                      <SelectBoxComponent
                        value={weight.unit}
                        setValue={weightUnitSetter}
                        data={["kg", "lb"]}
                        placeholder="Select weight unit"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <RadioButtonComponent
              label={"BMR estimation formula"}
              data={BMR_FORMULAS}
              defaultValue={"MJ"}
              valueSetter={setBMRFormula}
              row={false}
            />

            {BMRFormula.value === "KM" && (
              <InputComponent
                label="Body Fat (%)"
                isInput={true}
                value={fat * 100}
                setState={fatSetter}
              />
            )}

            <RadioButtonComponent
              label={"Results unit:"}
              data={ENERGY_UNITS}
              defaultValue={"cal"}
              valueSetter={setEnergyUnit}
              row={false}
            />
            <Divider sx={{ my: 2 }} />

            {!unitConvert ? (
              <Grid sx={{ textAlign: "center" }}>
                <Button
                  onClick={(e) => setUnitConvert(!unitConvert)}
                  variant="contained"
                >
                  Unit Conversion
                </Button>
              </Grid>
            ) : (
              <UnitConversion setUnitConvert={setUnitConvert} />
            )}
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Your BMR is"
                isInput={false}
                value={`${BMR.toFixed(0) || 0}${
                  energyUnit.value === "cal" ? "Calories/day" : "kJ/day"
                }`}
                date={true}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid className="data-table-container">
              <Grid className="table-toggle-div">
                <Typography variant="h6" sx={{ textAlign: "left" }}>
                  Daily calorie needs based on activity level
                </Typography>
                <Tooltip
                  title={
                    tableView ? "click to close table" : "click to open table"
                  }
                  placement="top"
                  arrow
                >
                  <span>
                    <img
                      src={plusIcon}
                      alt="plusIcon"
                      onClick={() => setTableView(!tableView)}
                      style={{
                        transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                      }}
                      className="add-icon-table"
                    />
                  </span>
                </Tooltip>
              </Grid>
              {tableView && (
                <Grid>
                  <Grid>
                    <TableComponent
                      rows={tableData}
                      headerData={["Activity Level", `${energyUnit.name}`]}
                      isMinWidth={300}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
