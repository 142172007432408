import { Box, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  returnHelmetData
} from "../../../Utils/CommonFunction";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import { GENDERS } from "../../../Utils/calorieHelper";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import UnitConversion from "../../Common/UnitConversion/UnitConversion";
import TableComponent from "../../Common/TableComponent/TableComponent";

export default function BodyFatCalc() {
  const [age, setAge] = useState(25);
  const [weight, setWeight] = useState(70);
  const [height, setHeight] = useState(178);
  const [neck, setNeck] = useState(50);
  const [waist, setWaist] = useState(96);
  const [hip, setHip] = useState(92);
  const [gender, setGender] = useState({ name: "Male", value: "male" });

  const [bodyFat, setBodyFat] = useState(15.7);
  const [bodyFatCategory, setBodyFatCategory] = useState("Fitness");
  const [leanBodyMass, setLeanBodyMass] = useState(59.0);
  const [idealBodyFat, setIdealBodyFat] = useState(10.5);
  const [bodyFatToLose, setBodyFatToLose] = useState(3.6);
  const [bmiBodyFat, setBmiBodyFat] = useState(16.1);
  const [tableView, setTableView] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [unitConvert, setUnitConvert] = useState(false);

  useEffect(() => {
    calculateBodyFat();
    generateTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    age,
    weight,
    height,
    neck,
    waist,
    hip,
    gender,
    bodyFat,
    bodyFatCategory,
    leanBodyMass,
    idealBodyFat,
    bodyFatToLose,
    bmiBodyFat,
  ]);

  const calculateBodyFat = () => {
    let bodyFatPercentage = 0;
    if (gender.value === "male") {
      bodyFatPercentage =
        495 /
          (1.0324 -
            0.19077 * Math.log10(+waist - +neck) +
            0.15456 * Math.log10(+height)) -
        450;
    } else {
      bodyFatPercentage =
        495 /
          (1.29579 -
            0.35004 * Math.log10(+waist + +hip - +neck) +
            0.221 * Math.log10(+height)) -
        450;
    }

    setBodyFat(bodyFatPercentage.toFixed(1));
    setBodyFatCategory(getBodyFatCategory(bodyFatPercentage, gender.value));
    const fatMass = +weight * (+bodyFatPercentage / 100);
    setLeanBodyMass((+weight - +fatMass).toFixed(1));
    setIdealBodyFat(getIdealBodyFat(age, gender.value));
    const fatToLose = +fatMass - (+weight * +idealBodyFat) / 100;
    setBodyFatToLose(Math.abs(fatToLose).toFixed(1));
    const bmi = +weight / Math.pow(+height / 100, 2);
    setBmiBodyFat(calculateBmiBodyFat(bmi, age, gender.value).toFixed(1));
  };

  const calculateBmiBodyFat = (bmi, age, gender) => {
    if (gender === "male") {
      return 1.2 * +bmi + 0.23 * +age - 16.2;
    } else {
      return 1.2 * +bmi + 0.23 * +age - 5.4;
    }
  };

  const getBodyFatCategory = (bodyFatPercentage, gender) => {
    if (gender === "male") {
      if (bodyFatPercentage < 6) return "Essential";
      if (bodyFatPercentage >= 6 && bodyFatPercentage < 14) return "Athletes";
      if (bodyFatPercentage >= 14 && bodyFatPercentage < 18) return "Fitness";
      if (bodyFatPercentage >= 18 && bodyFatPercentage < 25) return "Average";
      if (bodyFatPercentage >= 25) return "Obese";
    } else {
      if (bodyFatPercentage < 14) return "Essential";
      if (bodyFatPercentage >= 14 && bodyFatPercentage < 21) return "Athletes";
      if (bodyFatPercentage >= 21 && bodyFatPercentage < 25) return "Fitness";
      if (bodyFatPercentage >= 25 && bodyFatPercentage < 32) return "Average";
      if (bodyFatPercentage >= 32) return "Obese";
    }
    return 0;
  };

  const getIdealBodyFat = (age, gender) => {
    if (gender === "male") {
      if (age <= 20) return 8.5;
      if (age <= 25) return 10.5;
      if (age <= 30) return 12.7;
      if (age <= 35) return 13.7;
      if (age <= 40) return 15.3;
      if (age <= 45) return 16.4;
      if (age <= 50) return 18.9;
      if (age <= 55) return 20.9;
      return 20.9;
    } else {
      if (age <= 20) return 17.7;
      if (age <= 25) return 18.4;
      if (age <= 30) return 19.3;
      if (age <= 35) return 21.5;
      if (age <= 40) return 22.2;
      if (age <= 45) return 22.9;
      if (age <= 50) return 25.2;
      if (age <= 55) return 26.3;
      return 26.3;
    }
  };

  const generateTableData = () => {
    const data = [
      { name: "Body Fat", value: `${+bodyFat}%` },
      { name: "Body Fat Category", value: bodyFatCategory },
      {
        name: "Body Fat Mass",
        value: `${(+weight * (+bodyFat / 100)).toFixed(1)} kg`,
      },
      { name: "Lean Body Mass", value: `${+leanBodyMass} kg` },
      { name: "Ideal Body Fat for Given Age", value: `${+idealBodyFat}%` },
      {
        name: "Body Fat to Lose to Reach Ideal",
        value: `${+bodyFatToLose} kg`,
      },
      { name: "Body Fat (BMI method)", value: `${+bmiBodyFat}%` },
    ];
    setTableData(data);
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <RadioButtonComponent
                label={"Gender"}
                data={GENDERS}
                defaultValue={"male"}
                valueSetter={setGender}
                row={false}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Age in Years"
                value={age}
                min={2}
                max={140}
                setState={setAge}
              />
              <InputComponent
                label="Weight"
                isInput={true}
                min={1}
                value={weight}
                setState={setWeight}
                max={400}
                flag="kilogram"
              />
              <InputComponent
                label="Height"
                isInput={true}
                min={1}
                value={height}
                setState={setHeight}
                max={400}
                flag="centimeter"
              />
              <InputComponent
                label="Neck"
                isInput={true}
                min={1}
                value={neck}
                setState={setNeck}
                max={400}
                flag="centimeter"
              />
              <InputComponent
                label="Waist"
                isInput={true}
                min={1}
                value={waist}
                setState={setWaist}
                max={400}
                flag="centimeter"
              />
              {gender.value === "female" && (
                <InputComponent
                  label="Hip"
                  isInput={true}
                  min={1}
                  value={hip}
                  setState={setHip}
                  max={400}
                  flag="centimeter"
                />
              )}
            </Grid>

            <Divider sx={{ my: 2 }} />

            {!unitConvert ? (
              <Grid sx={{ textAlign: "center" }}>
                <Button
                  onClick={(e) => setUnitConvert(!unitConvert)}
                  variant="contained"
                >
                  Unit Conversion
                </Button>
              </Grid>
            ) : (
              <UnitConversion setUnitConvert={setUnitConvert} />
            )}
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Body Fat"
                isInput={false}
                value={isNaN(bodyFat) ? 0 : `${bodyFat}%`}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid className="data-table-container">
              <Grid className="table-toggle-div">
                <Typography variant="h6" sx={{ textAlign: "left" }}>
                  Body Fat Details
                </Typography>
                <Tooltip
                  title={
                    tableView ? "click to close table" : "click to open table"
                  }
                  placement="top"
                  arrow
                >
                  <span>
                    <img
                      src={plusIcon}
                      alt="plusIcon"
                      onClick={() => setTableView(!tableView)}
                      style={{
                        transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                      }}
                      className="add-icon-table"
                    />
                  </span>
                </Tooltip>
              </Grid>
              {tableView && (
                <Grid>
                  <Grid>
                    <TableComponent
                      rows={tableData}
                      headerData={["Name", "Value"]}
                      isMinWidth={300}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
