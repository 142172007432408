import HomeLoanCalc from "../Component/Pages/HomeLoanCalc/HomeLoanCalc";
import SipCalc from "../Component/Pages/SipCalc/SipCalc";
import SimpleInterestCalc from "../Component/Pages/SimpleInterestCalc/SimpleInterestCalc";
import GstCalc from "../Component/Pages/GSTCalc/GstCalc";
import LumpsumCalc from "../Component/Pages/SipCalc/LumpsumCalc";
import AgeCalc from "../Component/Pages/AgeCalc/AgeCalc";
import LoveCalc from "../Component/Pages/LoveCalc/LoveCalc";
import CompoundInterestCalc from "../Component/Pages/CompoundInterestCalc/CompoundInterestCalc";
import PpfCalc from "../Component/Pages/PPFCalc/PpfCalc";
import PercentageCalc from "../Component/Pages/PercentageCalc/PercentageCalc";
import FDCalc from "../Component/Pages/FDCalc/FDCalc";
import IncomeTaxCalc from "../Component/Pages/IncomeTaxCalc/IncomeTaxCalc";
import BMICalc from "../Component/Pages/BMICalc/BMICalc";
import DateCalc from "../Component/Pages/DateCalc/DateCalc";
import GratuityCalc from "../Component/Pages/GratuityCalc/GratuityCalc";
import BMRCalc from "../Component/Pages/BMRCalc/BMRCalc";
import CalorieCalc from "../Component/Pages/CalorieCalc/CalorieCalc";
import TipCalc from "../Component/Pages/TipCalc/TipCalc";
import TimeCalc from "../Component/Pages/TimeCalc/TimeCalc";
import PregnancyCalc from "../Component/Pages/PregnancyCalc/PregnancyCalc";
import GPACalc from "../Component/Pages/GPACalc/GPACalc";
import InflationCalc from "../Component/Pages/InflationCalc/InflationCalc";
import OvulationCalc from "../Component/Pages/OvulationCalc/OvulationCalc";
import MortgageLoanCalc from "../Component/Pages/MortgageLoanCalc/MortgageLoanCalc";
import FractionCalc from "../Component/Pages/FractionCalc/FractionCalc";
import DaysCalc from "../Component/Pages/DaysCalc/DaysCalc";
import ScientificCalculator from "../Component/Pages/ScientificCalculator/ScientificCalculator";
import InvestmentCalc from "../Component/Pages/InvestmentCalc/InvestmentCalc";
import DateOfBirthCalc from "../Component/Pages/DateOfBirthCalc/DateOfBirthCalc";
import CarLoanCalc from "../Component/Pages/CarLoanCalc/CarLoanCalc";
import GradeCalc from "../Component/Pages/GradeCalc/GradeCalc";
import LoanCalc from "../Component/Pages/LoanCalc/LoanCalc";
import FinalGradeCalc from "../Component/Pages/FinalGradeCalc/FinalGradeCalc";
import PayCheckCalc from "../Component/Pages/PayCheckCalc/PayCheckCalc";
import StandardDeviationCalc from "../Component/Pages/StandardDeviationCalc/StandardDeviationCalc";
import TdeeCalc from "../Component/Pages/TdeeCalc/TdeeCalc";
import DueDateCalc from "../Component/Pages/DueDateCalc/DueDateCalc";
import ConcreteCalc from "../Component/Pages/ConcreteCalc/ConcreteCalc";
import AmortizationCalc from "../Component/Pages/AmortizationCalc/AmortizationCalc";
import SalaryCalc from "../Component/Pages/SalaryCalc/SalaryCalc";
import CDCalc from "../Component/Pages/CDCalc/CDCalc";
import RetirementCalc from "../Component/Pages/RetirementCalc/RetirementCalc";
import MutualFundCalc from "../Component/Pages/MutualFundCalc/MutualFundCalc";
import RDCalc from "../Component/Pages/RDCalc/RDCalc";
import HRACalc from "../Component/Pages/HRACalc/HRACalc";
import SukanyaSamridhiYojanaCalc from "../Component/Pages/SukanyaSamridhiYojanaCalc/SukanyaSamridhiYojanaCalc";
import TakeSalaryHomeCalc from "../Component/Pages/TakeSalaryHome/TakeSalaryHomeCalc";
import PoundsToDollarsCalc from "../Component/Pages/PoundsToDollarsCalc/PoundsToDollarsCalc";
import EurosToPoundsCalc from "../Component/Pages/EurosToPoundsCalc/EurosToPoundsCalc";
import PasswordGeneratorCalc from "../Component/Pages/PasswordGeneratorCalc/PasswordGeneratorCalc";
import IsoscelesTriangleCalc from "../Component/Pages/IsoscelesTriangleCalc/IsoscelesTriangleCalc";
import RandomNumberGeneratorCalc from "../Component/Pages/RandomNumberGeneratorCalc/RandomNumberGeneratorCalc";
import BodyFatCalc from "../Component/Pages/BodyFatCalc/BodyFatCalc";
import WeightLossCalc from "../Component/Pages/WeightLossCalc/WeightLossCalc";
import MacroCalc from "../Component/Pages/MacroCalc/MacroCalc";
import PaceCalculator from "../Component/Pages/PaceCalc/PaceCalculator";
import SubnetCalc from "../Component/Pages/SubnetCalc/SubnetCalc";

import AgeCalcIcon from "../Assets/CalculatorIcon/Age-Calculator.png";
import EmiCalcIcon from "../Assets/CalculatorIcon/EMI-calculator.png";
import SipCalcIcon from "../Assets/CalculatorIcon/SIP-calculator.png";
import PercentageCalcIcon from "../Assets/CalculatorIcon/Percentage-calculator.png";
import HomeLoneCalcIcon from "../Assets/CalculatorIcon/home-loan-calculator.png";
import LoveCalcIcon from "../Assets/CalculatorIcon/Love-calculator.png";
import CompoundInterestCalcIcon from "../Assets/CalculatorIcon/compound-interest-calculator.png";
import IncomeTaxCalcIcon from "../Assets/CalculatorIcon/Income-tax-calculator.png";
import FDCalcIcon from "../Assets/CalculatorIcon/Fix-Deposit-calculator.png";
import PpfCalcIcon from "../Assets/CalculatorIcon/PPF-calculator.png";
import GstCalcIcon from "../Assets/CalculatorIcon/GST-Calculator.png";
import LumpsumCalcIcon from "../Assets/CalculatorIcon/Lumpsum-Calculator.png";
import BMICalcIcon from "../Assets/CalculatorIcon/BMI-Calculator.png";
import DateCalcIcon from "../Assets/CalculatorIcon/Date-Calculator.png";
import GratuityCalcIcon from "../Assets/CalculatorIcon/Gratuity-Calculator.png";
import BMRCalcIcon from "../Assets/CalculatorIcon/BMR-Calculator.png";
import CalorieCalcIcon from "../Assets/CalculatorIcon/Calories-Calculator.png";
import PregnancyCalcIcon from "../Assets/CalculatorIcon/Pregnancy-Calculator.png";
import TipCalcIcon from "../Assets/CalculatorIcon/Tips-Calculator.png";
import TimeCalcIcon from "../Assets/CalculatorIcon/Time-Calculator.png";
import GPACalcIcon from "../Assets/CalculatorIcon/GPA-Calculator.png";
import InflationCalcIcon from "../Assets/CalculatorIcon/inflation-Calculator.png";
import OvulationCalcIcon from "../Assets/CalculatorIcon/Ovulation-Calculator.png";
import DaysCalcIcon from "../Assets/CalculatorIcon/Days-Calculator.png";
import ScientificCalIcon from "../Assets/CalculatorIcon/Scientific-Calculator.png";
import InvestmentCalcIcon from "../Assets/CalculatorIcon/Investment-Calculator.png";
import DateOfBirthCalcIcon from "../Assets/CalculatorIcon/Date-of-Birth-Calculator.png";
import CarLoanCalcIcon from "../Assets/CalculatorIcon/Car-loan-Calculator.png";
import GradeCalcIcon from "../Assets/CalculatorIcon/Grade-Calculator.png";
import LoanCalcIcon from "../Assets/CalculatorIcon/Loan-Calculator.png";
import FinalGradeCalcIcon from "../Assets/CalculatorIcon/Final-grade-Calculator.png";
import PayCheckCalcIcon from "../Assets/CalculatorIcon/Paycheck-Calculator.png";
import MortgageLoanCalcIcon from "../Assets/CalculatorIcon/mortgage-Calculator.png";
import FractionCalcIcon from "../Assets/CalculatorIcon/Fraction-Calculator.png";
import StandardDeviationCalcIcon from "../Assets/CalculatorIcon/Standard-Deviation-Calculator.png";
import TdeeCalcIcon from "../Assets/CalculatorIcon/Tdee-Calculator.png";
import DueDateCalcIcon from "../Assets/CalculatorIcon/Due-Date-Calculator.png";
import ConcreteCalcIcon from "../Assets/CalculatorIcon/Concrete-Calculator.png";
import AmortizationCalcIcon from "../Assets/CalculatorIcon/Amortization-calculator.png";
import SalaryCalcIcon from "../Assets/CalculatorIcon/Salary-Calculator.png";
import CDCalcIcon from "../Assets/CalculatorIcon/CD-Calculator.png";
import RetirementCalcIcon from "../Assets/CalculatorIcon/Retirement-Calculator.png";
import MutualFundCalcIcon from "../Assets/CalculatorIcon/Mutual-fund-Calculator.png";
import RDCalcIcon from "../Assets/CalculatorIcon/RD-Calculator.png";
import HRACalcIcon from "../Assets/CalculatorIcon/HRA-Calculator.png";
import SukanyaSamridhiYojanaCalcIcon from "../Assets/CalculatorIcon/Sukanya-samridhi-yojana-Calculator.png";
import MacroCalcIcon from "../Assets/CalculatorIcon/Macro-Calculator.png";
import BodyFatCalcIcon from "../Assets/CalculatorIcon/Body-Fat-Calculator.png";
import PaceCalcIcon from "../Assets/CalculatorIcon/Pace-Calculator.png";
import WeightLossCalcIcon from "../Assets/CalculatorIcon/Weight-loss-Calculator.png";
import RandomNumberGeneratorCalcIcon from "../Assets/CalculatorIcon/Random-number-Calculator.png";
import IsoscelesTriangleCalcIcon from "../Assets/CalculatorIcon/Isosceles-Triangle-Calculator.png";
import SubnetCalcIcon from "../Assets/CalculatorIcon/Subnet-Calculator.png";
import PasswordGeneratorCalcIcon from "../Assets/CalculatorIcon/Password-Generator-Calculator.png";
import PoundsToDollarsCalcIcon from "../Assets/CalculatorIcon/Pounds-to-Dollars-Calculator.png";
import EurosToPoundsCalcIcon from "../Assets/CalculatorIcon/euros-to-pounds-Calculator.png";
import HouseAffordabilityCalc from "../Component/Pages/HouseAffordabilityCalc/HouseAffordabilityCalc";
import RentCalc from "../Component/Pages/RentCalc/RentCalc";
import RentalPropertyCalc from "../Component/Pages/RentalPropertyCalc/RentalPropertyCalc";
import APRCalc from "../Component/Pages/APRCalc/APRCalc";
import FHALoanCalc from "../Component/Pages/FHALoanCalc/FHALoanCalc";
import VAMortgageCalc from "../Component/Pages/VAMortgageCalc/VAMortgageCalc";
import DownPaymentCalc from "../Component/Pages/DownPaymentCalc/DownPaymentCalc";
import RentVsBuyCalc from "../Component/Pages/RentVsBuyCalc/RentVsBuyCalc";
import AutoLoanCalc from "../Component/Pages/AutoLoanCalc/AutoLoanCalc";
import AutoLeaseCalc from "../Component/Pages/AutoLeaseCalc/AutoLeaseCalc";
import SavingsCalc from "../Component/Pages/SavingsCalc/SavingsCalc";
import CashBackAndLowInterestCalc from "../Component/Pages/CashBackAndLowInterestCalc/CashBackAndLowInterestCalc";
import BondCalc from "../Component/Pages/BondCalc/BondCalc";
import AverageCalc from "../Component/Pages/AverageCalc/AverageCalc";

export const SEO = {
  HOME_PAGE_DESCRIPTION:
    "This page is show all type of calculators for making easy of your calculate procedure for any calculation",
  HOME_PAGE_KEYWORDS: "calc calculator homeloan HomeLoanCalc",
  HOME_PAGE_TITLE: "Calculator Times",

  //404
  ERR_PAGE_DESCRIPTION:
    "The page you're looking for cannot be found. Explore our wide range of calculators to simplify your calculations for any purpose.",
  ERR_PAGE_KEYWORDS: "calculators, home loan calculator, easy calculations",
  ERR_PAGE_TITLE: "Page Not Found - Calculator Times",

  //For Sip
  SIP_CALC_TITLE: "Sip Calculator",
  SIP_CALC_DESCRIPTION: "Sip Calculator",
  SIP_CALC_KEYWORDS: "Sip Calculator",

  //For Lumpsum Calculator
  LUMP_CALC_TITLE: "Lumpsum Calculator",
  LUMP_CALC_DESCRIPTION: "Lumpsum Calculator",
  LUMP_CALC_KEYWORDS: "Lumpsum Calculator",

  //For HomeLoanCalc
  HOME_LOAN_TITLE: "Home-Loan Calculator",
  HOME_LOAN_DESCRIPTION: "Home-Loan Calculator",
  HOME_LOAN_KEYWORDS: "Home-Loan Calculator",

  //For SimpleInterest
  SIMPLE_INTEREST_TITLE: "Simple Interest Calculator",
  SIMPLE_INTEREST_DESCRIPTION: "Simple Interest Calculator",
  SIMPLE_INTEREST_KEYWORDS: "Simple Interest Calculator",

  //For GST Calculator
  GST_CALC_TITLE: "GST Calculator",
  GST_CALC_DESCRIPTION: "GST Calculator",
  GST_CALC_KEYWORDS: "GST Calculator",

  //For LOVE Calculator
  LOVE_CALC_TITLE: "LOVE Calculator",
  LOVE_CALC_DESCRIPTION: "LOVE Calculator",
  LOVE_CALC_KEYWORDS: "LOVE Calculator",

  //For Age Calculator
  AGE_CALC_TITLE: "Age Calculator",
  AGE_CALC_DESCRIPTION: "Age Calculator",
  AGE_CALC_KEYWORDS: "Age Calculator",

  //For compound interest Calculator
  CIC_CALC_TITLE: "Compound interest Calculator",
  CIC_CALC_DESCRIPTION: "Compound interest Calculator",
  CIC_CALC_KEYWORDS: "Compound interest Calculator",

  //For percentage Calculator
  PERCENTAGE_CALC_TITLE: "Percentage Calculator",
  PERCENTAGE_CALC_DESCRIPTION: "Percentage Calculator",
  PERCENTAGE_CALC_KEYWORDS: "Percentage Calculator",

  //For FD Calculator
  FD_TITLE: "Fixed Deposits Calculator",
  FD_DESCRIPTION: "Fixed Deposits Calculator",
  FD_KEYWORDS: "Fixed Deposits Calculator",

  //For Income Tax Calculator
  IT_CALC_TITLE: "Income tax process Calculator",
  IT_CALC_DESCRIPTION: "Income tax process Calculator",
  IT_CALC_KEYWORDS: "Income tax process Calculator",

  //For BMI Calculator
  BMI_CALC_TITLE: "Body Mass Index (BMI) Calculator",
  BMI_CALC_DESCRIPTION: "Body Mass Index (BMI) Calculator",
  BMI_CALC_KEYWORDS: "Body Mass Index (BMI) Calculator",

  //For BMR Calculator
  BMR_CALC_TITLE: "Basal Metabolic Rate (BMR) Calculator",
  BMR_CALC_DESCRIPTION: "Basal Metabolic Rate (BMR) Calculator",
  BMR_CALC_KEYWORDS: "Basal Metabolic Rate (BMR) Calculator",

  //For Calorie Calculator
  CALORIE_CALC_TITLE: "Calorie Calculator",
  CALORIE_CALC_DESCRIPTION: "Calorie Calculator",
  CALORIE_CALC_KEYWORDS: "Calorie Calculator",

  //For Date Calculator
  DATE_CALC_TITLE: "Date Calculator",
  DATE_CALC_DESCRIPTION: "Date Calculator",
  DATE_CALC_KEYWORDS: "Date Calculator",

  //For Gratuity Calculator
  GRATUITY_CALC_TITLE: "Gratuity Calculator",
  GRATUITY_CALC_DESCRIPTION: "Gratuity Calculator",
  GRATUITY_CALC_KEYWORDS: "Gratuity Calculator",

  //For Tip Calculator
  TIP_CALC_TITLE: "Tip Calculator",
  TIP_CALC_DESCRIPTION: "Tip Calculator",
  TIP_CALC_KEYWORDS: "Tip Calculator",

  //For Time Calculator
  TIME_CALC_TITLE: "Time Calculator",
  TIME_CALC_DESCRIPTION: "Time Calculator",
  TIME_CALC_KEYWORDS: "Time Calculator",

  //For Pregnancy Calculator
  PREGNANCY_CALC_TITLE: "Pregnancy Calculator",
  PREGNANCY_CALC_DESCRIPTION: "Pregnancy Calculator",
  PREGNANCY_CALC_KEYWORDS: "Pregnancy Calculator",

  //For GPA Calculator
  GPA_CALC_TITLE: "GPA Calculator",
  GPA_CALC_DESCRIPTION: "GPA Calculator",
  GPA_CALC_KEYWORDS: "GPA Calculator",

  //For Inflation Calculator
  INFLATION_CALC_TITLE: "Inflation Calculator",
  INFLATION_CALC_DESCRIPTION: "Inflation Calculator",
  INFLATION_CALC_KEYWORDS: "Inflation Calculator",

  //For Ovulation Calculator
  OVULATION_CALC_TITLE: "Ovulation Calculator",
  OVULATION_CALC_DESCRIPTION: "Ovulation Calculator",
  OVULATION_CALC_KEYWORDS: "Ovulation Calculator",

  //For HomeLoanCalc
  MORTGAGE_LOAN_TITLE: "Mortgage-Loan Calculator",
  MORTGAGE_LOAN_DESCRIPTION: "Mortgage-Loan Calculator",
  MORTGAGE_LOAN_KEYWORDS: "Mortgage-Loan Calculator",

  //For fraction Calculator
  FRACTION_CALC_TITLE: "Fraction Calculator",
  FRACTION_CALC_DESCRIPTION: "Fraction Calculator",
  FRACTION_CALC_KEYWORDS: "Fraction Calculator",

  //For Days Calculator
  DAYS_CALC_TITLE: "Days Calculator",
  DAYS_CALC_DESCRIPTION: "Days Calculator",
  DAYS_CALC_KEYWORDS: "Days Calculator",

  //For Scientific Calculator
  SCIENTIFIC_CALC_TITLE: "Scientific Calculator",
  SCIENTIFIC_CALC_DESCRIPTION: "Scientific Calculator",
  SCIENTIFIC_CALC_KEYWORDS: "Scientific Calculator",

  //For Investment Calculator
  INVESTMENT_CALC_TITLE: "Investment Calculator",
  INVESTMENT_CALC_DESCRIPTION: "Investment Calculator",
  INVESTMENT_CALC_KEYWORDS: "Investment Calculator",

  //For Date of Birth Calculator
  DOB_CALC_TITLE: "Date of Birth Calculator",
  DOB_CALC_DESCRIPTION: "Date of Birth Calculator",
  DOB_CALC_KEYWORDS: "Date of Birth Calculator",

  //For Car Loan Calculator
  CAR_LOAN_TITLE: "Car-Loan Calculator",
  CAR_LOAN_DESCRIPTION: "Car-Loan Calculator",
  CAR_LOAN_KEYWORDS: "Car-Loan Calculator",

  //For Grade Calculator
  GRADE_CALC_TITLE: "Grade Calculator",
  GRADE_CALC_DESCRIPTION: "Grade Calculator",
  GRADE_CALC_KEYWORDS: "Grade Calculator",

  //For Loan Calculator
  LOAN_CALC_TITLE: "Loan Calculator",
  LOAN_CALC_DESCRIPTION: "Loan Calculator",
  LOAN_CALC_KEYWORDS: "Loan Calculator",

  //For Final Grade Calculator
  FINALGRADE_CALC_TITLE: "Final Grade Calculator",
  FINALGRADE_CALC_DESCRIPTION: "Final Grade Calculator",
  FINALGRADE_CALC_KEYWORDS: "Final Grade Calculator",

  //For pay check Calculator
  PAYCHECK_CALC_TITLE: "pay-check Calculator",
  PAYCHECK_CALC_DESCRIPTION: "pay-check Calculator",
  PAYCHECK_CALC_KEYWORDS: "pay-check Calculator",

  //For standard deviation Calculator
  SD_CALC_TITLE: "Standard Deviation Calculator",
  SD_CALC_DESCRIPTION: "Standard Deviation Calculator",
  SD_CALC_KEYWORDS: "Standard Deviation Calculator",

  //For tdee Calculator
  TDEE_CALC_TITLE: "Tdee Calculator",
  TDEE_CALC_DESCRIPTION: "Tdee Calculator",
  TDEE_CALC_KEYWORDS: "Tdee Calculator",

  //For due date Calculator
  DUEDATE_CALC_TITLE: "Due Date Calculator",
  DUEDATE_CALC_DESCRIPTION: "Due Date Calculator",
  DUEDATE_CALC_KEYWORDS: "Due Date Calculator",

  //For Concrete Calculator
  CONCRETE_CALC_TITLE: "Concrete Calculator",
  CONCRETE_CALC_DESCRIPTION: "Concrete Calculator",
  CONCRETE_CALC_KEYWORDS: "Concrete Calculator",

  //For Amortization Calculator
  AMORTIZATION_CALC_TITLE: "Amortization Calculator",
  AMORTIZATION_CALC_DESCRIPTION: "Amortization Calculator",
  AMORTIZATION_CALC_KEYWORDS: "Amortization Calculator",

  //For Salary Calculator
  SALARY_CALC_TITLE: "Salary Calculator",
  SALARY_CALC_DESCRIPTION: "Salary Calculator",
  SALARY_CALC_KEYWORDS: "Salary Calculator",

  //For CD Calculator
  CD_CALC_TITLE: "CD Calculator",
  CD_CALC_DESCRIPTION: "CD Calculator",
  CD_CALC_KEYWORDS: "CD Calculator",

  //For Retirement Calculator
  RETIREMENT_CALC_TITLE: "Retirement Calculator",
  RETIREMENT_CALC_DESCRIPTION: "Retirement Calculator",
  RETIREMENT_CALC_KEYWORDS: "Retirement Calculator",

  //mutual fund Calculator
  MUTUAL_FUND_CALC_TITLE: "Mutual Fund Calculator",
  MUTUAL_FUND_CALC_DESCRIPTION: "Mutual Fund Calculator",
  MUTUAL_FUND_CALC_KEYWORDS: "Mutual Fund Calculator",

  //For RD Calculator
  RD_CALC_TITLE: "RD Calculator",
  RD_CALC_DESCRIPTION: "RD Calculator",
  RD_CALC_KEYWORDS: "RD Calculator",

  //For HRA Calculator
  HRA_CALC_TITLE: "HRA Calculator",
  HRA_CALC_DESCRIPTION: "HRA Calculator",
  HRA_CALC_KEYWORDS: "HRA Calculator",

  //For Sukanya samridhi yojana Calculator
  SUKANYA_SAMRIDHI_YOJANA_CALC_TITLE: "Sukanya Samriddhi Yojana Calculator",
  SUKANYA_SAMRIDHI_YOJANA_CALC_DESCRIPTION:
    "Sukanya Samriddhi Yojana Calculator",
  SUKANYA_SAMRIDHI_YOJANA_CALC_KEYWORDS: "Sukanya Samriddhi Yojana Calculator",

  //For Limit Calculator
  LIMIT_CALC_TITLE: "Limit Calculator",
  LIMIT_CALC_DESCRIPTION: "Limit Calculator",
  LIMIT_CALC_KEYWORDS: "Limit Calculator",

  //For Macro Calculator
  MACRO_CALC_TITLE: "Macro Calculator",
  MACRO_CALC_DESCRIPTION: "Macro Calculator",
  MACRO_CALC_KEYWORDS: "Macro Calculator",

  //For Body Fat Calculator
  BODY_FAT_CALC_TITLE: "Body Fat Calculator",
  BODY_FAT_CALC_DESCRIPTION: "Body Fat Calculator",
  BODY_FAT_CALC_KEYWORDS: "Body Fat Calculator",

  //For Pace Calculator
  PACE_CALC_TITLE: "Pace Calculator",
  PACE_CALC_DESCRIPTION: "Pace Calculator",
  PACE_CALC_KEYWORDS: "Pace Calculator",

  //For Weight Loss Calculator
  WEIGHT_LOSS_CALC_TITLE: "Weight Loss Calculator",
  WEIGHT_LOSS_CALC_DESCRIPTION: "Weight Loss Calculator",
  WEIGHT_LOSS_CALC_KEYWORDS: "Weight Loss Calculator",

  //For Random Number Generator Calculator
  RANDOM_NUMBER_GENERATOR_CALC_TITLE: "Random Number Generator Calculator",
  RANDOM_NUMBER_GENERATOR_CALC_DESCRIPTION:
    "Random Number Generator Calculator",
  RANDOM_NUMBER_GENERATOR_CALC_KEYWORDS: "Random Number Generator Calculator",

  //For Isosceles Triangle Calculator
  ISOSCELES_TRIANGLE_CALC_TITLE: "Isosceles Triangle Calculator",
  ISOSCELES_TRIANGLE_CALC_DESCRIPTION: "Isosceles Triangle Calculator",
  ISOSCELES_TRIANGLE_CALC_KEYWORDS: "Isosceles Triangle Calculator",

  //For Subnet Calculator
  SUBNET_CALC_TITLE: "Subnet Calculator",
  SUBNET_CALC_DESCRIPTION: "Subnet Calculator",
  SUBNET_CALC_KEYWORDS: "Subnet Calculator",

  //For Password Generator Calculator
  PASSWORD_GENERATOR_CALC_TITLE: "Password Generator Calculator",
  PASSWORD_GENERATOR_CALC_DESCRIPTION: "Password Generator Calculator",
  PASSWORD_GENERATOR_CALC_KEYWORDS: "Password Generator Calculator",

  //For Pounds to Dollars Calculator
  POUNDS_TO_DOLLARS_CALC_TITLE: "Pounds to Dollars Calculator",
  POUNDS_TO_DOLLARS_CALC_DESCRIPTION: "Pounds to Dollars Calculator",
  POUNDS_TO_DOLLARS_CALC_KEYWORDS: "Pounds to Dollars Calculator",

  //For Euros to Pounds Calculator
  EUROS_TO_POUNDS_CALC_TITLE: "Euros to Pounds Calculator",
  EUROS_TO_POUNDS_CALC_DESCRIPTION: "Euros to Pounds Calculator",
  EUROS_TO_POUNDS_CALC_KEYWORDS: "Euros to Pounds Calculator",

  //ContactUS
  CONTACT_US_TITLE: "Contact Us",
  CONTACT_US_DESCRIPTION: "Get in touch with us",
  CONTACT_US_KEYWORDS: "Contact, Support, Help, Inquiries",

  //Privacy-policy
  PRIVACY_POLICY_TITLE: "Privacy Policy",
  PRIVACY_POLICY_DESCRIPTION: "Our commitment to your privacy",
  PRIVACY_POLICY_KEYWORDS:
    "Privacy Policy, Data Protection, Personal Information, Privacy",

  //terms of service
  TERMS_OF_SERVICE_TITLE: "Terms of Service",
  TERMS_OF_SERVICE_DESCRIPTION: "Terms and conditions for using our service",
  TERMS_OF_SERVICE_KEYWORDS:
    "Terms of Service, Terms and Conditions, User Agreement, Service Agreement",

  //disclaimer
  DISCLAIMER_TITLE: "Disclaimer",
  DISCLAIMER_DESCRIPTION: "Disclaimer regarding the use of our service",
  DISCLAIMER_KEYWORDS:
    "Disclaimer, Liability, Ownership, Accuracy, Endorsement",
};

export const CONSTANT = {
  CALCULATOR_TIMES_WEB_LINK: process.env.REACT_APP_SITEMAP_URL,
  CALCULATOR_TIMES: "Calculator Times",
};
export const COMPONENTDATA = [
  {
    title: "Age Calculator",
    component: <AgeCalc />,
    keywords:
      "age calculator online,chronological age calculator,age calculator by date of birth,age calculator by year,pearson age calculator,gestational age calculator",
    slug: "age-calculator",
    description:
      "Discover Your Age in Years, Months, Days, Weeks, Minutes, Hours, and Seconds with Our Age Calculator.",
    image: AgeCalcIcon,
    alt: "AgeCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "Date Calculator", slug: "date-calculator" },
      { title: "Time Calculator", slug: "time-calculator" },
    ],
  },
  {
    title: "EMI Calculator",
    component: <SimpleInterestCalc />,
    keywords:
      "home loan emi calculator,loan emi calculator,personal loan emi calculator,bike emi calculator,housing loan emi calculator",
    slug: "emi-calculator",
    description:
      "Plan Your Finances: Calculate Your Equated Monthly Installment (EMI) with Our EMI Calculator.",
    image: EmiCalcIcon,
    alt: "EmiCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Home Loan Calculator", slug: "home-loan-calculator" },
      { title: "Car Loan Calculator", slug: "car-loan-calculator" },
    ],
  },
  {
    title: "SIP Calculator",
    component: <SipCalc />,
    keywords:
      "step up sip calculator,sip calculator sbi,sbi sip calculator,mutual fund sip calculator,sip calculator online,sip calculator 40 years,hdfc sip calculator",
    slug: "sip-calculator",
    description:
      "Calculate how much you need to save or how much you will accumulate with your SIP.",
    image: SipCalcIcon,
    alt: "SipCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Mutual Fund Calculator", slug: "mutual-fund-calculator" },
      { title: "Lumpsum Calculator", slug: "lumpsum-calculator" },
    ],
  },
  {
    title: "Percentage Calculator",
    component: <PercentageCalc />,
    keywords:
      "cgpa to percentage calculator,marks percentage calculator,grade percentage calculator,percentage calculator marks,mark percentage calculator,sgpa to percentage calculator,average percentage calculator,increase percentage calculator",
    slug: "percentage-calculator",
    description:
      "Easily Calculate Percentages: Use Our Percentage Calculator Now.",
    image: PercentageCalcIcon,
    alt: "PercentageCalcIcon",
    category: "Math Calculators",
    similar: [
      { title: "Scientific Calculator", slug: "scientific-calculator" },
      { title: "Fraction Calculator", slug: "fraction-calculator" },
    ],
  },
  {
    title: "Home Loan Calculator",
    component: <HomeLoanCalc />,
    keywords:
      "hdfc home loan calculator,sbi home loan calculator,lic home loan calculator,home loan calculator emi,va home loan calculator",
    slug: "home-loan-calculator",
    description:
      "Calculate EMI on your loans - home loan, car loan or personal loan.",
    image: HomeLoneCalcIcon,
    alt: "HomeLoneCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Car Loan Calculator", slug: "car-loan-calculator" },
      { title: "Mortgage loan Calculator", slug: "mortgage-calculator" },
    ],
  },
  {
    title: "Love Calculator",
    component: <LoveCalc />,
    keywords:
      "true love calculator soulmate,love calculator by name,love calculator by date of birth,100 love calculator,true love calculator soulmate,real love calculator,love calculator by age,love calculator prank",
    slug: "love-calculator",
    description:
      "Uncover Your Love Compatibility: Find Your Match Percentage with Our Love Calculator.",
    image: LoveCalcIcon,
    alt: "LoveCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "Age Calculator", slug: "age-calculator" },
      { title: "Date Calculator", slug: "date-calculator" },
    ],
  },
  {
    title: "Compound Interest Calculator",
    component: <CompoundInterestCalc />,
    keywords:
      "daily compound interest calculator,cd compound interest calculator,monthly compound interest calculator,compound interest calculator india,continuous compound interest calculator,sip compound interest calculator",
    slug: "compound-interest-calculator",
    description:
      "Maximize Your Savings: Compute Compound Interest with Our Calculator.",
    image: CompoundInterestCalcIcon,
    alt: "CompoundInterestCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "FD Calculator", slug: "fd-calculator" },
      { title: "PPF Calculator", slug: "ppf-calculator" },
    ],
  },
  {
    title: "Income Tax Calculator",
    component: <IncomeTaxCalc />,
    keywords:
      "income tax calculator ay 2024-25,income tax calculator fy 2023-24,income tax calculator new regime,income tax calculator old vs new,2024 income tax calculator",
    slug: "income-tax-calculator",
    description:
      "Stay Financially Informed: Estimate Your Income Tax with Our Calculator.",
    image: IncomeTaxCalcIcon,
    alt: "IncomeTaxCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "GST Calculator", slug: "gst-calculator" },
      { title: "EMI Calculator", slug: "emi-calculator" },
    ],
  },
  {
    title: "FD Calculator",
    component: <FDCalc />,
    keywords:
      "hdfc fd calculator,sbi fd calculator,post office fd calculator,fd calculator hdfc,fd calculator 2024",
    slug: "fd-calculator",
    description:
      "Plan Your Investments: Calculate Returns on Fixed Deposits with Our FD Calculator.",
    image: FDCalcIcon,
    alt: "FDCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "CD Calculator", slug: "cd-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "PPF Calculator",
    component: <PpfCalc />,
    keywords:
      "ppf calculator sbi,post office ppf calculator,ppf calculator post office,sbi ppf calculator,ppf calculator india",
    slug: "ppf-calculator",
    description:
      "Secure Your Future: Explore Potential Returns with Our PPF Calculator.",
    image: PpfCalcIcon,
    alt: "PpfCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Gratuity Calculator", slug: "gratuity-calculator" },
      { title: "Retirement Calculator", slug: "retirement-calculator" },
    ],
  },
  {
    title: "GST Calculator",
    component: <GstCalc />,
    keywords:
      "gst calculator,gst calculator online,gst calculator india,easy gst calculator,online gst calculator,indian gst calculator,reverse gst calculator",
    slug: "gst-calculator",
    description: "Calculate your payable GST amount with a few clicks.",
    image: GstCalcIcon,
    alt: "GstCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "EMI Calculator", slug: "emi-calculator" },
      { title: "Income Tax Calculator", slug: "income-tax-calculator" },
    ],
  },
  {
    title: "Lumpsum Calculator",
    component: <LumpsumCalc />,
    keywords:
      "Lumpsum Investment Calculator,Mutual Fund Lumpsum Calculator,SIP vs Lumpsum Calculator,Lumpsum Return Calculator,Lumpsum SIP Calculator,Lumpsum vs SIP Comparison,Lumpsum Calculator for Mutual Funds,Investment Growth Calculator,Lumpsum Calculator Online,Financial Planning Calculator,Retirement Lumpsum Calculator,Lumpsum to SIP Conversion,Future Value Calculator,Lumpsum Investment Return,Compound Interest Calculator",
    slug: "lumpsum-calculator",
    description:
      "Calculate your lump sum savings or potential accumulation in seconds!",
    image: LumpsumCalcIcon,
    alt: "LumpsumCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Investment Calculator", slug: "investment-calculator" },
      { title: "SIP Calculator", slug: "sip-calculator" },
    ],
  },
  {
    title: "BMI Calculator",
    component: <BMICalc />,
    keywords:
      "bmi calculator women,bmi calculator by age,bmi calculator men,bmi calculator kg,bmi calculator for women,bmi calculator male",
    slug: "bmi-calculator",
    description: "Calculate your BMI with ease!",
    image: BMICalcIcon,
    alt: "BMICalcIcon",
    category: "Fitness & Health Calculators",
    similar: [
      { title: "BMR Calculator", slug: "bmr-calculator" },
      { title: "Calorie Calculator", slug: "calorie-calculator" },
    ],
  },
  {
    title: "Date Calculator",
    component: <DateCalc />,
    keywords:
      "due date calculator,birth date calculator,conception date calculator,date calculator between two dates,date calculator add days,due date calculator",
    slug: "date-calculator",
    description:
      "Find the number of years, months, weeks, and days between dates.",
    image: DateCalcIcon,
    alt: "DateCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "Time Calculator", slug: "time-calculator" },
      { title: "Days Calculator", slug: "days-calculator" },
    ],
  },
  {
    title: "Gratuity Calculator",
    component: <GratuityCalc />,
    keywords:
      "gratuity calculator for private employees,gratuity calculator india,gratuity calculator online,gratuity calculator formula,gratuity calculator in india,online gratuity calculator",
    slug: "gratuity-calculator",
    description:
      "Easily calculate your gratuity with our online Gratuity Calculator.",
    image: GratuityCalcIcon,
    alt: "GratuityCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "PPF Calculator", slug: "ppf-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "BMR Calculator",
    component: <BMRCalc />,
    keywords:
      "bmr calculator to lose weight,bmr calculator for women,bmr calculator tdee,bmr calculator tdee,bmr calculator female",
    slug: "bmr-calculator",
    description:
      "This BMR calculator estimates basal metabolic rate based on well-known formulas.",
    image: BMRCalcIcon,
    alt: "BMRCalcIcon",
    category: "Fitness & Health Calculators",
    similar: [
      { title: "BMI Calculator", slug: "bmi-calculator" },
      { title: "Calorie Calculator", slug: "calorie-calculator" },
    ],
  },
  {
    title: "Calorie Calculator",
    component: <CalorieCalc />,
    keywords:
      "food calorie calculator,maintenance calorie calculator,calorie calculator to lose weight,chipotle calorie calculator,recipe calorie calculator,calorie calculator to gain weight,weight loss calorie calculator,walking calorie calculator,indian food calorie calculator",
    slug: "calorie-calculator",
    description:
      "This calorie calculator estimates the number of calories needed each day to maintain, lose, or gain weight.",
    image: CalorieCalcIcon,
    alt: "CalorieCalcIcon",
    category: "Fitness & Health Calculators",
    similar: [
      { title: "BMI Calculator", slug: "bmi-calculator" },
      { title: "BMR Calculator", slug: "bmr-calculator" },
    ],
  },
  {
    title: "Pregnancy Calculator",
    component: <PregnancyCalc />,
    keywords:
      "pregnancy calculator week by week,ivf pregnancy calculator,due date pregnancy calculator,pregnancy calculator due date,pregnancy calculator weekly,pregnancy calculator due date",
    slug: "pregnancy-calculator",
    description:
      "The pregnancy calculator provides an estimated pregnancy schedule based on the due date, last period date, or IVF transfer date.",
    image: PregnancyCalcIcon,
    alt: "PregnancyCalcIcon",
    category: "Fitness & Health Calculators",
    similar: [
      { title: "Due Date Calculator", slug: "due-date-calculator" },
      { title: "Ovulation Calculator", slug: "ovulation-calculator" },
    ],
  },
  {
    title: "Tip Calculator",
    component: <TipCalc />,
    keywords:
      "tip calculator app,tip calculator iphone,restaurant tip calculator,tattoo tip calculator,tip calculator google",
    slug: "tip-calculator",
    description:
      "A tool that calculates the appropriate tip amount based on a specified percentage of the total bill.",
    image: TipCalcIcon,
    alt: "TipCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "Time Calculator", slug: "time-calculator" },
      { title: "Date Calculator", slug: "date-calculator" },
    ],
  },
  {
    title: "Time Calculator",
    component: <TimeCalc />,
    keywords:
      "work time calculator,date and time calculator,usps delivery time calculator,time calculator hours,time calculator work,time calculator online,elapsed time calculator,time calculator between two times",
    slug: "time-calculator",
    description:
      "A tool that calculates the duration or difference between two specified times or dates.",
    image: TimeCalcIcon,
    alt: "TimeCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "Date Calculator", slug: "date-calculator" },
      { title: "Age Calculator", slug: "age-calculator" },
    ],
  },
  {
    title: "GPA Calculator",
    component: <GPACalc />,
    keywords:
      "college gpa calculator,gpa calculator college,high school gpa calculator,cumulative gpa calculator,uf gpa calculator,percentage to gpa calculator,cgpa to gpa calculator",
    slug: "gpa-calculator",
    description:
      "Calculate your college GPA quickly with our easy to use College GPA Calculator.",
    image: GPACalcIcon,
    alt: "GPACalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "Grade Calculator", slug: "grade-calculator" },
      { title: "Percentage Calculator", slug: "percentage-calculator" },
    ],
  },
  {
    title: "Inflation Calculator",
    component: <InflationCalc />,
    keywords:
      "cpi inflation calculator,inflation calculator by year,inflation calculator usd,us inflation calculator,future inflation calculator,bls inflation calculator,salary inflation calculator,money inflation calculator,usd inflation calculator",
    slug: "inflation-calculator",
    description:
      "Find out how much you will need in the future to meet your current expenses whilst keeping up with inflation.",
    image: InflationCalcIcon,
    alt: "InflationCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "EMI Calculator", slug: "emi-calculator" },
      { title: "Loan Calculator", slug: "loan-calculator" },
    ],
  },
  {
    title: "Ovulation Calculator",
    component: <OvulationCalc />,
    keywords:
      "free ovulation calculator,perfect ovulation calculator,accurate ovulation calculator,ovulation calculator for irregular periods,ovulation calculator boy,ovulation calculator for boy twins,ovulation calculator boy or girl",
    slug: "ovulation-calculator",
    description:
      "Ovulation calculator provides estimates of a person's possible ovulation windows and corresponding due dates over six menstrual cycles.",
    image: OvulationCalcIcon,
    alt: "OvulationCalcIcon",
    category: "Fitness & Health Calculators",
    similar: [
      { title: "Pregnancy Calculator", slug: "pregnancy-calculator" },
      { title: "Due Date Calculator", slug: "due-date-calculator" },
    ],
  },
  {
    title: "Days Calculator",
    component: <DaysCalc />,
    keywords:
      "how many days calculator,number of days calculator,no of days calculator,days calculator between two dates,days calculator online,safe days calculator,calendar days calculator,hours to days calculator,working days calculator",
    slug: "days-calculator",
    description:
      "A tool to quickly compute the number of days between two dates.",
    image: DaysCalcIcon,
    alt: "DaysCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "Age Calculator", slug: "age-calculator" },
      { title: "Date Calculator", slug: "date-calculator" },
    ],
  },
  {
    title: "Scientific Calculator",
    component: <ScientificCalculator />,
    keywords:
      "online scientific calculator,scientific calculator with fraction,best scientific calculator,non programmable scientific calculator,scientific calculator online",
    slug: "scientific-calculator",
    description:
      "A scientific calculator is a versatile device designed to perform complex mathematical, engineering, and scientific calculations.",
    image: ScientificCalIcon,
    alt: "ScientificCalIcon",
    category: "Math Calculators",
    similar: [
      { title: "Percentage Calculator", slug: "percentage-calculator" },
      {
        title: "Standard Deviation Calculator",
        slug: "standard-deviation-calculator",
      },
    ],
  },
  {
    title: "Investment Calculator",
    component: <InvestmentCalc />,
    keywords:
      "return on investment calculator,stock investment calculator,investment calculator over time,retirement investment calculator,compound investment calculator,sip investment calculator,mutual fund investment calculator,lump sum investment calculator,gold investment calculator,bankrate investment calculator",
    slug: "investment-calculator",
    description:
      "An investment calculator estimates returns based on input parameters such as initial investment, interest rate, and time.",
    image: InvestmentCalcIcon,
    alt: "InvestmentCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "SIP Calculator", slug: "sip-calculator" },
      { title: "Lumpsum Calculator", slug: "lumpsum-calculator" },
    ],
  },
  {
    title: "Date of Birth Calculator",
    component: <DateOfBirthCalc />,
    keywords:
      "date of birth calculator by date of birth,date of birth calculator year,date of birth calculator astrology,date of birth calculator india,online date of birth calculator",
    slug: "date-of-birth-calculator",
    description:
      "A date of birth calculator calculates a person's age based on their birth date and a reference date.",
    image: DateOfBirthCalcIcon,
    alt: "DateOfBirthCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "Love Calculator", slug: "love-calculator" },
      { title: "Age Calculator", slug: "age-calculator" },
    ],
  },
  {
    title: "Car Loan Calculator",
    component: <CarLoanCalc />,
    keywords:
      "used car loan calculator,refinance car loan calculator,new car loan calculator,emi car loan calculator,auto car loan calculator,best car loan calculator",
    slug: "car-loan-calculator",
    description:
      "A car loan calculator estimates monthly payments and total interest based on loan amount, interest rate, and term length.",
    image: CarLoanCalcIcon,
    alt: "CarLoanCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Home Loan Calculator", slug: "home-loan-calculator" },
      { title: "EMI Calculator", slug: "emi-calculator" },
    ],
  },
  {
    title: "Grade Calculator",
    component: <GradeCalc />,
    keywords:
      "final grade calculator,weighted grade calculator,test grade calculator,grade calculator final,grade calculator final,grade calculator final,german grade calculator",
    slug: "grade-calculator",
    description:
      "A grade calculator typically refers to a tool used to calculate and manage academic grades and overall performance in courses or subjects.",
    image: GradeCalcIcon,
    alt: "GradeCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "GPA Calculator", slug: "gpa-calculator" },
      { title: "Scientific Calculator", slug: "scientific-calculator" },
    ],
  },
  {
    title: "Loan Calculator",
    component: <LoanCalc />,
    keywords:
      "used car loan calculator,refinance car loan calculator,new car loan calculator,emi car loan calculator,auto car loan calculator,best car loan calculator",
    slug: "loan-calculator",
    description:
      "A loan calculator Calculates monthly loan payments based on principal amount, interest rate, and loan term.",
    image: LoanCalcIcon,
    alt: "LoanCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Home Loan Calculator", slug: "home-loan-calculator" },
      {
        title: "Car Loan Calculator",
        slug: "car-loan-calculator",
      },
    ],
  },
  {
    title: "Final Grade Calculator",
    component: <FinalGradeCalc />,
    keywords:
      "rogerhub final grade calculator,final grade calculator weighted,final grade calculator points,final grade calculator with exam,final grade calculator college,my final grade calculator",
    slug: "final-grade-calculator",
    description:
      "A Final Grade Calculator is tool to calculate final grades based on weighted scores and desired outcomes.",
    image: FinalGradeCalcIcon,
    alt: "FinalGradeCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "Grade Calculator", slug: "grade-calculator" },
      { title: "GPA Calculator", slug: "gpa-calculator" },
    ],
  },
  {
    title: "PayCheck Calculator",
    component: <PayCheckCalc />,
    keywords:
      "adp paycheck calculator,texas paycheck calculator,california paycheck calculator,hourly paycheck calculator,florida paycheck calculator,paycheck calculator texas,paycheck calculator california,paycheck calculator florida,salary paycheck calculator",
    slug: "pay-check-calculator",
    description:
      "A payCheck Calculator is tool for calculating net pay from gross wages after accounting for taxes and deductions.",
    image: PayCheckCalcIcon,
    alt: "PayCheckCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Amortization Calculator", slug: "amortization-calc" },
      { title: "Compound Interest", slug: "compound-interest-calculator" },
    ],
  },
  {
    title: "Mortgage Calculator",
    component: <MortgageLoanCalc />,
    keywords:
      "simple mortgage calculator,bankrate mortgage calculator,home mortgage calculator,zillow mortgage calculator,free mortgage calculator,reverse mortgage calculator,monthly mortgage calculator,mortgage calculator with taxes",
    slug: "mortgage-calculator",
    description:
      "Estimate your loan against property EMI with our mortgage loan calculator. Calculate mortgage loan EMI!",
    image: MortgageLoanCalcIcon,
    alt: "MortgageLoanCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Home Loan Calculator", slug: "home-loan-calculator" },
      { title: "Car Loan Calculator", slug: "car-loan-calculator" },
    ],
  },
  {
    title: "Fraction Calculator",
    component: <FractionCalc />,
    keywords:
      "mixed fraction calculator,decimal to fraction calculator,partial fraction calculator,improper fraction calculator,simplify fraction calculator,fraction calculator with whole numbers,reduce fraction calculator,3 fraction calculator,equivalent fraction calculator",
    slug: "fraction-calculator",
    description:
      "This free fraction calculator supports fraction addition, subtraction, multiplication, division, and conversion.",
    image: FractionCalcIcon,
    alt: "FractionCalcIcon",
    category: "Math Calculators",
    similar: [
      {
        title: "Standard Deviation Calculator",
        slug: "standard-deviation-calculator",
      },
      { title: "Percentage Calculator", slug: "percentage-calculator" },
    ],
  },
  {
    title: "Standard Deviation Calculator",
    component: <StandardDeviationCalc />,
    keywords:
      "sample standard deviation calculator,mean and standard deviation calculator,population standard deviation calculator,standard deviation calculator using mean,find standard deviation calculator,how to find standard deviation calculator,mean standard deviation calculator",
    slug: "standard-deviation-calculator",
    description:
      "A standard deviation calculator determines the spread or dispersion of a dataset from its mean.",
    image: StandardDeviationCalcIcon,
    alt: "StandardDeviationCalcIcon",
    category: "Math Calculators",
    similar: [
      { title: "Scientific Calculator", slug: "scientific-calculator" },
      { title: "Fraction Calculator", slug: "fraction-calculator" },
    ],
  },
  {
    title: "Tdee Calculator",
    component: <TdeeCalc />,
    keywords:
      "tdee calculator free,tdee calculator to lose weight,tdee calculator weight loss,tdee calculator for weight loss,best tdee calculator,tdee calculator accurate",
    slug: "tdee-calculator",
    description:
      "A TDEE calculator estimates daily calorie needs based on activity level and metabolic rate.",
    image: TdeeCalcIcon,
    alt: "TdeeCalcIcon",
    category: "Fitness & Health Calculators",
    similar: [
      { title: "BMI Calculator", slug: "bmi-calculator" },
      { title: "BMR Calculator", slug: "bmr-calculator" },
    ],
  },
  {
    title: "Due Date Calculator",
    component: <DueDateCalc />,
    keywords:
      "due date calculator,birth date calculator,conception date calculator,date calculator between two dates,date calculator add days,due date calculator",
    slug: "due-date-calculator",
    description:
      "A due date calculator estimates the expected date of delivery based on the first day of a woman's last menstrual period.",
    image: DueDateCalcIcon,
    alt: "DueDateCalcIcon",
    category: "Fitness & Health Calculators",
    similar: [
      { title: "Pregnancy Calculator", slug: "pregnancy-calculator" },
      { title: "Ovulation Calculator", slug: "ovulation-calculator" },
    ],
  },
  {
    title: "Take Home Salary Calculator",
    component: <TakeSalaryHomeCalc />,
    keywords:
      "Take Home Salary Calculator,Net Salary Calculator,After Tax Salary Calculator,Paycheck Calculator,Income Tax Calculator,Calculate Take Home Pay,Salary Calculator After Tax,Net Income Calculator,Gross to Net Salary Calculator,Employee Salary Calculator",
    slug: "take-home-salary-calculator",
    description:
      "Take-home salary calculator for India: Compute net salary after deductions for taxes, EPF, and other contributions.",
    image: PayCheckCalcIcon,
    alt: "PayCheckCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Income Tax Calculator", slug: "income-tax-calculator" },
      { title: "Amortization Calculator", slug: "amortization-calc" },
    ],
  },
  {
    title: "Concrete Calculator",
    component: <ConcreteCalc />,
    keywords:
      "concrete calculator bags,concrete calculator yards,sakrete concrete calculator,quikrete concrete calculator,concrete calculator formula,concrete calculator slab,concrete calculator cost",
    slug: "concrete-calculator",
    description:
      "A Concrete Calculator is tool for estimating concrete quantities needed based on dimensions and specifications.",
    image: ConcreteCalcIcon,
    alt: "ConcreteCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "Date Calculator", slug: "date-calculator" },
      { title: "Days Calculator", slug: "days-calculator" },
    ],
  },
  {
    title: "Amortization Calculator",
    component: <AmortizationCalc />,
    keywords:
      "loan amortization calculator,mortgage amortization calculator,amortization calculator mortgage,auto loan amortization calculator,car amortization calculator,car loan amortization calculator,amortization calculator with extra payments,personal loan amortization calculator",
    slug: "amortization-calculator",
    description:
      "An amortization calculator calculates periodic loan payments, breaking down principal and interest over time.",
    image: AmortizationCalcIcon,
    alt: "AmortizationCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "EMI Calculator", slug: "emi-calculator" },
      { title: "SIP Calculator", slug: "sip-calculator" },
    ],
  },
  {
    title: "Salary Calculator",
    component: <SalaryCalc />,
    keywords:
      "hourly to salary calculator,adp salary calculator,annual salary calculator,hourly salary calculator,yearly salary calculator,in hand salary calculator,salary calculator california,monthly salary calculator",
    slug: "salary-calculator",
    description:
      "A Salary Calculator is tool for estimating net income based on gross salary and tax deductions.",
    image: SalaryCalcIcon,
    alt: "SalaryCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "EMI Calculator", slug: "emi-calculator" },
      { title: "Home Loan Calculator", slug: "home-loan-calculator" },
    ],
  },
  {
    title: "CD Calculator",
    component: <CDCalc />,
    keywords:
      "bankrate cd calculator,normal cd calculator,cd calculator apy,cd calculator compounded monthly,cd calculator bankrate,cd calculator simple,cd calculator interest,cd calculator rate,bank cd calculator",
    slug: "cd-calculator",
    description:
      "A certificate of deposit calculator determines the future value of a CD investment based on initial deposit, interest rate, and term length.",
    image: CDCalcIcon,
    alt: "CDCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "FD Calculator", slug: "fd-calculator" },
      { title: "PPF Calculator", slug: "ppf-calculator" },
    ],
  },
  {
    title: "Retirement Calculator",
    component: <RetirementCalc />,
    keywords:
      "best retirement calculator,date of retirement calculator,early retirement calculator,retirement calculator with inflation,military retirement calculator,fers retirement calculator,retirement calculator for couple,retirement calculator 401k",
    slug: "retirement-calculator",
    description:
      "A retirement calculator estimates future financial needs based on factors like age, income, savings, and investment growth.",
    image: RetirementCalcIcon,
    alt: "RetirementCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "PPF Calculator", slug: "ppf-calculator" },
      { title: "SIP Calculator", slug: "sip-calculator" },
    ],
  },
  {
    title: "Mutual Fund Calculator",
    component: <MutualFundCalc />,
    keywords:
      "sip mutual fund calculator,sbi mutual fund calculator,mutual fund calculator sip,mutual fund calculator lumpsum,hdfc mutual fund calculator,lumpsum mutual fund calculator,monthly mutual fund calculator",
    slug: "mutual-fund-calculator",
    description:
      "A mutual fund calculator estimates the future value of investments based on inputs like initial amount, interest rate, and investment duration.",
    image: MutualFundCalcIcon,
    alt: "MutualFundCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "Investment Calculator", slug: "investment-calculator" },
      { title: "SIP Calculator", slug: "sip-calculator" },
    ],
  },
  {
    title: "RD Calculator",
    component: <RDCalc />,
    keywords:
      "post office rd calculator,rd calculator post office,hdfc rd calculator,sbi rd calculator,postal rd calculator,axis bank rd calculator",
    slug: "rd-calculator",
    description:
      "A RD calculator computes the future value of regular, fixed deposits made over a specified period, considering the interest rate.",
    image: RDCalcIcon,
    alt: "RDCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "CD Calculator", slug: "cd-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "HRA Calculator",
    component: <HRACalc />,
    keywords:
      "hra calculator income tax,hra calculator online,income tax hra calculator,icici hra calculator,hra calculator india,online hra calculator",
    slug: "hra-calculator",
    description:
      "A HRA calculator estimates monthly take-home pay after deductions like taxes and insurance.",
    image: HRACalcIcon,
    alt: "HRACalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "CD Calculator", slug: "cd-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Sukanya Samriddhi Yojana Calculator",
    component: <SukanyaSamridhiYojanaCalc />,
    keywords:
      "sukanya samridhi yojana,sukanya samridhi yojna,sukanya samridhi,sukanya samridhi calculator,sukanya samridhi scheme",
    slug: "sukanya-samriddhi-yojana-calculator",
    description:
      "Estimate returns and maturity values for investments in the government-backed savings scheme for girls.",
    image: SukanyaSamridhiYojanaCalcIcon,
    alt: "SukanyaSamridhiYojanaCalcIcon",
    category: "Financial Calculators",
    similar: [
      { title: "CD Calculator", slug: "cd-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },

  {
    title: "Macro Calculator",
    component: <MacroCalc />,
    keywords:
      "macro calculator for weight loss,macro calculator for fat loss,free macro calculator,macro calculator free,best macro calculator,keto macro calculator",
    slug: "macro-calculator",
    description:
      "A macro calculator helps you determine the ideal balance of macro nutrients based on your specific health and fitness goals.",
    image: MacroCalcIcon,
    alt: "MacroCalcIcon",
    category: "Fitness & Health Calculators",
    similar: [
      { title: "CD Calculator", slug: "cd-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Body Fat Calculator",
    component: <BodyFatCalc />,
    keywords:
      "army body fat calculator,navy body fat calculator,new army body fat calculator,body fat calculator for men,us navy body fat calculator",
    slug: "body-fat-calculator",
    description:
      "A body fat calculator estimates the percentage of body fat based on various measurements and personal data.",
    image: BodyFatCalcIcon,
    alt: "BodyFatCalcIcon",
    category: "Fitness & Health Calculators",
    similar: [
      { title: "BMI Calculator", slug: "bmi-calculator" },
      { title: "Calorie Calculator", slug: "calorie-calculator" },
    ],
  },
  {
    title: "Pace Calculator",
    component: <PaceCalculator />,
    keywords:
      "running pace calculator,marathon pace calculator,race pace calculator,mile pace calculator,run pace calculator,pace calculator running,pace calculator running,half marathon pace calculator,5k pace calculator",
    slug: "pace-calculator",
    description:
      "A pace calculator is a tool used to determine the time it takes to cover a specific distance at a given speed.",
    image: PaceCalcIcon,
    alt: "PaceCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "CD Calculator", slug: "cd-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Weight Loss Calculator",
    component: <WeightLossCalc />,
    keywords:
      "weight loss calculator by date,calorie weight loss calculator,weight loss calculator date,protein weight loss calculator,walking weight loss calculator,exercise weight loss calculator",
    slug: "weight-loss-calculator",
    description:
      "A Weight Loss Calculator estimates the daily calorie intake needed to achieve a specific weight loss goal within a set timeframe.",
    image: WeightLossCalcIcon,
    alt: "WeightLossCalcIcon",
    category: "Fitness & Health Calculators",
    similar: [
      { title: "Calorie Calculator", slug: "calorie-calculator" },
      { title: "BMI Calculator", slug: "bmi-calculator" },
    ],
  },
  {
    title: "Random Number Generator Calculator",
    component: <RandomNumberGeneratorCalc />,
    keywords:
      "random number generator 1-10,random number generator wheel,6 digit random number generator,random number generator 1-100,google random number generator,random number generator no repeats,random number generator python,random number generator excel,random number generator with names",
    slug: "random-number-generator-calculator",
    description:
      "Generate a random number within a specified range using the Random Number Generator Calculator.",
    image: RandomNumberGeneratorCalcIcon,
    alt: "RandomNumberGeneratorCalcIcon",
    category: "Math Calculators",
    similar: [
      { title: "CD Calculator", slug: "cd-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Isosceles Triangle Calculator",
    component: <IsoscelesTriangleCalc />,
    keywords:
      "right isosceles triangle calculator,isosceles triangle calculator angle,area of isosceles triangle calculator,isosceles triangle calculator sides",
    slug: "isosceles-triangle-calculator",
    description: "Calculates properties of an isosceles triangle.",
    image: IsoscelesTriangleCalcIcon,
    alt: "IsoscelesTriangleCalcIcon",
    category: "Math Calculators",
    similar: [
      { title: "CD Calculator", slug: "cd-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Subnet Calculator",
    component: <SubnetCalc />,
    keywords:
      "ip subnet calculator,ipv6 subnet calculator,online subnet calculator,subnet calculator cidr,visual subnet calculator",
    slug: "subnet-calculator",
    description:
      "A subnet calculator helps determine subnet mask, network, and host ranges for IP address segmentation.",
    image: SubnetCalcIcon,
    alt: "SubnetCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "CD Calculator", slug: "cd-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Password Generator Calculator",
    component: <PasswordGeneratorCalc />,
    keywords:
      "random password generator,strong password generator,password generator free,lastpass password generator,secure password generator,strong random password generator,online password generator,password generator words",
    slug: "password-generator-calculator",
    description:
      "Password Generator Calculator is a tool that creates secure passwords based on specified criteria.",
    image: PasswordGeneratorCalcIcon,
    alt: "PasswordGeneratorCalcIcon",
    category: "Other Calculators",
    similar: [
      { title: "CD Calculator", slug: "cd-calculator" },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Pounds to Dollars Calculator",
    component: <PoundsToDollarsCalc />,
    keywords:
      "convert pounds to dollars,100 pounds to dollars,pounds to dollars conversion,50 pounds to dollars,1000 pounds to dollars",
    slug: "pounds-to-dollars-calculator",
    description:
      "A Pounds to Dollars Calculator is a Convert GBP to USD quickly and accurately.",
    image: PoundsToDollarsCalcIcon,
    alt: "PoundsToDollarsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Euros to Pounds Calculator",
        slug: "euros-to-pounds-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Euros to Pounds Calculator",
    component: <EurosToPoundsCalc />,
    keywords:
      "convert euros to pounds calculator,100 euros to pounds,euros to pounds currency calculator",
    slug: "euros-to-pounds-calculator",
    description:
      "A Euros to Pounds calculator is a quickly convert Euros to Pounds with this simple calculator.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },

  //September 2024

  {
    title: "House Affordability Calculator",
    component: <HouseAffordabilityCalc />,
    keywords:
      "home affordability, mortgage affordability, house budget calculator, how much house can I afford, house price calculator",
    slug: "house-affordability-calculator",
    description:
      "Determine how much house you can afford based on your income, down payment, and other financial factors.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Rent Calculator",
    component: <RentCalc />,
    keywords:
      "rent affordability, rent calculator, how much rent can I afford, rental budget calculator, rent vs income",
    slug: "rent-calculator",
    description:
      "Calculate your affordable rent based on your income, expenses, and savings goals.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Rental Property Calculator",
    component: <RentalPropertyCalc />,
    keywords:
      "rental property ROI, rental income calculator, property investment calculator, real estate investment",
    slug: "rental-property-calculator",
    description:
      "Analyze the profitability of a rental property by calculating ROI, expenses, and potential income.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "APR Calculator",
    component: <APRCalc />,
    keywords:
      "APR calculation, annual percentage rate calculator, loan APR, credit card APR",
    slug: "apr-calculator",
    description:
      "Calculate the true cost of borrowing by determining the annual percentage rate (APR) on loans or credit cards.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "FHA Loan Calculator",
    component: <FHALoanCalc />,
    keywords:
      "FHA mortgage calculator, FHA loan eligibility, FHA loan payment, FHA down payment",
    slug: "fha-loan-calculator",
    description:
      "Estimate your FHA loan payments, including mortgage insurance and other factors.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  // {
  //   title: "VA Mortgage Calculator",
  //   component: <VAMortgageCalc />,
  //   keywords:
  //     "VA loan calculator, VA mortgage, veterans mortgage calculator, VA home loan payments",
  //   slug: "va-mortgage-calculator",
  //   description:
  //     "Calculate your VA mortgage payments, factoring in VA funding fees and eligibility requirements.",
  //   image: EurosToPoundsCalcIcon,
  //   alt: "EurosToPoundsCalcIcon",
  //   category: "Financial Calculators",
  //   similar: [
  //     {
  //       title: "Pounds to Dollars Calculator",
  //       slug: "pounds-to-dollars-calculator",
  //     },
  //     { title: "Investment Calculator", slug: "investment-calculator" },
  //   ],
  // },
  {
    title: "Down Payment Calculator",
    component: <DownPaymentCalc />,
    keywords:
      "down payment calculation, house down payment, mortgage down payment, home buying",
    slug: "down-payment-calculator",
    description:
      "Estimate the down payment needed for your desired home purchase price and loan type.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  // {
  //   title: "Rent vs Buy Calculator",
  //   component: <RentVsBuyCalc />,
  //   keywords:
  //     "rent vs buy comparison, should I rent or buy, homeownership calculator, rent vs mortgage",
  //   slug: "rent-vs-buy-calculator",
  //   description:
  //     "Compare the financial benefits of renting versus buying a home to make the best decision for your situation.",
  //   image: EurosToPoundsCalcIcon,
  //   alt: "EurosToPoundsCalcIcon",
  //   category: "Financial Calculators",
  //   similar: [
  //     {
  //       title: "Pounds to Dollars Calculator",
  //       slug: "pounds-to-dollars-calculator",
  //     },
  //     { title: "Investment Calculator", slug: "investment-calculator" },
  //   ],
  // },
  // {
  //   title: "Auto Loan Calculator",
  //   component: <AutoLoanCalc />,
  //   keywords:
  //     "car loan calculator, auto finance calculator, vehicle payment calculator, car loan interest",
  //   slug: "auto-loan-calculator",
  //   description:
  //     "Calculate your monthly car loan payments, including interest rates and loan terms.",
  //   image: EurosToPoundsCalcIcon,
  //   alt: "EurosToPoundsCalcIcon",
  //   category: "Financial Calculators",
  //   similar: [
  //     {
  //       title: "Pounds to Dollars Calculator",
  //       slug: "pounds-to-dollars-calculator",
  //     },
  //     { title: "Investment Calculator", slug: "investment-calculator" },
  //   ],
  // },
  {
    title: "Auto Lease Calculator",
    component: <AutoLeaseCalc />,
    keywords:
      "car lease calculator, auto leasing, lease vs buy, vehicle lease payments",
    slug: "auto-lease-calculator",
    description:
      "Estimate your monthly car lease payments and compare leasing to buying.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Savings Calculator",
    component: <SavingsCalc />,
    keywords:
      "savings goal calculator, savings interest, future value calculator, savings growth",
    slug: "savings-calculator",
    description:
      "Project how much your savings will grow over time based on contributions and interest rates.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Cash Back and Low Interest Calculator",
    component: <CashBackAndLowInterestCalc />,
    keywords:
      "credit card cash back calculator, low interest credit cards, credit card comparison",
    slug: "cash-back-and-low-interest-calculator",
    description:
      "Compare credit card offers to see which provides the best cash back or lowest interest for your spending habits.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },

 
  {
    title: "Bond Calculator",
    component: <BondCalc />,
    keywords:
      "bond yield calculator, bond investment, bond price, bond interest calculator",
    slug: "bond-calculator",
    description:
      "Evaluate the yield and price of bonds to assess their investment potential.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
  {
    title: "Average Calculator",
    component: <AverageCalc />,
    keywords:
      "average calculation, mean calculator, average score, data analysis",
    slug: "average-calculator",
    description:
      "Quickly calculate the average of a set of numbers for data analysis or personal use.",
    image: EurosToPoundsCalcIcon,
    alt: "EurosToPoundsCalcIcon",
    category: "Financial Calculators",
    similar: [
      {
        title: "Pounds to Dollars Calculator",
        slug: "pounds-to-dollars-calculator",
      },
      { title: "Investment Calculator", slug: "investment-calculator" },
    ],
  },
];

export const UNITCONVERSIONDATA = [
  {
    category: "Length",
    unit: [
      "Meter",
      "Kilometer",
      "Centimeter",
      "Milimeter",
      "Micrometer",
      "Nanometer",
      "Mile",
      "Yard",
      "Foot",
      "Inch",
      "Light Year",
    ],
  },
  {
    category: "Temperature",
    unit: ["Celsius", "Kelvin", "Fahrenheit"],
  },
  {
    category: "Area",
    unit: [
      "Square Meter",
      "Square Kilometer",
      "Square Centimeter",
      "Square Millimeter",
      "Square Micrometer",
      "Hectare",
      "Square Mile",
      "Square Yard",
      "Square Foot",
      "Square Inch",
      "Acre",
    ],
  },
  {
    category: "Volume",
    unit: [
      "Cubic Meter",
      "Cubic Kilometer",
      "Cubic Centimeter",
      "Cubic Millimeter",
      "Cubic Micrometer",
      "Liter",
      "Milliliter",
      "US Gallon",
      "US Quart",
      "US Pint",
      "US Cup",
      "US Fluid Ounce",
      "US Table Spoon",
      "US Tea Spoon",
      "Imperial Gallon",
      "Imperial Quart",
      "Imperial Pint",
      "Imperial Fluid Ounce",
      "Imperial Table Spoon",
      "Imperial Tea Spoon",
      "Cubic Mile",
      "Cubic Yard",
      "Cubic Foot",
      "Cubic Inch",
    ],
  },
  {
    category: "Weight",
    unit: [
      "Kilogram",
      "Gram",
      "Milligram",
      "Metric Ton",
      "Long Ton",
      "Short Ton",
      "Pound",
      "Ounce",
      "Carrat",
      "Atomic Mass Unit",
    ],
  },
];

export const conversionFactors = {
  // Length conversion factors relative to meter
  Meter: 1,
  Kilometer: 1000,
  Centimeter: 0.01,
  Milimeter: 0.001,
  Micrometer: 1e-6,
  Nanometer: 1e-9,
  Mile: 1609.34,
  Yard: 0.9144,
  Foot: 0.3048,
  Inch: 0.0254,
  LightYear: 9.461e15,

  // Area conversion factors relative to square meter
  SquareMeter: 1,
  SquareKilometer: 1e6,
  SquareCentimeter: 0.0001,
  SquareMillimeter: 1e-6,
  SquareMicrometer: 1e-12,
  Hectare: 1e4,
  SquareMile: 2.59e6,
  SquareYard: 0.836127,
  SquareFoot: 0.092903,
  SquareInch: 0.00064516,
  Acre: 4046.86,

  // Volume conversion factors relative to cubic meter
  CubicMeter: 1,
  CubicKilometer: 1e9,
  CubicCentimeter: 1e-6,
  CubicMillimeter: 1e-9,
  CubicMicrometer: 1e-18,
  Liter: 0.001,
  Milliliter: 1e-6,
  USGallon: 0.00378541,
  USQuart: 0.000946353,
  USPint: 0.000473176,
  USCup: 0.000236588,
  USFluidOunce: 2.9574e-5,
  USTableSpoon: 1.4787e-5,
  USTeaSpoon: 4.9289e-6,
  ImperialGallon: 0.00454609,
  ImperialQuart: 0.00113652,
  ImperialPint: 0.000568261,
  ImperialFluidOunce: 2.8413e-5,
  ImperialTableSpoon: 1.7758e-5,
  ImperialTeaSpoon: 5.9194e-6,
  CubicMile: 4.168e9,
  CubicYard: 0.764555,
  CubicFoot: 0.0283168,
  CubicInch: 1.6387e-5,

  // Weight conversion factors relative to kilogram
  Kilogram: 1,
  Gram: 0.001,
  Milligram: 1e-6,
  MetricTon: 1000,
  LongTon: 1016.05,
  ShortTon: 907.185,
  Pound: 0.453592,
  Ounce: 0.0283495,
  Carrat: 0.0002,
  AtomicMassUnit: 1.660539e-27,
};

export const passwordCheckboxData = [
  { label: "Include Lower Case (a-z)", value: "Include Lower Case (a-z)" },
  { label: "Include Upper Case (A-Z)", value: "Include Upper Case (A-Z)" },
  { label: "Include Numbers (0-9)", value: "Include Numbers (0-9)" },
  {
    label: "Include Symbols (!#$%&\"()'*+,-./:;<=>?@[]^_`{|}~)",
    value: "Include Symbols (!#$%&\"()'*+,-./:;<=>?@[]^_`{|}~)",
  },
  {
    label: "Exclude Ambiguous Character (iIl1L|oO0`\"-_':;.,)",
    value: "Exclude Ambiguous Character (iIl1L|oO0`\"-_':;.,)",
  },
  {
    label: "Exclude Brackets (<>()[]{})",
    value: "Exclude Brackets (<>()[]{})",
  },
  { label: "No Repeated Characters", value: "No Repeated Characters" },
];


export const cashBackCheckBox = [
  { label: "Include All Fees in Loan", value: "Include All Fees in Loan" },
];
export const stateListForCashBack = [
  "--Select--","Alabama",
	"Alaska",
	"Arizona",
	"Arkansas",
	"California",
	"Colorado",
	"Connecticut",
	"Delaware",
	"District of Columbia",
	"Florida",
	"Georgia",
	"Hawaii",
	"Idaho",
	"Illinois",
	"Indiana",
	"Iowa",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Maine",
	"Maryland",
	"Massachusetts",
	"Michigan",
	"Minnesota",
	"Mississippi",
	"Missouri",
	"Montana",
	"Nebraska",
	"Nevada",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"New York",
	"North Carolina",
	"North Dakota",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"Utah",
	"Vermont",
	"Virginia",
	"Washington",
	"West Virginia",
	"Wisconsin",
	"Wyoming",
	]


  export const stateRates = {
    "--Select--":0,
    "Alabama": 4,
    "Alaska": 0, // No state sales tax
    "Arizona": 5.6,
    "Arkansas": 6.5,
    "California": 7.25,
    "Colorado": 2.9,
    "Connecticut": 6.35,
    "Delaware": 0, // No state sales tax
    "District of Columbia": 6,
    "Florida": 6,
    "Georgia": 4,
    "Hawaii": 4,
    "Idaho": 6,
    "Illinois": 6.25,
    "Indiana": 7,
    "Iowa": 6,
    "Kansas": 6.5,
    "Kentucky": 6,
    "Louisiana": 4.45,
    "Maine": 5.5,
    "Maryland": 6,
    "Massachusetts": 6.25,
    "Michigan": 6,
    "Minnesota": 6.875,
    "Mississippi": 7,
    "Missouri": 4.225,
    "Montana": 0, // No state sales tax
    "Nebraska": 5.5,
    "Nevada": 6.85,
    "New Hampshire": 0, // No state sales tax
    "New Jersey": 6.625,
    "New Mexico": 5.125,
    "New York": 4,
    "North Carolina": 4.75,
    "North Dakota": 5,
    "Ohio": 5.75,
    "Oklahoma": 4.5,
    "Oregon": 0, // No state sales tax
    "Pennsylvania": 6,
    "Rhode Island": 7,
    "South Carolina": 6,
    "South Dakota": 4.5,
    "Tennessee": 7,
    "Texas": 6.25,
    "Utah": 4.85,
    "Vermont": 6,
    "Virginia": 5.3,
    "Washington": 6.5,
    "West Virginia": 6,
    "Wisconsin": 5,
    "Wyoming": 4,
  };
  