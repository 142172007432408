import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "../TimeCalc/TimeCalc.scss";
import "./StandardDeviationCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function StandardDeviationCalc() {
  const TYPES = [
    { label: "Sample", value: "Sample" },
    { label: "Population", value: "Population" },
  ];
  const [numbersInput, setNumbersInput] = useState(
    "10, 12, 16, 16, 21, 23, 23, 23"
  );
  const [type, setType] = useState({
    name: "Sample",
    value: "Sample",
  });
  const [StandardDeviation, setStandardDeviation] = useState(0);
  const [variance, setVariance] = useState(0);
  const [count, setCount] = useState(0);
  const [mean, setMean] = useState(0);
  const [sumOfSquares, setSumOfSquares] = useState(0);

  useEffect(() => {
    const calculateStatistics = () => {
      if (numbersInput === "" || numbersInput.endsWith(",")) {
        setCount(0);
        setMean(0);
        setSumOfSquares(0);
        setVariance(0);
        setStandardDeviation(0);
        return;
      } else {
        const numbers = numbersInput.split(",").map((num) => num.trim());
        const n = numbers.length;
        if (n >= 2) {
          const xbar = numbers.reduce((acc, val) => +acc + +val, 0) / n;
          const sumOfSquares = numbers.reduce(
            (acc, val) => +acc + Math.pow(+val - +xbar, 2),
            0
          );
          const sampleVariance =
            +sumOfSquares / (n - (type?.value === "Sample" ? 1 : 0));
          const sampleStandardDeviation = Math.sqrt(+sampleVariance);
          const populationVariance = +sumOfSquares / n;
          const populationStandardDeviation = Math.sqrt(+populationVariance);
          setCount(n);
          setMean(+xbar.toFixed(2));
          setSumOfSquares(+sumOfSquares.toFixed(2));

          if (type?.value === "Sample") {
            setVariance(+sampleVariance.toFixed(2));
            setStandardDeviation(+sampleStandardDeviation.toFixed(2));
          } else {
            setVariance(+populationVariance.toFixed(2));
            setStandardDeviation(+populationStandardDeviation.toFixed(2));
          }
        }
      }
    };

    calculateStatistics();
  }, [numbersInput, type]);

  const numberCommaRegex = /^[\d,\s]+$/;

  const handleNumbersInputChange = (event) => {
    const { value } = event.target;
    if (numberCommaRegex.test(value) || value === "") {
      setNumbersInput(value);
    }
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "8px 0", md: "8px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part" sx={{ mt: 2 }}>
            <Grid>
              <Typography className="common-sub-heading-calc">
                Enter Data Set:
              </Typography>
              <textarea
                rows="4"
                // cols="65"
                value={numbersInput}
                onChange={handleNumbersInputChange}
              />
              <Typography className="value-enter">
                (Enter value with "," separated)
              </Typography>
              <RadioButtonComponent
                label="Types"
                data={TYPES}
                defaultValue={"Sample"}
                valueSetter={setType}
                row={true}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Standard Deviation"
                isInput={false}
                value={StandardDeviation}
                date={true}
              />
              <InputComponent
                label="Variance"
                isInput={false}
                value={variance}
                date={true}
              />
              <InputComponent
                label="Count"
                isInput={false}
                value={count}
                date={true}
              />
              <InputComponent
                label="Mean"
                isInput={false}
                value={mean}
                date={true}
              />
              <InputComponent
                label="Sum of Squares"
                isInput={false}
                value={sumOfSquares}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default StandardDeviationCalc;
