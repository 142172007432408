import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import DateView from "../../Common/CalenderView/CalenderView";
import InputSlider from "../../Common/Slider/Slider";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import Table from "./Table";
import dayjs from "dayjs";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

export default function OvulationCalc() {
  const [periodDate, setperiodDate] = useState(dayjs());
  const [avgCycLen, setAvgCycLen] = useState(28);
  const [currentCycleData, setCurrentCycleData] = useState([]);
  const [nextSixData, setNextSixData] = useState([]);


  function generateOvulationData(periodDate, avgCycLen) {
    let nextPeriod = periodDate.add(avgCycLen, "days");

    let OVWindowEnd = nextPeriod.subtract(12, "days");
    let OVWindowStart = OVWindowEnd.subtract(4, "days");

    let probableOVDate = OVWindowStart.add(2, "days");

    let intercourseEnd = OVWindowEnd;
    let intercourseStart = OVWindowEnd.subtract(1, "week");

    let pregnancyTest = intercourseEnd.add(1, "week");

    let dueDate = periodDate.add(40, "week").add(avgCycLen - 28, "days");

    let currentCycleData = [
      {
        string: "Ovulation Window",
        date: [OVWindowStart, OVWindowEnd],
        cellColor: "#fabee2",
      },
      { string: "Most Probable Ovulation Date", date: probableOVDate },
      {
        string: "Intercourse Window for Pregnancy",
        date: [intercourseStart, intercourseEnd],
      },
      { string: "Pregnancy Test", date: pregnancyTest },
      { string: "Next Period Start", date: nextPeriod },
      { string: "Due Date If Pregnant", date: dueDate },
    ];

    setCurrentCycleData(currentCycleData);

    let nextSixData = [];

    for (let i = 0; i < 6; i++) {
      let periodStart = periodDate.add(avgCycLen * i, "days");
      let OVWindowEnd = periodDate.add(avgCycLen * (i + 1) - 12, "days");
      let OVWindowStart = OVWindowEnd.subtract(4, "days");
      let dueDate = periodDate
        .add(40, "week")
        .add((i + 1) * avgCycLen - 28, "days");

      nextSixData.push({
        periodStart,
        OVWindow: [OVWindowStart, OVWindowEnd],
        dueDate: dueDate,
      });
    }
    console.clear();
    setNextSixData(nextSixData);

    return { currentCycleData, nextSixData };
  }

  useEffect(() => {
    generateOvulationData(periodDate, avgCycLen);
  }, [periodDate, avgCycLen]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid containe spacing={3}>
          <Grid item xs={12} md={8}>
            <Grid item className="calculation-part ">
              <Grid item>
                <Typography className="common-sub-heading-desc">
                  The Ovulation Calculator estimates the most probable
                  ovulation/fertile window as well as other related dates. The
                  estimations are based on a woman's last period date. This
                  calculator should not be used as a form of birth control.
                </Typography>
              </Grid>

              <Grid item sx={{ mt: 3 }}>
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ mx: 1, fontWeight: 600, fontSize: "18px" }}
                  >
                    First Day of Your Last Period:
                  </Typography>
                  <DateView date={periodDate} valueSetter={setperiodDate} />
                </Grid>

                <Grid item>
                  <InputSlider
                    label="Average Length of Your Cycles: "
                    value={avgCycLen}
                    valueSetter={setAvgCycLen}
                    min={22}
                    max={44}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  {currentCycleData.length >= 0 && (
                    <Grid sx={{ mt: 5 }}>
                      <Typography className="common-sub-heading-calc">
                        Important dates for this cycle:{" "}
                      </Typography>
                      <Table
                        rows={currentCycleData}
                        headerData={["Important Milestone", "Date"]}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item sx={{ mt: 5, mb: 2 }}>
                  <Typography className="common-sub-heading-calc">
                    Important dates for the next six cycles:
                  </Typography>
                  {nextSixData.length >= 0 && (
                    <Grid>
                      <Table
                        rows={nextSixData}
                        headerData={[
                          "Period Start	",
                          "Ovulation Window",
                          "Due Date",
                        ]}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
