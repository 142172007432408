import { Grid } from "@mui/material";
import React from "react";
import "./Loader.scss";
function Loader() {
  return (
    <Grid className="loader-container">
      <span className="loader"></span>{" "}
    </Grid>
  );
}

export default Loader;
