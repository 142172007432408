import React from "react";

import { Grid, Typography } from "@mui/material";
import ComponentHeader from "../Header/ComponentHeader";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";

function Disclaimer() {
  return (
    <>
      <Helmet>
        <title>{SEO.DISCLAIMER_TITLE}</title>
        <meta name="description" content={SEO.DISCLAIMER_DESCRIPTION} />
        <meta name="keywords" content={SEO.DISCLAIMER_KEYWORDS} />

        <meta property="og:title" content={SEO.DISCLAIMER_TITLE} />
        <meta property="og:description" content={SEO.DISCLAIMER_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.CALCULATOR_TIMES_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.CALCULATOR_TIMES} />
        <meta name="robots" content="index" />
<meta name="googlebot" content="index" />
<meta name="googlebot-news" content="index" />
        <meta name="twitter:title" content={SEO.DISCLAIMER_TITLE} />
        <meta name="twitter:description" content={SEO.DISCLAIMER_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" /><link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid sx={{ px: 2, py: 1 }}>
        <ComponentHeader name="Disclaimer" />
        <Grid className="privacy-policy">
          <Typography variant="h2">Disclaimer page</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Disclaimer;
