import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import TableComponent from "../../Common/TableComponent/TableComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

const TipCalc = () => {
  const [billAmountInput, setBillAmountInput] = useState(50);
  const [tipPercentage, setTipPercentage] = useState(10);
  const [numberOfPeople, setNumberOfPeople] = useState(1);
  const [tipAmount, setTipAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tipPerPerson, setTipPerPerson] = useState(0);
  const [totalPerPerson, setTotalPerPerson] = useState(0);
  const [price, setPrice] = useState(55);
  const [tipsTableData, setTipsTableData] = useState([]);

  useEffect(() => {
    const bill = parseFloat(billAmountInput);
    const tipPercent = parseFloat(tipPercentage) / 100;
    const people = parseInt(numberOfPeople);

    const tip = bill * tipPercent;
    const total = bill + tip;
    const tipPer = tip / people;
    const totalPer = total / people;

    setTipAmount(tip);
    setTotalAmount(total);
    setTipPerPerson(tipPer);
    setTotalPerPerson(totalPer);
  }, [billAmountInput, tipPercentage, numberOfPeople]);

  useEffect(() => {
    const tipPercentages = [5, 10, 12, 14, 15, 18, 20, 25, 30, 50];
    const records = [];
    tipPercentages.forEach((tipPercentage) => {
      const tipAmount = parseFloat(price) * (tipPercentage / 100);
      const total = parseFloat(price) + tipAmount;
      records.push({
        tipPercentage: tipPercentage + "%",
        tipAmount: tipAmount.toFixed(2),
        total: total.toFixed(2),
      });
    });

    setTipsTableData(records);
  }, [price]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <Typography className="common-sub-heading-calc">
                1. Shared Bill Tip Calculator
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Bill Amount"
                min={1}
                value={billAmountInput}
                setState={setBillAmountInput}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Tip (p.a.)"
                value={tipPercentage}
                setState={setTipPercentage}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                label="Number of People"
                value={numberOfPeople}
                setState={setNumberOfPeople}
                min={0}
                max={999999}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Tip"
                isInput={false}
                value={tipAmount}
                flag="dollar"
              />
              <InputComponent
                label="Total Amount"
                isInput={false}
                value={totalAmount}
                flag="dollar"
              />
              <InputComponent
                label="Tip Per Person"
                isInput={false}
                value={tipPerPerson}
                flag="dollar"
              />
              <InputComponent
                label="Total Per Person"
                isInput={false}
                value={totalPerPerson}
                flag="dollar"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +billAmountInput, label: "Bill Amount" },
                { id: 1, value: +tipAmount, label: "Tip Amount" },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={7} className="calculation-part" sx={{ mt: 2 }}>
            <Typography className="common-sub-heading-calc">
              2. Tip Calculator
            </Typography>
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Bill Amount"
                value={price}
                setState={setPrice}
              />
            </Grid>
            <Grid sx={{ p: 2 }}>
              <TableComponent
                rows={tipsTableData}
                headerData={["Tip %", "Tip Amount", "Total"]}
                isMinWidth={300}
                currency="dollar"
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
};

export default TipCalc;
