import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "./HouseAffordabilityCalc.scss";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";

function HouseAffordabilityCalc() {
  const [annualHouseHoldAmount, setAnnualHouseHoldAmount] = useState(100000);
  const [loanTerm, setLoanTerm] = useState(30);
  const [interestRate, setInterestRate] = useState(6.414);
  const [monthlyDebtPayback, setMonthlyDebtPayback] = useState(0);
  const [downPayment, setDownPayment] = useState(20);
  const [propertyTax, setPropertyTax] = useState(1.5);
  const [HOA, setHOA] = useState(0);
  const [insurance, setInsurance] = useState(0.5);
  const [dtiRatio, setDtiRation] = useState("Conventional loan (28/36 rule)");
  const [maxLoanAmount, setMaxLoanAmount] = useState(0);
  const [totalHousePrice, setTotalHousePrice] = useState(0);
  const [downPaymentAmount, setDownPaymentAmount] = useState(0);
  const [totalMonthlyCost, setTotalMonthlyCost] = useState(0);
  const [estClosingCost, setEstClosingCost] = useState(0);
  const [monthlyMortagePayment, setMonthlyMortagePayment] = useState(0);
  const [annualPropertyCost, setAnnualPropertyCost] = useState(0);
  const [insuranceCost, setInsuranceCost] = useState(0);
  const [maintenanceCost, setMaintenanceCost] = useState(0);
  const [hoaCost, setHOACost] = useState(0);
  const [oneTimePayClosing, setOneTimePayClosing] = useState(0);

  const selectBoxData = [
    "Conventional loan (28/36 rule)",
    "FHA loan (31% front-end, 43% back-end)",
    "VA loan (41%)",
    "10%",
    "15%",
    "20%",
    "25%",
    "30%",
    "35%",
    "40%",
    "45%",
    "50%",
  ];

  useEffect(() => {
    // DTI Ratio Mapping
    const dtiRatioMapping = {
      "Conventional loan (28/36 rule)": [28, 36],
      "FHA loan (31% front-end, 43% back-end)": [31, 43],
      "VA loan (41%)": [41, 41],
    };

    // Determine the DTI ratios
    let dtiRatioValue = dtiRatioMapping[dtiRatio];

    // If the dtiRatio is a percentage string like "10%", "15%", etc.
    if (!dtiRatioValue) {
      let customRatio = parseFloat(dtiRatio.replace("%", ""));
      dtiRatioValue = [customRatio, customRatio];
    }
    console.log("dtiRatioValue", dtiRatioValue);
    // Calculate maximum allowable front-end monthly payment based on DTI
    let maxFrontEndMonthlyPayment =
      (annualHouseHoldAmount * dtiRatioValue[0]) / (12 * 100);

    // Calculate monthly interest rate
    let monthlyInterestRate = interestRate / (12 * 100);

    // Calculate max loan amount using the front-end DTI
    let maxLoanAmount =
      (maxFrontEndMonthlyPayment *
        (1 - Math.pow(1 + monthlyInterestRate, -loanTerm * 12))) /
      monthlyInterestRate;

    // Calculate down payment and total house price
    let downPaymentAmount = (downPayment / 100) * maxLoanAmount;
    let totalHousePrice = maxLoanAmount + downPaymentAmount;

    // Calculate monthly property tax, HOA, and insurance
    let monthlyPropertyTax = ((propertyTax / 100) * totalHousePrice) / 12;
    let monthlyHOA = ((HOA / 100) * totalHousePrice) / 12;
    let monthlyInsurance = ((insurance / 100) * totalHousePrice) / 12;

    // Calculate the total monthly cost including all components
    let monthlyHousingCost =
      maxFrontEndMonthlyPayment +
      monthlyPropertyTax +
      monthlyHOA +
      monthlyInsurance;

    // Apply the back-end DTI ratio to consider all monthly debts
    let totalMonthlyCost = +monthlyHousingCost + +monthlyDebtPayback;

    // Ensure total monthly cost does not exceed back-end DTI limit
    let maxBackEndMonthlyPayment =
      (annualHouseHoldAmount * dtiRatioValue[1]) / (12 * 100);

    // If total monthly cost exceeds back-end DTI limit, adjust the values
    if (totalMonthlyCost > maxBackEndMonthlyPayment) {
      maxFrontEndMonthlyPayment =
        maxBackEndMonthlyPayment -
        monthlyDebtPayback -
        (monthlyPropertyTax + monthlyHOA + monthlyInsurance);

      maxLoanAmount =
        (maxFrontEndMonthlyPayment *
          (1 - Math.pow(1 + monthlyInterestRate, -loanTerm * 12))) /
        monthlyInterestRate;

      downPaymentAmount = (downPayment / 100) * maxLoanAmount;
      totalHousePrice = maxLoanAmount + downPaymentAmount;

      monthlyPropertyTax = ((propertyTax / 100) * totalHousePrice) / 12;
      monthlyHOA = ((HOA / 100) * totalHousePrice) / 12;
      monthlyInsurance = ((insurance / 100) * totalHousePrice) / 12;
    }

    // Calculate estimated closing cost (typically 3% of the total house price)
    let estClosingCost = totalHousePrice * 0.03;

    // Calculate the total one-time payment at closing (Down Payment + Closing Cost)
    let oneTimePayClosing = downPaymentAmount + estClosingCost;

    // Set all state values
    setMaxLoanAmount(maxLoanAmount.toFixed(2));
    setTotalHousePrice(totalHousePrice.toFixed(2));
    setDownPaymentAmount(downPaymentAmount.toFixed(2));
    setTotalMonthlyCost(+totalMonthlyCost?.toFixed(2));

    setEstClosingCost(estClosingCost.toFixed(2));
    setMonthlyMortagePayment(maxFrontEndMonthlyPayment.toFixed(2));
    setAnnualPropertyCost(monthlyPropertyTax * 12);
    setInsuranceCost(monthlyInsurance * 12);
    setMaintenanceCost(((totalHousePrice * 0.015) / 12).toFixed(2) * 12);
    setHOACost(monthlyHOA * 12);
    setOneTimePayClosing(oneTimePayClosing.toFixed(2));
  }, [
    annualHouseHoldAmount,
    loanTerm,
    interestRate,
    monthlyDebtPayback,
    downPayment,
    propertyTax,
    HOA,
    insurance,
    dtiRatio,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Annual Household Income"
                value={annualHouseHoldAmount}
                min={1}
                setState={setAnnualHouseHoldAmount}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Mortgage loan term"
                setState={setLoanTerm}
                value={loanTerm}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest rate"
                setState={setInterestRate}
                value={interestRate}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Monthly debt payback"
                setState={setMonthlyDebtPayback}
                value={monthlyDebtPayback}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Down payment"
                setState={setDownPayment}
                value={downPayment}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Property tax"
                setState={setPropertyTax}
                value={propertyTax}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="HOA or co-op fee"
                setState={setHOA}
                value={HOA}
                min={0}
                max={199}
              />

              <InputComponent
                isInput={true}
                flag="percentage"
                label="Insurance"
                setState={setInsurance}
                value={insurance}
                min={0}
                max={199}
              />

              <SelectBoxComponent
                value={dtiRatio}
                label="Debt-to-income (DTI) ratio"
                setValue={setDtiRation}
                data={selectBoxData}
                placeholder="Debt-to-income (DTI) ratio"
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                isInput={false}
                value={` You can afford a house up to <strong>$${totalHousePrice}</strong> within which <strong>$${maxLoanAmount}</strong> is the loan and <strong>$${downPaymentAmount}</strong> is the down payment.`}
                date={true}
              />
              <InputComponent
                label="You can borrow"
                isInput={false}
                flag="dollar"
                value={maxLoanAmount}
              />
              <InputComponent
                label="Total price of the house"
                isInput={false}
                flag="dollar"
                value={totalHousePrice}
              />
              <InputComponent
                label="Down payment"
                isInput={false}
                flag="dollar"
                value={downPaymentAmount}
              />
              <InputComponent
                label="Estimated closing cost (one time, assume 3%)"
                isInput={false}
                flag="dollar"
                value={estClosingCost}
              />
              <InputComponent
                label="Total one-time payment at closing"
                isInput={false}
                flag="dollar"
                value={oneTimePayClosing}
              />
              <InputComponent
                label="Monthly mortgage payment"
                isInput={false}
                flag="dollar"
                value={monthlyMortagePayment}
              />
              <InputComponent
                label="Annual property tax"
                isInput={false}
                flag="dollar"
                value={annualPropertyCost}
              />
              <InputComponent
                label="Annual HOA or co-op fee"
                isInput={false}
                flag="dollar"
                value={hoaCost}
              />
              <InputComponent
                label="Annual insurance cost"
                isInput={false}
                flag="dollar"
                value={insuranceCost}
              />
              <InputComponent
                label={`Estimated annual maintenance cost`}
                isInput={false}
                flag="dollar"
                value={maintenanceCost}
              />
              <InputComponent
                label="Total monthly cost on the house"
                isInput={false}
                flag="dollar"
                value={totalMonthlyCost}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ display: "flex" }}>
            <PieChartComponent
              data={[
                { id: 0, value: +maxLoanAmount, label: "You can borrow	" },
                {
                  id: 1,
                  value: +totalHousePrice,
                  label: "Total price of the house",
                },
                {
                  id: 2,
                  value: +downPaymentAmount,
                  label: "Down payment",
                },
              ]}
              flag="invest"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default HouseAffordabilityCalc;
