import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  getMessageByPercentage,
  loveColor,
  returnHelmetData
} from "../../../Utils/CommonFunction";
import loveCalcImg from "../../../Assets/ComponentImage/loveCalc.jpg";
import "./LoveCalc.scss";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function LoveCalc() {
  const [femaleName, setFemaleName] = useState("Angelena");
  const [maleName, setMaleName] = useState("Joe");
  const [gender, setGender] = useState("Male");

  const [love, setLove] = useState("");
  const [percentageScore, setPercentageScore] = useState(0);

  useEffect(() => {
    if (femaleName.length > 0 && maleName.length > 0) {
      let name1 = maleName?.toLowerCase().replace(/\s/g, "");
      let name2 = femaleName?.toLowerCase().replace(/\s/g, "");
      const combinedName = name1 + name2;

      let loveScore = 0;
      for (let i = 0; i < combinedName.length; i++) {
        if (name2.includes(combinedName[i])) {
          loveScore++;
        }
      }

      const percentageScore = Math.round(
        (loveScore / combinedName.length) * 100
      );

      if (gender === "Male") {
        setPercentageScore(percentageScore);
        setLove(`${name1} ❤️ ${name2}`);
      } else {
        setPercentageScore(percentageScore);
        setLove(`${name2} ❤️ ${name1}`);
      }
    }
  }, [femaleName, maleName, gender]);
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} sx={{ margin: "0 0 14px 0" }}>
            <TabbingComponent
              data={["Male", "Female"]}
              value={gender}
              setValue={setGender}
            />
          </Grid>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                label="Your name"
                type="text"
                value={gender === "Male" ? maleName : femaleName}
                setState={gender === "Male" ? setMaleName : setFemaleName}
                placeholder={gender === "Male" ? "Ex. Joe" : "Ex. Angelena"}
              />
              <InputComponent
                isInput={true}
                label="Partner Name"
                type="text"
                value={gender === "Male" ? femaleName : maleName}
                setState={gender === "Male" ? setFemaleName : setMaleName}
                placeholder={gender === "Male" ? "Ex. Angelena" : "Ex. Joe"}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="love-detail">
              <Typography className="love-name">{love}</Typography>
              <div style={{ width: "20%", height: "20%", margin: "10px auto" }}>
                <CircularProgressbar
                  value={percentageScore}
                  text={`${percentageScore}%`}
                  background
                  backgroundPadding={6}
                  styles={buildStyles({
                    backgroundColor: "#61B0C1",
                    textColor: "#fff",
                    pathColor: "#fff",
                    trailColor: "transparent",
                  })}
                />
              </div>

              <Typography
                className="love-message"
                sx={{ color: loveColor(percentageScore) }}
              >
                {getMessageByPercentage(percentageScore)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={0} md={1} />

          <Grid item xs={0} md={4} sx={{ py: 2 }}>
            <img
              src={loveCalcImg}
              alt="age-calculator"
              className="age-calc-assets"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default LoveCalc;
