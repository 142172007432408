import { Box, Container, Grid, Link, Typography } from "@mui/material";
import React from "react";
import Divider from "@mui/material/Divider";
import "./Footer.scss";
import fbIcon from "../../../Assets/Icon/FACEBOOK.png";
import wpIcon from "../../../Assets/Icon/WHATSAPP.png";
import footerLogo from "../../../Assets/Icon/footer-logo.png";
import twitterIcon from "../../../Assets/Icon/TWITTER.png";
import linkedinIcon from "../../../Assets/Icon/LINKEDIN.png";
import pinterestIcon from "../../../Assets/Icon/PINTEREST.png";
import { COMPONENTDATA } from "../../../Utils/Constant";

function Footer() {
  return (
    <Box className="footer-box">
      <Container className="container-class">
        <Grid container sx={{ gap: "35px", pt: 3 }}>
          <Grid item xs={12} sm={6} md={3} className="address-details">
            <Grid>
              <Grid className="d-flex-align">
                <Link href="/">
                  <img src={footerLogo} alt="logo" className="footer-logo" />
                </Link>
              </Grid>
            </Grid>
            <Grid className="icon-div">
              <img
                src={fbIcon}
                alt="facebook-link"
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/sharer/sharer.php?u=https://calculatortimes.com/",
                    "_blank"
                  );
                }}
              />
              <img
                src={wpIcon}
                alt="whatsapp-link"
                onClick={() => {
                  window.open(
                    "whatsapp://send?text=https://calculatortimes.com/"
                  );
                }}
              />
              <img
                src={twitterIcon}
                alt="tweet-link"
                onClick={() => {
                  window.open(
                    "https://twitter.com/intent/tweet?text=CalculatorTimes.com%20offers%20a%20wide%20range%20of%20online%20calculators%20to%20simplify%20your%20everyday%20calculations%20and%20complex%20equations!&url=https://calculatortimes.com/"
                  );
                }}
              />
              <img
                src={pinterestIcon}
                alt="pinterest-link"
                onClick={() => {
                  window.open(
                    "https://in.pinterest.com/pin/create/button/?description=Discover%20a%20variety%20of%20useful%20calculators%20on%20Calculator%20Times!%20From%20financial%20tools%20to%20academic%20aids,%20find%20the%20right%20calculator%20for%20your%20needs.&media=&url=https://calculatortimes.com"
                  );
                }}
              />

              <img
                src={linkedinIcon}
                alt="linkedin-link"
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/shareArticle?mini=true&url=https://calculatortimes.com&title=CalculatorTimes%20-%20Your%20Ultimate%20Calculator%20Hub&summary=Discover%20a%20wide%20range%20of%20calculators%20to%20make%20your%20life%20easier.%20From%20finance%20to%20health%2C%20we%20have%20it%20all!&source=calculatortimes.com"
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} className="footer-main-title">
            <Typography className="item-title">
              <span>
                <Link href="/contact-us">Contact us</Link>
              </span>
            </Typography>
            <Typography className="item-title">
              <span>
                <Link href="/privacy-policy">Privacy policy</Link>
              </span>
            </Typography>
            <Typography className="item-title">
              <span>
                <Link href="/terms-of-service">Terms of service</Link>
              </span>
            </Typography>
            <Typography className="item-title">
              <span>
                <Link href="/disclaimer">Disclaimer</Link>
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Divider className="divider" />
      <Container className="container-class">
        <Grid className="footer-bottom">
          <Typography className="footer-bottom-line">
            &copy; 2024 CalculatorTimes All right reserved.
          </Typography>
          <Link
            className="footer-bottom-line"
            href={process.env.REACT_APP_FOOTER_LINK}
            target="_blank"
          >
            Developed by <span>www.pixerfect.com</span>
          </Link>
        </Grid>
      </Container>
      <Grid style={{display:'none'}}>
        {COMPONENTDATA.map((item, index) =>(
          <Link
          className="all-calc-link"
          href={process.env.REACT_APP_SITEMAP_URL+item.slug}
          target="_blank"
          key={index}
        >
          {item?.title}
        </Link>
        ))}      
      </Grid>
    </Box>
  );
}

export default Footer;
