import { Box } from "@mui/material";
import React from "react";
import ComponentHeader from "../Header/ComponentHeader";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import ScientificCalc from "../../Pages/ScientificCalc/ScientificCalc";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

const ScientificCalculator = () => {
  
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <ScientificCalc />
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
};

export default ScientificCalculator;
