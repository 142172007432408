import { Box, Grid, Typography, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import PieChartComponent from "../../Common/PieChart/PieChart";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";

import TableComponent from "../../Common/TableComponent/TableComponent";

function SavingsCalc() {
  const [initialDeposit, setInitialDeposit] = useState(20000);
  const [annualContribution, setAnnualContribution] = useState(5000);
  const [annualContributionIncrease, setAnnualContributionIncrease] =
    useState(3);
  const [monthlyContribution, setMonthlyContribution] = useState(0);
  const [monthlyContributionIncrease, setMonthlyContributionIncrease] =
    useState(0);
  const [interestRate, setInterestRate] = useState(3);
  const [compound, setCompound] = useState("annually");
  const [yearOfLoan, setYearOfLoan] = useState(10);
  const [taxRate, setTaxRate] = useState(0);
  const [endBalance, setEndBalance] = useState(0);
  const [totalContributions, setTotalContributions] = useState(0);
  const [totalInterestEarned, setTotalInterestEarned] = useState(0);
  const [monthlyData, setMonthlyData] = useState([]);
  const [tableView, setTableView] = useState(false);
  const [totalTax, setTotalTax] = useState(0);

  useEffect(() => {
    let totalMonths = +yearOfLoan * 12;
    let currentBalance = parseFloat(initialDeposit);
    let totalContributions = 0;
    let totalInterestEarned = 0;
    let totalTax = 0; // Track total tax
    let monthlyData = [];
    let annualContributionAdjusted = parseFloat(annualContribution);
    let monthlyContributionAdjusted = parseFloat(monthlyContribution);

    // Determine compounding frequency
    let n = {
      annually: 1,
      semiannually: 2,
      quarterly: 4,
      monthly: 12,
      semimonthly: 24,
      biweekly: 26,
      weekly: 52,
      daily: 365,
      continuously: "continuously",
    }[compound];

    for (let month = 1; month <= totalMonths; month++) {
      let interest = 0;

      if (n === "continuously") {
        // Continuous compounding
        interest =
          currentBalance * (Math.exp((+interestRate / 100) * (1 / 12)) - 1);
      } else {
        // Discrete compounding based on frequency `n`
        let periodRate = +interestRate / 100 / n;
        let periods = n / 12;

        // Interest calculation for the specific compounding period
        interest =
          currentBalance * Math.pow(1 + periodRate, periods) - currentBalance;
      }
      interest = +interest.toFixed(2);

      // Calculate tax on interest
      let tax = ((+interest * +taxRate) / 100).toFixed(2);
      totalTax += +tax;

      let newMonthlyDeposit = monthlyContributionAdjusted;
      let newAnnualDeposit = 0;

      // Adjust the contribution at the end of each year (12th month of each year)
      if (month % 12 === 0) {
        newAnnualDeposit = annualContributionAdjusted;
        annualContributionAdjusted *= 1 + annualContributionIncrease / 100; // Adjust for annual increase

        // Increase monthly contribution at the end of the year
        if (monthlyContributionIncrease > 0) {
          monthlyContributionAdjusted *= 1 + monthlyContributionIncrease / 100;
        }
      }

      currentBalance += newMonthlyDeposit + newAnnualDeposit + interest - tax;

      totalContributions += +newMonthlyDeposit + +newAnnualDeposit;
      totalInterestEarned += interest;
      let depositValue =
        month === 1
          ? (+newMonthlyDeposit + +newAnnualDeposit + +initialDeposit).toFixed(
              2
            )
          : (+newMonthlyDeposit + +newAnnualDeposit).toFixed(2);

      monthlyData.push({
        month,
        deposit: depositValue,
        interest: interest.toFixed(2),
        ...(taxRate > 0 && { tax: tax }),
        endingBalance: currentBalance.toFixed(2),
      });
    }

    setEndBalance(currentBalance.toFixed(2));
    setTotalContributions(totalContributions.toFixed(2));
    setTotalInterestEarned(totalInterestEarned.toFixed(2));
    setTotalTax(totalTax.toFixed(2)); // Update total tax state
    setMonthlyData(monthlyData);
  }, [
    initialDeposit,
    annualContribution,
    annualContributionIncrease,
    monthlyContribution,
    monthlyContributionIncrease,
    interestRate,
    compound,
    yearOfLoan,
    taxRate,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Initial deposit"
                value={initialDeposit}
                min={0}
                setState={setInitialDeposit}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Annual contribution"
                value={annualContribution}
                min={0}
                setState={setAnnualContribution}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Annual contribution increase"
                setState={setAnnualContributionIncrease}
                value={annualContributionIncrease}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Monthly contribution"
                value={monthlyContribution}
                min={0}
                setState={setMonthlyContribution}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Monthly contribution increase"
                setState={setMonthlyContributionIncrease}
                value={monthlyContributionIncrease}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest rate"
                setState={setInterestRate}
                value={interestRate}
                min={0}
                max={999}
              />
              <SelectBoxComponent
                value={compound}
                setValue={setCompound}
                data={[
                  "annually",
                  "semiannually",
                  "quarterly",
                  "monthly",
                  "semimonthly",
                  "biweekly",
                  "weekly",
                  "daily",
                  "continuously",
                ]}
                placeholder="Select frequency"
                label="Compound"
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Years to save"
                setState={setYearOfLoan}
                value={yearOfLoan}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Tax rate"
                setState={setTaxRate}
                value={taxRate}
                min={0}
                max={999}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="End balance"
                isInput={false}
                value={endBalance}
              />
              <InputComponent
                label="Initial deposit"
                isInput={false}
                value={initialDeposit}
              />
              <InputComponent
                label="Total contributions"
                isInput={false}
                value={totalContributions}
              />
              <InputComponent
                label={
                  totalTax > 0
                    ? "Total interest earned (After Tax)"
                    : "Total interest earned"
                }
                isInput={false}
                value={totalInterestEarned}
              />
              {totalTax > 0 && (
                <InputComponent
                  label="Total tax"
                  isInput={false}
                  value={totalTax}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +initialDeposit, label: "Initial deposit" },
                { id: 1, value: +totalContributions, label: "Contributions" },
                {
                  id: 2,
                  value: +totalInterestEarned,
                  label:
                    totalTax > 0
                      ? "Total interest earned (After Tax)"
                      : "Total interest earned",
                },
                totalTax > 0 && {
                  id: 3,
                  value: +totalInterestEarned,
                  label: "Total tax",
                },
              ]}
              flag="invest"
              isTax={true}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Monthly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyData}
                  currency="dollar"
                  headerData={
                    taxRate > 0
                      ? ["Month", "Deposit", "Interest", "Tax", "End Balance"]
                      : ["Month", "Deposit", "Interest", "End Balance"]
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default SavingsCalc;
