import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import Triangle from "../../../Assets/Icon/IsoscelesTriangleCalcImg.png";

function IsoscelesTriangleCalc() {
  const [leg, setLeg] = useState(10);
  const [base, setBase] = useState(5);
  const [units, setUnits] = useState("meters");
  const [unitShort, setUnitShort] = useState("m");
  const [baseAngle, setBaseAngle] = useState(0);
  const [vertexAngle, setVertexAngle] = useState(0);
  const [height, setHeight] = useState(0);
  const [area, setArea] = useState(0);
  const [perimeter, setPerimeter] = useState(0);

  useEffect(() => {
    const height = Math.sqrt(leg ** 2 - (+base / 2) ** 2);
    const area = 0.5 * +base * +height;
    const perimeter = 2 * +leg + +base;
    const baseAngle = Math.acos(+base / (2 * +leg)) * (180 / Math.PI);
    const vertexAngle = 180 - 2 * +baseAngle;

    setHeight(+height.toFixed(2));
    setArea(+area.toFixed(2));
    setPerimeter(+perimeter.toFixed(2));
    setBaseAngle(+baseAngle.toFixed(2));
    setVertexAngle(+vertexAngle.toFixed(2));
  }, [leg, base, units]);

  useEffect(() => {
    const unitMap = {
      feet: "ft",
      inches: "in",
      yards: "yd",
      kilometers: "km",
      millimeters: "mm",
      meters: "m",
      centimeters: "cm",
    };

    setUnitShort(unitMap[units]);
  }, [units]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: "10px 24px" }}>
        <ComponentHeader />
        <Grid container gap={2.5} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={7} className="calculation-part">
            <Grid>
              <InputComponent
                label="Leg (a)"
                isInput={true}
                value={leg}
                min={1}
                max={10000000000}
                setState={setLeg}
              />
              <InputComponent
                label="Base (b)"
                isInput={true}
                value={base}
                min={1}
                max={10000000000}
                setState={setBase}
              />
              <Grid>
                <SelectBoxComponent
                  value={units}
                  setValue={setUnits}
                  data={[
                    "kilometers",
                    "meters",
                    "millimeters",
                    "centimeters",
                    "feet",
                    "inches",
                    "yards",
                  ]}
                  placeholder="select-option"
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid className="concrete-img">
              <img src={Triangle} alt="shape-1" height="auto" width="auto" />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                label="Base Angle (α)"
                isInput={false}
                value={`${baseAngle || 0}°`}
                date={true}
              />{" "}
              <InputComponent
                isInput={false}
                value={
                  isNaN(baseAngle)
                    ? `${0} rad`
                    : `${(baseAngle * 0.01745).toFixed(2)} rad`
                }
                date={true}
              />
              <InputComponent
                label="Vertex Angle (β)"
                isInput={false}
                value={`${vertexAngle || 0}°`}
                date={true}
              />
              <InputComponent
                isInput={false}
                value={
                  isNaN(vertexAngle)
                    ? `${0} rad`
                    : `${(vertexAngle * 0.01745).toFixed(2)} rad`
                }
                date={true}
              />
              <InputComponent
                label="Height (h)"
                isInput={false}
                value={`${height || 0} ${unitShort}`}
                date={true}
              />
              <InputComponent
                label="Area"
                isInput={false}
                value={`${area || 0} ${unitShort}²`}
                date={true}
              />
              <InputComponent
                label="Perimeter"
                isInput={false}
                value={`${perimeter || 0} ${unitShort}`}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default IsoscelesTriangleCalc;
