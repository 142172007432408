import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import {
  returnHelmetData
} from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import BarChartComponent from "../../Common/BarChart/BarCahrt";

function AmortizationCalc() {
  const [carAmountInput, setCarAmountInput] = useState(100000);
  const [interestValue, setInterestValue] = useState(6);
  const [yearOfLoan, setYearOfLoan] = useState(15);
  const [monthlyEmi, setMonthlyEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [yearlySummary, setYearlySummary] = useState([
    {
      year: 0,
      principal: +carAmountInput,
      interest: 0,
    },
  ]);

  useEffect(() => {
    const generateMonthlyRecords = (
      monthlyPayment,
      monthlyInterestRate,
      numPayments
    ) => {
      setMonthlyRecords([]);
      if (monthlyPayment > 0 && monthlyInterestRate > 0 && numPayments > 1) {
        const records = [];
        let remainingPrincipal = carAmountInput;
        for (let i = 1; i <= numPayments; i++) {
          const interestPayment = remainingPrincipal * monthlyInterestRate;
          const principalPayment = monthlyPayment - interestPayment;
          remainingPrincipal -= principalPayment;
          records.push({
            month: i,
            principalPayment: principalPayment.toFixed(2),
            interestPayment: interestPayment.toFixed(2),
            totalPaid: (+principalPayment + +interestPayment).toFixed(2),
            balance: Math.abs(remainingPrincipal.toFixed(2)),
          });
        }
        setMonthlyRecords(records);

        let yearlySummary = [];
        let interest = 0;

        for (let yearIndex = 0; yearIndex <= +yearOfLoan; yearIndex++) {
          interest +=
            yearIndex === 0 ? 0 : +totalInterestGenerated / +yearOfLoan;
          yearlySummary.push({
            principal: +carAmountInput,
            interest: +interest.toFixed(2),
          });
        }
        setYearlySummary(yearlySummary);
      }
    };
    const totalLoanMonths = yearOfLoan * 12;
    const interestPerMonth = interestValue / 100 / 12;
    const monthlyPayment =
      (carAmountInput *
        interestPerMonth *
        (1 + interestPerMonth) ** totalLoanMonths) /
      ((1 + interestPerMonth) ** totalLoanMonths - 1);

    const totalInterestGenerated =
      monthlyPayment * totalLoanMonths - carAmountInput;
    setMonthlyEmi(monthlyPayment.toFixed(2));
    setTotalAmount((+totalInterestGenerated + +carAmountInput).toFixed(2));
    setTotalInterest(totalInterestGenerated.toFixed(2));
    generateMonthlyRecords(monthlyPayment, interestPerMonth, totalLoanMonths);
  }, [carAmountInput, interestValue, yearOfLoan]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: "10px 24px" }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Loan Amount"
                value={carAmountInput}
                min={1}
                max={10000000000}
                setState={setCarAmountInput}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Rate of Interest (p.a.)"
                setState={setInterestValue}
                value={interestValue}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Tenure"
                setState={setYearOfLoan}
                value={yearOfLoan}
                min={0}
                max={199}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Monthly Pay"
                isInput={false}
                flag="dollar"
                value={monthlyEmi}
              />
              <InputComponent
                label="Principal Amount"
                isInput={false}
                flag="dollar"
                value={carAmountInput}
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                flag="dollar"
                value={totalInterest}
              />
              <InputComponent
                label="Total Amount"
                isInput={false}
                flag="dollar"
                value={totalAmount}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +carAmountInput, label: "Principal Amount" },
                { id: 1, value: +totalInterest, label: "Interest Amount" },
              ]}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Yearly/Monthly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyRecords}
                  currency="dollar"
                  headerData={[
                    "Month",
                    "Principal Paid",
                    "Interest Charged",
                    "Total Payment",
                    "Balance",
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={5} className="piechart-div">
          <BarChartComponent
            data={[
              {
                data: yearlySummary
                  ?.sort((a, b) => (a.year > b.year ? 1 : -1))
                  ?.map((x) => x?.principal),
                stack: "A",
                label: "Principal Amount",
              },
              {
                data: yearlySummary
                  ?.sort((a, b) => (a.year > b.year ? 1 : -1))
                  ?.map((x) => x?.interest),
                stack: "A",
                label: "Interest Value",
              },
            ]}
          />
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default AmortizationCalc;
