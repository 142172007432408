import { Grid, Typography, Divider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  formatDate,
  returnHelmetAsPerParameter,
} from "../../../Utils/CommonFunction";
import "./BlogComponent.scss";
import axios from "axios";
import Loader from "../Loader/Loader";
import SideBarMenu from "../SideBar/SideBarMenu";
import { DataContext } from "../../../Context/DataContext";
function BlogComponent() {
  const { blogData, setBlogData } = useContext(DataContext);
  const [isLoader, setIsLoader] = useState(false);

  const fetchBlogData = () => {
    try {
      setIsLoader(true);
      axios
        .get(
          `${process.env.REACT_APP_WP_URL}wp-json/wp/v2/posts/?slug=${window.location.pathname}`
        )
        .then((res) => {
          if (res?.data[0]?.status === "publish") {
            const data = {
              id: res?.data[0]?.id,
              date: res?.data[0]?.date,
              modified: res?.data[0]?.modified,
              slug: res?.data[0]?.slug,
              status: res?.data[0]?.status,
              type: res?.data[0]?.type,
              title: res?.data[0]?.title,
              content: res?.data[0]?.content,
              description: res?.data[0]?.excerpt?.rendered,
              category: res?.data[0]?.categories,
              keys: res?.data[0]?.tags,
              yoast: res?.data[0]?.yoast_head,
            };
            setBlogData(data);
            setIsLoader(false);
          } else {
            setBlogData({});
            setIsLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setBlogData({});
          setIsLoader(false);
        });
    } catch (e) {
      setIsLoader(false);
    }
  };
  useEffect(() => {
    if (Object.keys(blogData).length === 0) {
      fetchBlogData();
    } else if (!window.location.pathname.includes(blogData?.slug)) {
      fetchBlogData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoader ? (
        <Loader />
      ) : (
        Object.keys(blogData).length > 0 && (
          <>
            {returnHelmetAsPerParameter(blogData)}

            <Divider sx={{ my: 5 }} />
            <Grid container>
              <Grid item xs={12} sm={8} lg={8} className="blog-container">
                <Grid className="blog-content">
                  <Typography variant="h2">
                    {blogData?.title?.rendered}
                  </Typography>
                  <Typography>
                    {formatDate(blogData?.modified)}~By CalculatorTimes
                  </Typography>
                </Grid>
                <Grid
                  className="blog-content"
                  dangerouslySetInnerHTML={{
                    __html: blogData?.content?.rendered,
                  }}
                ></Grid>
              </Grid>
              <Grid item xs={0} sm={0} lg={1} />
              <Grid
                item
                xs={0}
                sm={4}
                lg={3}
                sx={{
                  position: "relative",
                  display: { xs: "none", sm: "block" },
                }}
              >
                <SideBarMenu />
              </Grid>
            </Grid>
          </>
        )
      )}
    </>
  );
}

export default BlogComponent;
