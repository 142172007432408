import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./SelectBoxComponent.scss";
import { Grid, Typography } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, value, theme) {
  if (value === undefined) {
    return;
  }
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const SelectBoxComponent = (props) => {
  const { value, setValue, data, placeholder, label } = props;
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(value);
  };

  return (
    <Grid container sx={{ alignItems: "center", padding: "2px 8px" }}>
      {label && (
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontWeight: 500, fontSize: "18px" }}>
            {label}
          </Typography>
        </Grid>
      )}
      <Grid item xs={label ? 6 : 12}>
        <FormControl
          sx={{ width: "100%", mt: 2, borderRadius: "5px" }}
          className="notranslate select-box-hover-rm"
        >
          <Select
            displayEmpty
            value={value === "" ? "" : value}
            onChange={handleChange}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
            className="select-option"
          >
            <MenuItem disabled value="" className="menu-item">
              <em>{placeholder}</em>
            </MenuItem>
            {data.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, value, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
export default SelectBoxComponent;
