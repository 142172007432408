import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import TableComponent from "../../Common/TableComponent/TableComponent";
import BarChartComponent from "../../Common/BarChart/BarCahrt";

function SimpleInterestCalc() {
  const [loanAmountInput, setLoanAmountInput] = useState(1000000);
  const [interestValue, setInterestValue] = useState(14);
  const [yearOfLoan, setYearOfLoan] = useState(3);
  const [monthlyEmi, setMonthlyEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [yearlySummary, setYearlySummary] = useState([
    {
      year: 0,
      principal: +loanAmountInput,
      interest: 0,
    },
  ]);

  useEffect(() => {
    const totalLoanMonths = yearOfLoan * 12;
    const interestPerMonth = interestValue / 100 / 12;
    const monthlyPayment =
      (loanAmountInput *
        interestPerMonth *
        (1 + interestPerMonth) ** totalLoanMonths) /
      ((1 + interestPerMonth) ** totalLoanMonths - 1);

    const totalInterestGenerated =
      monthlyPayment * totalLoanMonths - loanAmountInput;
    setMonthlyEmi(monthlyPayment.toFixed(2));
    setTotalAmount((+totalInterestGenerated + +loanAmountInput).toFixed(2));
    setTotalInterest(totalInterestGenerated.toFixed(2));

    let balance = +loanAmountInput;
    let records = [];
    let yearlySummary = [];

    for (let month = 1; month <= totalLoanMonths; month++) {
      const interestCharged = balance * interestPerMonth;
      const principalPaid = monthlyPayment - interestCharged;
      balance -= principalPaid;

      records.push({
        month: month,
        principalPayment: +principalPaid.toFixed(2),
        interestPayment: +interestCharged.toFixed(2),
        totalPaid: +monthlyPayment.toFixed(2),
        Balance: balance.toFixed(2),
      });
    }

    let interest = 0;

    for (let yearIndex = 0; yearIndex <= +yearOfLoan; yearIndex++) {
      interest += yearIndex === 0 ? 0 : +totalInterestGenerated / +yearOfLoan;
      yearlySummary.push({
        principal: +loanAmountInput,
        interest: +interest.toFixed(2),
      });
    }
    setYearlySummary(yearlySummary);

    setMonthlyRecords(records);
  }, [loanAmountInput, interestValue, yearOfLoan]);
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Principal Amount"
                value={loanAmountInput}
                min={1}
                setState={setLoanAmountInput}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Rate of Interest (p.a.)"
                setState={setInterestValue}
                value={interestValue}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Time Period"
                setState={setYearOfLoan}
                value={yearOfLoan}
                min={0}
                max={199}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Monthly EMI"
                isInput={false}
                value={monthlyEmi}
              />
              <InputComponent
                label="Principal Amount"
                isInput={false}
                value={loanAmountInput}
              />
              <InputComponent
                label="Total Interest"
                isInput={false}
                value={totalInterest}
              />
              <InputComponent
                label="Total Amount"
                isInput={false}
                value={totalAmount}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +loanAmountInput, label: "Principal Amount" },
                { id: 1, value: +totalInterest, label: "Interest Amount" },
              ]}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Monthly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyRecords}
                  headerData={[
                    "Month",
                    "Principal Paid",
                    "Interest Charged",
                    "Total Payment",
                    "Balance",
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={5} className="piechart-div">
          <BarChartComponent
            data={[
              {
                data: yearlySummary
                  ?.sort((a, b) => (a.year > b.year ? 1 : -1))
                  ?.map((x) => x?.principal),
                stack: "A",
                label: "Principal Amount",
              },
              {
                data: yearlySummary
                  ?.sort((a, b) => (a.year > b.year ? 1 : -1))
                  ?.map((x) => x?.interest),
                stack: "A",
                label: "Interest Value",
              },
            ]}
          />
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default SimpleInterestCalc;
