import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "./RentalPropertyCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";

import { BOOLEANS } from "../../../Utils/calorieHelper";
import PieChartComponent from "../../Common/PieChart/PieChart";

function RentalPropertyCalc() {
  const [isUseLoan, setIsUseLoan] = useState({ label: "Yes", value: "yes" });
  const [needRepair, setNeedRepair] = useState({ label: "No", value: "no" });
  const [isSellPrice, setIsSellPrice] = useState({ label: "No", value: "no" });
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [loanTerms, setLoanTerms] = useState(0);
  const [closingCost, setClosingCost] = useState(0);
  const [propertyTax, setPropertyTax] = useState(0);
  const [propertyTaxIncrement, setPropertyTaxIncrement] = useState(0);

  const [totalIns, setTotalIns] = useState(0);
  const [totalInsIncrement, setTotalInsIncrement] = useState(0);

  const [HOA, setHOA] = useState(0);
  const [HOAIncrement, setHOAIncrement] = useState(0);
  const [maintenance, setMaintenance] = useState(0);
  const [maintenanceIncrement, setMaintenanceIncrement] = useState(0);

  const [otherCost, setOtherCost] = useState(0);
  const [otherCostIncrement, setOtherCostIncrement] = useState(0);

  const [monthlyRent, setMonthlyRent] = useState(0);
  const [monthlyRentIncrement, setMonthlyRentIncrement] = useState(0);

  const [otherMonthlyIncome, setOtherMonthlyIncome] = useState(0);
  const [otherMonthlyIncomeIncrement, setOtherMonthlyIncomeIncrement] =
    useState(0);
  const [vacancyRate, setVacancyRate] = useState(0);
  const [managementFee, setManagementFee] = useState(0);
  const [holdingLength, setHoldingLength] = useState(0);
  const [costToSell, setCostToSell] = useState(0);

  const [valueAppreciation, setValueAppreciation] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);

  const [returnIRR, setReturnIRR] = useState(0);
  const [totalProfitSold, setTotalProfitSold] = useState(0);
  const [cashOnCashReturn, setCashOnCashReturn] = useState(0);
  const [capitalizationRate, setCapitalizationRate] = useState(0);
  const [totalRentalIncome, setTotalRentalIncome] = useState(0);
  const [totalMortgagePayments, setTotalMortgagePayments] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalNetOperatingIncome, setTotalNetOperatingIncome] = useState(0);
  useEffect(() => {}, []);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <Typography className="common-sub-heading-calc">
                Purchase
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Purchase Price"
                value={purchasePrice}
                min={1}
                setState={setPurchasePrice}
                max={10000000000}
              />
              <RadioButtonComponent
                label={"Use Loan?"}
                data={BOOLEANS}
                defaultValue={"yes"}
                valueSetter={setIsUseLoan}
                row={false}
              />
              {isUseLoan.value === "yes" && (
                <div className="inside-input">
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Down Payment"
                    value={downPayment}
                    min={0}
                    setState={setDownPayment}
                    max={100}
                  />
                  <InputComponent
                    isInput={true}
                    flag="percentage"
                    label="Interest Rate"
                    value={interestRate}
                    min={0}
                    setState={setInterestRate}
                    max={999}
                  />
                  <InputComponent
                    isInput={true}
                    flag="year"
                    label="Loan Term"
                    value={loanTerms}
                    min={0}
                    setState={setLoanTerms}
                    max={50}
                  />
                </div>
              )}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Closing Cost"
                value={closingCost}
                min={0}
                setState={setClosingCost}
                max={10000000}
              />
              <RadioButtonComponent
                label={"Need Repairs?"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setNeedRepair}
                row={false}
              />
            </Grid>
            <Grid>
              <Typography className="common-sub-heading-calc">
                Recurring Operating Expenses [ Annual - Annual Increase]
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Property Tax"
                value={propertyTax}
                min={1}
                setState={setPropertyTax}
                max={10000000000}
                isCombo={true}
                otherValue={propertyTaxIncrement}
                setOtherState={setPropertyTaxIncrement}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Total Insurance"
                value={totalIns}
                min={1}
                setState={setTotalIns}
                max={10000000000}
                isCombo={true}
                otherValue={totalInsIncrement}
                setOtherState={setTotalInsIncrement}
              />{" "}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="HOA Fee"
                value={HOA}
                min={1}
                setState={setHOA}
                max={10000000000}
                isCombo={true}
                otherValue={HOAIncrement}
                setOtherState={setHOAIncrement}
              />{" "}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Maintenance"
                value={maintenance}
                min={1}
                setState={setMaintenance}
                max={10000000000}
                isCombo={true}
                otherValue={maintenanceIncrement}
                setOtherState={setMaintenanceIncrement}
              />{" "}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Other Costs"
                value={otherCost}
                min={1}
                setState={setOtherCost}
                max={10000000000}
                isCombo={true}
                otherValue={otherCostIncrement}
                setOtherState={setOtherCostIncrement}
              />
            </Grid>
            <Grid>
              <Typography className="common-sub-heading-calc">
                Income
              </Typography>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Monthly Rent"
                value={monthlyRent}
                min={1}
                setState={setMonthlyRent}
                max={10000000000}
                isCombo={true}
                otherValue={monthlyRentIncrement}
                setOtherState={setMonthlyRentIncrement}
              />{" "}
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Other Monthly Income"
                value={otherMonthlyIncome}
                min={1}
                setState={setOtherMonthlyIncome}
                max={10000000000}
                isCombo={true}
                otherValue={otherMonthlyIncomeIncrement}
                setOtherState={setOtherMonthlyIncomeIncrement}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Vacancy Rate"
                value={vacancyRate}
                min={0}
                setState={setVacancyRate}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Management Fee"
                value={managementFee}
                min={0}
                setState={setManagementFee}
                max={10000000}
              />
            </Grid>
            <Grid>
              <Typography className="common-sub-heading-calc">Sell</Typography>
              <RadioButtonComponent
                label={"Do You Know the Sell Price?"}
                data={BOOLEANS}
                defaultValue={"no"}
                valueSetter={setIsSellPrice}
                row={false}
              />

              {isSellPrice.value === "yes" ? (
                <InputComponent
                  isInput={true}
                  flag="dollar"
                  value={sellPrice}
                  min={0}
                  setState={setSellPrice}
                  label="Sell Price"
                  max={10000000000}
                />
              ) : (
                <InputComponent
                  isInput={true}
                  flag="percentage"
                  label="Value Appreciation (p.a.)"
                  value={valueAppreciation}
                  min={0}
                  setState={setValueAppreciation}
                  max={10000000000}
                />
              )}

              <InputComponent
                isInput={true}
                flag="year"
                label="Holding Length"
                value={holdingLength}
                min={0}
                setState={setHoldingLength}
                max={100}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Cost to Sell"
                value={costToSell}
                min={0}
                setState={setCostToSell}
                max={10000000000}
              />
            </Grid>
            <Grid className="result-label" >
              <Typography>Result</Typography>
            </Grid>
            <Grid >
              <InputComponent
                label="Return (IRR):"
                isInput={false}
                flag="percentage"
                value={returnIRR}
              />
              <InputComponent
                label="Total Profit when Sold:"
                isInput={false}
                flag="dollar"
                value={totalProfitSold}
              />
              <InputComponent
                label="Cash on Cash Return:	"
                isInput={false}
                flag="percentage"
                value={cashOnCashReturn}
              />
              <InputComponent
                label="Capitalization Rate:"
                isInput={false}
                flag="percentage"
                value={capitalizationRate}
              />
              <InputComponent
                label="Total Rental Income:"
                isInput={false}
                flag="dollar"
                value={totalRentalIncome}
              />{" "}
              <InputComponent
                label="Total Mortgage Payments:"
                isInput={false}
                flag="dollar"
                value={totalMortgagePayments}
              />{" "}
              <InputComponent
                label="Total Expenses:"
                isInput={false}
                flag="dollar"
                value={totalExpenses}
              />{" "}
              <InputComponent
                label="Total Net Operating Income:"
                isInput={false}
                flag="dollar"
                value={totalNetOperatingIncome}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +sellPrice, label: "Mortgage" },
                { id: 1, value: +valueAppreciation, label: "Vacancy" },
                { id: 2, value: +sellPrice, label: "Property Tax" },
                { id: 3, value: +valueAppreciation, label: "Total Insurance" },
                { id: 4, value: +sellPrice, label: "Maintenance Cost" },
                { id: 5, value: +valueAppreciation, label: "Other Cost" },
              ]}
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default RentalPropertyCalc;
